/* eslint-disable @typescript-eslint/no-explicit-any */
import Empty from "@/components/Empty";
import { trpc } from "@/helpers/trpc";
import { COUNTRIES_UAE_ID } from "@/lib/dbIds";
import { AddClientDrawer } from "@/pages/crm/clients/list";
import Schemas from "@heffl/server/src/schemas";
import { Icons } from "@heffl/ui/components/icons";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import messageToMobileApp from "@heffl/ui/lib/messageToMobileApp";
import {
  findPrimaryContact,
  formatValue,
  isMobileApp,
} from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import Fuse from "fuse.js";
import { Building2, MapPin, Phone, Plus, Search, XIcon } from "lucide-react";
import { useEffect, useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { UseFormReturn, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";

export const GOOGLE_MAPS_API_KEY = "AIzaSyCQdBepgPBP4-a66aYLTR65TsYSkFN-KHs";

const PropertyCard = ({
  name,
  address,
  onClick,
}: {
  name: string;
  address: string;
  onClick: () => void;
}) => {
  return (
    <Card
      onClick={onClick}
      className="flex gap-3 cursor-pointer"
      parentClassName="hover:bg-green-50 "
    >
      <div className="flex justify-center items-center p-3 w-12 h-12 bg-white rounded-full border shadow">
        <MapPin className="w-6 h-6 text-green-600" />
      </div>
      <div className="w-full">
        <p className="text-base font-medium">{name}</p>
        <p className="w-11/12 text-gray-500">{address}</p>
      </div>
    </Card>
  );
};
export const isValidUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const mapPlaceFormatter = (placeObject: any) => {
  let state = placeObject.address_components.filter((addressComponent: any) => {
    return addressComponent.types.includes("administrative_area_level_1");
  })[0]?.long_name;
  if (state === "أبو ظبي") state = "Abu Dhabi";
  if (state === "دبي") state = "Dubai";

  const city = placeObject.address_components.filter(
    (addressComponent: any) => {
      return addressComponent.types.includes("sublocality_level_1");
    }
  )[0];

  const formattedAddress = placeObject.formatted_address
    // Remove city if it's repeated in the address
    .replace(new RegExp(`${city?.long_name},?\\s*`, "gi"), "")
    .replace(/United Arab Emirates,?\s*/gi, "")
    .replace(/Dubai,?\s*/gi, "")
    .replace(/Abu Dhabi,?\s*/gi, "")
    .replace(/\s*-\s*/g, ", ")
    // Remove Arabic
    .replace(/[\u0600-\u06FF\u0750-\u077F]/g, "")
    .replace(/,\s*,+/g, ",")
    .replace(/,\s*$/, "")
    .replace(/\s\s+/g, " ") // Remove double spaces
    .replace(/,\s*$/, "") // Remove trailing comma
    .replace(/^,\s*/, "") // Remove leading comma
    .replace(/,+/g, ",") // Replace multiple commas with a single comma
    .trim();
  return {
    address: formattedAddress,
    // remove Arabic from city
    city: (city?.long_name || "")
      .replace(/[\u0600-\u06FF\u0750-\u077F]/g, "")
      .trim(),
    state: state || "",
    latitude: placeObject.geometry.location.lat(),
    longitude: placeObject.geometry.location.lng(),
    placeId: placeObject.place_id,
  };
};

type OpenGoogleMapsProps =
  | {
      googleMapsLink?: string | null;
      googleMapsPlaceId?: string | null;
      address?: string | null;
    }
  | undefined;

export const generateGoogleMapsLink = (params: OpenGoogleMapsProps): string => {
  const { googleMapsLink, googleMapsPlaceId, address } = params || {};
  return (
    googleMapsLink ||
    `https://www.google.com/maps/dir/?api=1&destination=${address}&destination_place_id=${googleMapsPlaceId}`
  );
};
export const openGoogleMaps = (params: OpenGoogleMapsProps) => {
  const { googleMapsLink, googleMapsPlaceId, address } = params || {};
  if (!googleMapsLink && !googleMapsPlaceId && !address) {
    return toast.error("Maps link not added");
  }

  const mapLink = generateGoogleMapsLink({
    googleMapsLink,
    googleMapsPlaceId,
    address,
  });
  if (isMobileApp()) {
    messageToMobileApp({
      action: "openExternalLink",
      data: mapLink,
    });
  } else {
    window.open(mapLink, "_blank");
  }
};

const PropertyForm = ({
  form,
  clientId,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.fieldService.fsProperty>>;
  clientId?: number;
}) => {
  const countryId = form.watch("countryId");
  const googleMapsLink = form.watch("googleMapsLink");

  const { data: countries } = trpc.countries.list.useQuery();
  const { data: client } = trpc.clients.details.useQuery(clientId || 0, {
    enabled: !!clientId,
  });

  const googleMapsToPlaceIdMutation =
    trpc.fieldService.properties.googleMapsToPlaceId.useMutation();

  const selectedCountry = countries?.find(
    (country) => country.id === countryId
  );

  const onPlaceSelect = async (placeId: string) => {
    const result = await geocodeByPlaceId(placeId);
    const formattedAddress = mapPlaceFormatter(result[0]);
    if (selectedCountry) {
      const fuse = new Fuse(selectedCountry.countryStates, { keys: ["name"] });
      const result = fuse.search(formattedAddress.state);
      const stateId = result.length > 0 ? result[0].item.id : undefined;
      if (stateId) {
        form.setValue("stateId", stateId);
      }
    }
    form.setValue("address", formattedAddress.address);
    form.setValue("city", formattedAddress.city);
    form.setValue("latitude", formattedAddress.latitude);
    form.setValue("longitude", formattedAddress.longitude);
    form.setValue("googleMapsPlaceId", formattedAddress.placeId);
  };

  return (
    <>
      {client && (
        <Button
          className="self-end"
          size="xs"
          icon={Building2}
          variant="primaryOutline"
          onClick={() => {
            if (client.clientAddresses.length) {
              const address = client.clientAddresses[0];
              form.setValue("name", address.landmark || "");
              form.setValue("address", address.address || "");
              form.setValue("city", address.city || "");
              form.setValue("latitude", address.latitude);
              form.setValue("longitude", address.longitude);
              form.setValue("googleMapsPlaceId", address.googleMapsPlaceId);
              form.setValue("googleMapsLink", address.googleMapsLink);
              if (address.countryId)
                form.setValue("countryId", address.countryId);
              if (address.stateId) form.setValue("stateId", address.stateId);
            }
          }}
        >
          Copy client address
        </Button>
      )}
      <div className="flex gap-2">
        <div className="relative w-full">
          <Icons.google className="absolute top-1.5 left-1.5 z-50 w-6 h-6" />
          <GooglePlacesAutocomplete
            apiKey={GOOGLE_MAPS_API_KEY}
            selectProps={{
              styles: {
                input: (provided) => ({
                  ...provided,
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  paddingLeft: 35,
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "6px",
                  border: "1px solid #E2E8F0",
                }),
              },
              placeholder: "Search google maps...",
              onChange: (e) => e && onPlaceSelect(e.value?.place_id),
            }}
            apiOptions={{
              language: "en",
              region: "ae",
            }}
          />
        </div>
      </div>
      <div className="flex gap-2 mt-1">
        <FormField
          name="googleMapsLink"
          label="Google maps link"
          className="w-4/5"
        >
          <Input placeholder="Add google maps link " />
        </FormField>
        <Button
          onClick={async () => {
            try {
              if (!googleMapsLink) return;
              if (!isValidUrl(googleMapsLink)) {
                return toast.error("Invalid google maps link");
              }
              const mapResponse = await googleMapsToPlaceIdMutation.mutateAsync(
                googleMapsLink
              );
              if (mapResponse) onPlaceSelect(mapResponse.placeId);
              else toast.error("Failed, check your maps link");
            } catch (err) {
              console.error("Failed to read clipboard contents: ", err);
            }
          }}
          size="md"
          className="mt-[22px] w-1/5"
          variant="primaryOutline"
          loading={googleMapsToPlaceIdMutation.isLoading}
        >
          Fetch
        </Button>
      </div>
      <div className="flex gap-2">
        <FormField name="name" label="Apt / Unit / Floor">
          <Input placeholder="Property details" />
        </FormField>
        <FormField name="city" label="City">
          <Input placeholder="City" />
        </FormField>
      </div>
      <FormField name="address" label="Address">
        <Input placeholder="Address" />
      </FormField>
      <div className="grid grid-cols-2 gap-2">
        <FormField name="countryId" label="Country">
          <Select
            allowClear={false}
            options={
              countries?.map((country) => ({
                label: country.name,
                value: country.id,
                states: country.countryStates,
              })) || []
            }
            // @ts-ignore
            onSelect={() => form.setValue("stateId", null)}
          />
        </FormField>
        <FormField name="stateId" label="State">
          <Select
            allowClear={false}
            options={
              selectedCountry?.countryStates?.map((state) => ({
                label: state.name,
                value: state.id,
              })) || []
            }
          />
        </FormField>
      </div>
    </>
  );
};

const AddPropertyModal = ({
  open,
  onClose,
  clientId,
}: {
  open: boolean;
  onClose: (id?: number) => void;
  clientId: number;
}) => {
  const form = useForm<z.infer<typeof Schemas.fieldService.fsProperty>>({
    resolver: zodResolver(Schemas.fieldService.fsProperty),
    defaultValues: {
      name: "",
      city: "",
      address: "",
      countryId: COUNTRIES_UAE_ID,
      clientId,
    },
  });

  const onModalClose = (id?: number) => {
    onClose(id);
    form.reset();
  };

  const addFsPropertyMutation = trpc.fieldService.properties.add.useMutation({
    onSuccess(newProperty) {
      toast.success("Succesfully added property.");
      onModalClose(newProperty.id);
    },
  });

  useEffect(() => {
    if (clientId) {
      form.setValue("clientId", clientId, {
        shouldDirty: true,
      });
    }
  }, [clientId]);

  const onSubmit = (
    values: z.infer<typeof Schemas.fieldService.fsProperty>
  ) => {
    addFsPropertyMutation.mutate(values);
  };

  return (
    <ModalDrawer
      title="Add property"
      open={open}
      onClose={() => onModalClose()}
      footer={
        <Button
          type="submit"
          variant="primary"
          loading={addFsPropertyMutation.isLoading}
          className="!w-full"
          onClick={() => form.handleSubmit(onSubmit)()}
        >
          Add property
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <PropertyForm clientId={clientId} form={form} />
      </Form>
    </ModalDrawer>
  );
};

export const EditPropertyModal = ({
  open,
  onClose,
  propertyId,
}: {
  open: boolean;
  onClose: () => void;
  propertyId: number;
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof Schemas.fieldService.fsProperty>>({
    resolver: zodResolver(Schemas.fieldService.fsProperty),
  });

  const { data: propertyDetails } =
    trpc.fieldService.properties.details.useQuery(propertyId, {
      enabled: !!propertyId,
    });

  const onCloseModal = () => {
    form.reset();
    onClose();
  };

  useEffect(() => {
    if (propertyDetails) {
      // @ts-ignore
      form.reset(propertyDetails);
    }
  }, [propertyDetails]);

  const editFsPropertyMutation =
    trpc.fieldService.properties.update.useMutation({
      onSuccess: () => {
        toast.success("Successfully updated property.");
        onCloseModal();
        if (location.pathname.includes("/jobs/edit")) {
          navigate(-1);
        }
      },
    });

  const onSubmit = (
    values: z.infer<typeof Schemas.fieldService.fsProperty>
  ) => {
    console.log(values);
    editFsPropertyMutation.mutate({ id: propertyId, property: values });
  };

  return (
    <ModalDrawer
      title="Edit Property"
      open={open}
      onClose={onCloseModal}
      footer={
        <Button
          type="submit"
          variant="primary"
          loading={editFsPropertyMutation.isLoading}
          className="!w-full"
          onClick={() => form.handleSubmit(onSubmit)()}
        >
          Save Changes
        </Button>
      }
    >
      <Form className="mt-4" {...form} onSubmit={onSubmit}>
        <PropertyForm form={form} />
      </Form>
    </ModalDrawer>
  );
};

const PropertySelector = ({
  value,
  onChange,
  onSelect,
  defaultClientId,
}: {
  value?: number | null;
  defaultClientId?: number | null;
  onChange?: (v: number | null) => void;
  onSelect?: (v: { clientId: number; propertyId: number } | null) => void;
}) => {
  const [selectedClient, setSelectedClient] = useState<number | null>(null);
  const [showAddProperty, setShowAddProperty] = useState(false);
  const [addClient, setAddClient] = useState(false);
  const [clientSearch, setClientSearch] = useState("");
  const [propertySearch, setPropertySearch] = useState("");

  const { data: properties } = trpc.fieldService.properties.list.useQuery(
    {
      clientId: selectedClient || 0,
    },
    {
      enabled: !!selectedClient,
    }
  );
  const { data: clientsData } = trpc.clients.list.useQuery({
    pageNo: 1,
    pageSize: 20,
    search: clientSearch,
    include: [],
  });
  const { data: propertyDetails } =
    trpc.fieldService.properties.details.useQuery(value || 0, {
      enabled: !!value,
    });
  const { data: clientDetails } = trpc.clients.details.useQuery(
    selectedClient || 0,
    {
      enabled: !!selectedClient,
    }
  );

  const sortedProperties = () => {
    if (propertySearch.length && properties?.length) {
      const filteredProperties = new Fuse(properties, {
        keys: ["name", "city", "address"],
      });
      return filteredProperties
        .search(propertySearch)
        .map((result) => result.item);
    } else {
      return properties || [];
    }
  };

  useEffect(() => {
    if (defaultClientId) {
      setSelectedClient(defaultClientId);
    }
  }, [defaultClientId]);

  return (
    <>
      <AddClientDrawer
        open={addClient}
        onClose={(newId) => {
          if (newId) {
            setSelectedClient(newId);
          }
          setAddClient(false);
        }}
      />
      {selectedClient && (
        <AddPropertyModal
          open={showAddProperty}
          onClose={(id) => {
            if (id) {
              onChange && onChange(id);
              onSelect &&
                onSelect({ clientId: selectedClient, propertyId: id });
              setSelectedClient(null);
            }
            setShowAddProperty(false);
          }}
          clientId={selectedClient}
        />
      )}
      <div>
        {!value ? (
          <Select
            onSearch={setClientSearch}
            value={selectedClient}
            placeholder="Select client property"
            onChange={(v) => {
              if (v) setSelectedClient(Number(v));
            }}
            options={
              clientsData?.clients?.map((client) => ({
                label: client.name,
                value: client.id,
                contact: findPrimaryContact(client.contacts),
              })) || []
            }
            render={(client) => (
              <div className="flex flex-col gap-1 pb-1 w-full border-b border-gray-200">
                <p className="text-sm font-semibold">{client.label}</p>
                <div className="flex gap-2">
                  {client?.contact?.mobile && (
                    <div className="flex flex-row gap-1 items-center text-xs">
                      <Phone className="w-3.5 h-3.5 text-gray-500" />
                      {client.contact.mobile}
                    </div>
                  )}
                </div>
              </div>
            )}
            createButton={{
              label: "Add client",
              onClick: () => {
                setAddClient(true);
              },
            }}
          />
        ) : propertyDetails ? (
          <div className="flex gap-2 justify-between p-3 rounded-md border">
            <div className="flex gap-2 items-center">
              <div className="flex justify-center items-center p-3 bg-gray-100 rounded-sm">
                <MapPin className="h-5" />
              </div>
              <div>
                <p className="text-xs font-medium text-green-600 uppercase line-clamp-1">
                  {propertyDetails.clients.name}
                </p>
                <p className="text-lg font-normal line-clamp-1">
                  {formatValue.propertyName(propertyDetails)}
                </p>
              </div>
            </div>
            <div
              onClick={() => {
                onChange && onChange(null);
                onSelect && onSelect(null);
              }}
              className="p-2 rounded-md border cursor-pointer"
            >
              <XIcon />
            </div>
          </div>
        ) : (
          <FullScreenSpinner />
        )}
      </div>
      <ModalDrawer
        title={`Select ${clientDetails?.name || "client"} property`}
        open={!!selectedClient}
        onClose={() => setSelectedClient(null)}
      >
        {!properties && <FullScreenSpinner className="h-36" />}
        {!!properties && (
          <>
            <Button
              onClick={() => setShowAddProperty(true)}
              size="sm"
              variant="link"
              className="float-right"
            >
              <Plus className="h-4" /> Add Property
            </Button>
            <Input
              parentClassName="pt-1"
              prefix={<Search className="text-gray-400" />}
              placeholder="Search properties..."
              value={propertySearch}
              onChange={(e) => setPropertySearch(e.target.value)}
            />
            <div className="flex overflow-y-auto flex-col gap-2 mt-4">
              {sortedProperties().map((property) => (
                <PropertyCard
                  onClick={() => {
                    onChange && onChange(property.id);
                    if (selectedClient)
                      onSelect &&
                        onSelect({
                          clientId: selectedClient,
                          propertyId: property.id,
                        });
                    setSelectedClient(null);
                  }}
                  name={formatValue.propertyName(property)}
                  address={formatValue.address(property)}
                  key={property.id}
                />
              ))}
            </div>
          </>
        )}
        {properties && !properties.length && (
          <Empty
            icon={MapPin}
            title="No properties added"
            description="Please add a property to add jobs."
            actionText="Add property"
            onAction={() => {
              setShowAddProperty(true);
            }}
          />
        )}
      </ModalDrawer>
    </>
  );
};

export default PropertySelector;
