import DataGrid from "@/components/dataGrid/DataGrid";
import { SearchInput } from "@/components/FormComponents";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import { DollarSign } from "lucide-react";
import { capitalize } from "radash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

const chartOfAccountsTypes = [
  { label: "Cash", value: "CASH", type: "assets" },
  { label: "Bank", value: "BANK", type: "assets" },
  { label: "Fixed Asset", value: "FIXED_ASSET", type: "assets" },
  {
    label: "Other Current Asset",
    value: "OTHER_CURRENT_ASSET",
    type: "assets",
  },
  { label: "Other Asset", value: "OTHER_ASSET", type: "assets" },
  { label: "Stock", value: "STOCK", type: "assets" },
  { label: "Payment Clearing", value: "PAYMENT_CLEARING", type: "assets" },
  { label: "Expense", value: "EXPENSE", type: "expenses" },
  {
    label: "Cost of Goods Sold",
    value: "COST_OF_GOODS_SOLD",
    type: "expenses",
  },
  { label: "Other Expense", value: "OTHER_EXPENSE", type: "expenses" },
  { label: "Income", value: "INCOME", type: "income" },
  { label: "Other Income", value: "OTHER_INCOME", type: "income" },
];

const ChartOfAccountsForm = () => {
  return (
    <>
      <FormField name="type" label="Account Type">
        <Select
          placeholder="Select account type"
          options={chartOfAccountsTypes}
          render={(option) => (
            <>
              <span className="text-gray-500">{capitalize(option.type)}</span>:{" "}
              {option.label}
            </>
          )}
        />
      </FormField>
      <FormField name="name" label="Account Name">
        <Input type="text" placeholder="Enter account name" />
      </FormField>
      <FormField name="parentAccountId" label="Parent Account">
        <Select
          allowClear
          options={chartOfAccountsTypes}
          placeholder="Select parent account"
          render={(option) => (
            <>
              <span className="text-gray-500">{capitalize(option.type)}</span>:{" "}
              {option.label}
            </>
          )}
        />
      </FormField>
      <FormField name="accountCode" label="Account Code">
        <Input type="text" placeholder="Enter account code" />
      </FormField>
      <FormField name="description" label="Description">
        <Textarea placeholder="Enter account description" />
      </FormField>
    </>
  );
};

type AddAccountModalProps = {
  open: boolean;
  onClose: () => void;
  defaultValues?: Partial<z.infer<typeof Schemas.accounting.chartOfAccounts>>;
};

export const AddChartOfAccountsModal = ({
  open,
  onClose,
  defaultValues,
}: AddAccountModalProps) => {
  const form = useForm<z.infer<typeof Schemas.accounting.chartOfAccounts>>({
    resolver: zodResolver(Schemas.accounting.chartOfAccounts),
    defaultValues: {
      isActive: true,
      ...defaultValues,
    },
  });
  const accountAddMutation = trpc.books.chartOfAccounts.add.useMutation({
    onSuccess: () => {
      toast.success("Account added successfully");
      onClose();
      form.reset();
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const onSubmit = (
    values: z.infer<typeof Schemas.accounting.chartOfAccounts>
  ) => {
    accountAddMutation.mutate(values);
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      title="Add Account"
      footer={
        <Button
          loading={accountAddMutation.isLoading}
          onClick={form.handleSubmit(onSubmit)}
          variant="primary"
          className="w-full"
          size="md"
        >
          Add Account
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <ChartOfAccountsForm />
      </Form>
    </ModalDrawer>
  );
};

type EditAccountModalProps = {
  open: boolean;
  onClose: () => void;
  accountId: number;
};

const EditAccountModal = ({
  open,
  onClose,
  accountId,
}: EditAccountModalProps) => {
  const form = useForm<z.infer<typeof Schemas.accounting.chartOfAccounts>>({
    resolver: zodResolver(Schemas.accounting.chartOfAccounts),
  });

  const { data: account } =
    trpc.books.chartOfAccounts.details.useQuery(accountId);

  const accountUpdateMutation = trpc.books.chartOfAccounts.update.useMutation({
    onSuccess: () => {
      toast.success("Account updated successfully");
      onClose();
      form.reset();
    },
  });

  useEffect(() => {
    if (account) {
      form.reset(account);
    }
  }, [account, form]);

  const onSubmit = (
    values: z.infer<typeof Schemas.accounting.chartOfAccounts>
  ) => {
    accountUpdateMutation.mutate({ id: accountId, chartOfAccount: values });
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      title="Edit Account"
      footer={
        <div className="flex w-full">
          <Button
            size="md"
            loading={accountUpdateMutation.isLoading}
            onClick={form.handleSubmit(onSubmit)}
            variant="primary"
            className="w-full"
          >
            Update Account
          </Button>
        </div>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <ChartOfAccountsForm />
      </Form>
    </ModalDrawer>
  );
};

const ChartOfAccounts = () => {
  const [filters, setFilters] = useParamsState<{
    search: string;
    pageNo: number;
    pageSize: number;
  }>({
    search: "",
    pageNo: 1,
    pageSize: 30,
  });

  const [showAdd, setShowAdd] = useState(false);
  const [editAccountId, setEditAccountId] = useState<number | null>(null);

  const { data: accounts, isLoading } =
    trpc.books.chartOfAccounts.list.useQuery({
      pageNo: filters.pageNo,
      pageSize: filters.pageSize,
      search: filters.search || undefined,
    });

  return (
    <Page title="Chart of Accounts" className="sm:p-0" fullWidth>
      <AddChartOfAccountsModal
        open={showAdd}
        onClose={() => setShowAdd(false)}
      />
      {editAccountId && (
        <EditAccountModal
          open={!!editAccountId}
          onClose={() => setEditAccountId(null)}
          accountId={editAccountId}
        />
      )}
      <div className="flex flex-col gap-3 justify-between w-full border-gray-200 sm:border-b sm:p-3 sm:flex-row sm:gap-0">
        <SearchInput
          value={filters.search}
          onChange={(e: string) => setFilters({ search: e })}
          placeholder="Search accounts..."
        />
        <ResponsiveActionButton
          onClick={() => setShowAdd(true)}
          text="Account"
        />
      </div>
      <DataGrid
        rowKey="id"
        name="chartOfAccountsListMain"
        className="h-[calc(100vh-117px-var(--header-height))] mt-12"
        label="Chart of Accounts"
        loading={isLoading}
        onCellClick={({ row }) => {
          setEditAccountId(row.id);
        }}
        empty={{
          icon: DollarSign,
          title: "No accounts added",
          description: "Please add an account",
          actionText: "Add account",
          onAction: () => setShowAdd(true),
          buttonSize: "sm",
        }}
        pagination={{
          pageNo: filters.pageNo,
          pageSize: filters.pageSize,
          count: accounts?.meta.count,
          setPageNo: (pageNo) => setFilters({ pageNo }),
          setPageSize: (pageSize) => setFilters({ pageSize }),
        }}
        rows={accounts?.chartOfAccounts || []}
        columns={[
          {
            key: "name",
            name: "Account Name",
            cellClass: "cursor-pointer hover:underline hover:text-primary",
            width: 400,
          },
          {
            key: "accountCode",
            name: "Account Code",
            width: 150,
          },
          {
            key: "type",
            name: "Type",
            renderCell: ({ row }) => capitalize(row.type),
            width: 200,
          },
          {
            key: "parentAccount",
            name: "Parent Account",
            renderCell: ({ row }) => row.parentAccount?.name || "-",
            width: 200,
          },
        ]}
      />
    </Page>
  );
};

export default ChartOfAccounts;
