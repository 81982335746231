import { trpc } from "@/helpers/trpc";
import { Card } from "@heffl/ui/components/primitives/card";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { FormField, FormLabel } from "@heffl/ui/components/primitives/form";
import { MultiSelect } from "@heffl/ui/components/primitives/multi-select";
import dayjs from "dayjs";
import { CalendarDays, Pencil, Plus, Tag, Trash2, Wrench } from "lucide-react";
import { useState } from "react";

import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/creatable-select";
import FormList from "@heffl/ui/components/primitives/form-list";
import { Label } from "@heffl/ui/components/primitives/label";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import { Sheet } from "@heffl/ui/components/primitives/sheet";
import { Switch } from "@heffl/ui/components/primitives/switch";
import { produce } from "immer";
import { sum } from "radash";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { FsSchedule, jobSchema } from "./job-form";
import { Input } from "@heffl/ui/components/primitives/input";
import heffl from "@/helpers/heffl";
import { makeEllipsis } from "@heffl/ui/lib/utils";

const ScheduleEditModal = ({
  open,
  onClose,
  value,
  onChange,
  number,
  form,
}: {
  open: boolean;
  onClose: () => void;
  value: FsSchedule;
  onChange: (value: FsSchedule) => void;
  number: number;
  form: UseFormReturn<z.infer<typeof jobSchema>>;
}) => {
  const allSchedules = form.watch("fsSchedules");
  const fsJobServices = form.watch("fsJobServices");

  const scheduleServices = value.fsScheduleServices || [];

  const jobItems = fsJobServices?.map((service) => ({
    ...service,
    availableQuantity:
      service.quantity -
      sum(
        allSchedules
          ?.filter((s) => s.uuid !== value.uuid)
          .map(
            (schedule) =>
              schedule.fsScheduleServices.find(
                (s) => s.fsJobServiceIdUuid === service.uuid
              )?.quantity || 0
          ) || []
      ),
  }));

  const onModalClose = () => {
    onClose();
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onModalClose}
      title={
        <div className="flex gap-2">
          Edit schedule - {dayjs(value.startDate).format("DD/MM/YYYY")} -{" "}
          {number}
        </div>
      }
      modalClassName="max-w-2xl"
    >
      <div className="overflow-x-auto mt-2 w-full">
        <p className="text-sm font-semibold">Schedule Line items</p>
        <table className="mt-2 w-full table-auto">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-4 py-2 text-left">Name</th>
              <th className="px-4 py-2 text-right">Quantity</th>
              <th className="px-4 py-2 text-right">Price</th>
              <th className="px-4 py-2 text-right">Tax</th>
              <th className="px-4 py-2 text-right">Total</th>
              <th className="px-4 py-2 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {jobItems?.map((jobService) => {
              const scheduleService = scheduleServices?.find(
                (service) => service.fsJobServiceIdUuid === jobService.uuid
              );
              const scheduleServiceIndex = scheduleServices?.findIndex(
                (service) => service.fsJobServiceIdUuid === jobService.uuid
              );
              return (
                <tr key={jobService.uuid} className="border-b">
                  <td className="px-4 py-2">{jobService.name}</td>
                  <td className="px-4 py-2 w-20 text-right">
                    <Select
                      hideSearch
                      disabled={!scheduleService}
                      placeholder="NIL"
                      value={scheduleService?.quantity?.toString()}
                      onChange={(val) => {
                        onChange(
                          produce(value, (draft) => {
                            draft.fsScheduleServices[
                              scheduleServiceIndex
                            ].quantity = Number(val);
                          })
                        );
                      }}
                      options={Array.from(
                        { length: jobService.availableQuantity },
                        (_, i) => ({
                          label: (i + 1).toString(),
                          value: (i + 1).toString(),
                        })
                      )}
                    />
                  </td>
                  <td className="px-4 py-2 text-right">
                    <Input
                      type="number"
                      className="ml-auto w-24"
                      value={jobService.price}
                      onChange={(e) => {
                        onChange(
                          produce(value, (draft) => {
                            draft.fsScheduleServices[
                              scheduleServiceIndex
                            ].price = Number(e.target.value);
                          })
                        );
                      }}
                    />
                  </td>
                  <td className="px-4 py-2 text-right">
                    {jobService.tax ? `${jobService.tax}%` : "NIL"}
                  </td>
                  <td className="px-4 py-2 text-right">
                    {(
                      jobService.price *
                      (scheduleService?.quantity || 0) *
                      (1 + (jobService.tax || 0) / 100)
                    ).toFixed(2)}
                  </td>
                  <td className="px-4 py-2 text-center">
                    <Button
                      onClick={() => {
                        if (scheduleService) {
                          onChange(
                            produce(value, (draft) => {
                              draft.fsScheduleServices =
                                draft.fsScheduleServices.filter(
                                  (service) =>
                                    service.fsJobServiceIdUuid !==
                                    jobService.uuid
                                );
                            })
                          );
                        } else if (jobService) {
                          if (jobService.availableQuantity < 1) {
                            return heffl.toast.error(
                              "Not enough quantity available for this service"
                            );
                          }

                          const prev = value.fsScheduleServices || [];
                          onChange(
                            produce(value, (draft) => {
                              draft.fsScheduleServices = [
                                ...prev,
                                {
                                  ...jobService,
                                  fsJobServiceIdUuid: jobService.uuid,
                                  quantity: 1,
                                  type: "SERVICE",
                                  viewType: "LINE_ITEM",
                                },
                              ];
                            })
                          );
                        }
                      }}
                      variant={scheduleService ? "destructive" : "primary"}
                      icon={scheduleService ? Trash2 : Plus}
                      size="sm"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <FormField name="notes" label="Notes" className="mt-4">
        <MiniRichTextEditor height={130} placeholder="Schedule related notes" />
      </FormField>
    </ModalDrawer>
  );
};

export const SchedulesInput = ({
  value = [],
  name,
  form,
  edit,
}: {
  name?: string;
  value?: FsSchedule[];
  onChange?: (value: FsSchedule[]) => void;
  form: UseFormReturn<z.infer<typeof jobSchema>>;
  edit?: boolean;
}) => {
  const [showSchedulesList, setShowSchedulesList] = useState(false);
  const [showScheduleEdit, setShowScheduleEdit] = useState<{
    uuid: string;
    number: number;
  } | null>(null);

  const { data: tags } = trpc.tags.list.useQuery({
    type: "SCHEDULE",
  });
  // TODO add enddate if start date is changed

  const scheduleToEdit = value?.find(
    (schedule) => schedule.uuid === showScheduleEdit?.uuid
  );

  return (
    <>
      {scheduleToEdit && showScheduleEdit && (
        <ScheduleEditModal
          form={form}
          open={showScheduleEdit !== null}
          onClose={() => setShowScheduleEdit(null)}
          value={scheduleToEdit}
          onChange={(updatedSchedule) => {
            const updatedValue = produce(value, (draft) => {
              const scheduleIndex = draft.findIndex(
                (schedule) => schedule.uuid === updatedSchedule.uuid
              );
              if (scheduleIndex !== -1) {
                draft[scheduleIndex] = updatedSchedule;
              }
            });
            form.setValue("fsSchedules", updatedValue);
          }}
          number={showScheduleEdit.number}
        />
      )}
      <Sheet
        title="Edit schedules"
        open={showSchedulesList}
        onClose={() => setShowSchedulesList(false)}
        className="w-[80vw]"
      >
        <div className="mt-8">
          <FormList name={name || ""}>
            {({ append, _name, remove }) => {
              return (
                <div className="flex flex-col gap-2">
                  <Button
                    onClick={() =>
                      append({
                        startDate: dayjs().toDate(),
                        endDate: dayjs().toDate(),
                        timeDisabled: true,
                        status: "SCHEDULED" as const,
                        invoiceRequired: false,
                        fsScheduleAssignees:
                          value && value.length
                            ? value[0].fsScheduleAssignees
                            : [],
                      })
                    }
                    variant="primary"
                    size="md"
                    className="self-end"
                  >
                    <Plus className="h-5" />
                    Add new schedule
                  </Button>
                  <div className="grid grid-cols-4 gap-3 mt-4">
                    {value?.map((schedule, index) => (
                      <Card
                        key={schedule.startDate.toString()}
                        className="relative gap-2 items-start !px-2"
                      >
                        <div className="">
                          <div className="flex gap-2 justify-between items-center">
                            <div className="flex gap-2 items-center">
                              <span className="rounded-full w-4 text-center h-4 bg-[#22577A] text-white text-[11px] flex items-center justify-center">
                                {index + 1}
                              </span>
                              <FormLabel>Start date</FormLabel>
                            </div>
                            <div className="flex gap-2 items-center">
                              <Button
                                onClick={() =>
                                  setShowScheduleEdit({
                                    uuid: schedule.uuid || "",
                                    number: index + 1,
                                  })
                                }
                                size="xs"
                                icon={Pencil}
                                disabled={edit}
                              >
                                Edit
                              </Button>
                              <Button
                                size="xs"
                                icon={Trash2}
                                variant="destructive"
                                onClick={() => remove(index)}
                                className="px-1 w-fit"
                              />
                            </div>
                          </div>
                          <FormField
                            name={_name(index, "startDate")}
                            className="mt-3"
                          >
                            <DatePicker />
                          </FormField>
                          <FormField name={_name(index, "fsScheduleTags")}>
                            <MultiSelect
                              icon={Tag}
                              placeholder="Add tags"
                              options={
                                tags?.map(({ name, id, color }) => ({
                                  label: name,
                                  value: id,
                                  color,
                                })) || []
                              }
                            />
                          </FormField>
                          <div className="flex justify-between items-center">
                            <Label>Invoice required</Label>
                            <FormField
                              name={_name(index, "invoiceRequired")}
                              className="w-fit"
                            >
                              <Switch size="small" />
                            </FormField>
                          </div>
                          <div className="flex flex-wrap gap-2">
                            {schedule.fsScheduleServices?.map((service) => (
                              <Badge
                                small
                                key={service.fsJobServiceIdUuid}
                                variant="outline"
                                icon={Wrench}
                              >
                                {makeEllipsis(service.name, 10)} x{" "}
                                <span className="font-medium">
                                  {service.quantity}
                                </span>
                              </Badge>
                            ))}
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>
              );
            }}
          </FormList>
        </div>
      </Sheet>
      <div className="flex justify-between mt-6 w-full">
        <Label>Schedules</Label>
        <Button
          onClick={() => setShowSchedulesList(true)}
          size="xs"
          className="flex gap-1 items-center"
        >
          <span className="flex items-center">
            <Pencil className="h-3.5" /> Edit schedules
          </span>
          <span className="text-xs">({value?.length})</span>
        </Button>
      </div>
      <div className="grid grid-cols-3 gap-2 justify-between pt-1 w-full">
        {value?.slice(0, 5).map((schedule) => (
          <div
            className="flex gap-2 justify-center items-center p-2 w-full text-xs rounded-md border shadow-sm"
            key={schedule.startDate.toString()}
          >
            <CalendarDays className="w-4 h-4 text-yellow-600" />
            <p>{dayjs(schedule.startDate).format("DD/MM/YYYY")}</p>
          </div>
        ))}
        {value && value.length > 5 && (
          <div
            onClick={() => setShowSchedulesList(true)}
            className="flex gap-2 justify-center items-center p-2 w-full text-xs rounded-md border shadow-sm cursor-pointer text-primary-800"
          >
            +{value.length - 5} more
          </div>
        )}
      </div>
    </>
  );
};
