import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filters";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { getJobId } from "@/pages/field-service/jobs/list";
import calculateInvoice from "@heffl/server/src/helpers/lineItems/calculateInvoice";
import enums from "@heffl/server/src/schemas/enums";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { formatAddress, formatCurrency } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { FileText } from "lucide-react";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

type VendorFilter = {
  vendors: number[];
  dates: [Date, Date] | undefined;
  jobStatuses: z.infer<typeof enums.jobStatus>[];
  clients: number[];
};

const VendorServiceReport: React.FC = () => {
  const [filters, setFilters] = useParamsState<VendorFilter>({
    vendors: [],
    dates: [dayjs().startOf("month").toDate(), dayjs().endOf("month").toDate()],
    clients: [],
    jobStatuses: ["COMPLETED"],
  });
  const [vendorSearch, setVendorSearch] = useState("");
  const [clientSearch, setClientSearch] = useState("");

  const { data: vendors } = trpc.purchases.vendors.list.useQuery({
    search: vendorSearch,
  });

  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageNo: 1,
    pageSize: 10,
  });

  const vendorFilters = useMemo(
    () => ({
      vendors: filters.vendors,
      dates: filters.dates,
      clients: filters.clients,
      jobStatuses: filters.jobStatuses,
    }),
    [filters]
  );

  const { data: vendorSummary } =
    trpc.sales.reports.vendorSummaryReport.useQuery(vendorFilters);

  const navigate = useNavigate();

  if (!vendorSummary || !clients || !vendors) {
    return <FullScreenSpinner />;
  }

  return (
    <Page title="Vendor Summary Report" fullWidth className="sm:!p-0">
      <div className="flex flex-wrap items-center pt-3 w-full">
        <FilterBar
          className="px-3"
          filters={[
            {
              key: "dates",
              type: "date-range",
              label: "Date",
              value: filters.dates,
              onChange: (value) => setFilters({ dates: value as [Date, Date] }),
            },
            {
              key: "vendors",
              type: "checkbox",
              label: "Vendors",
              value: filters.vendors,
              showSearch: true,
              onChange: (value) => setFilters({ vendors: value as number[] }),
              onSearch: (value) => setVendorSearch(value),
              options: vendors?.vendors.map((vendor) => ({
                label: vendor.name,
                value: vendor.id,
              })),
            },
            {
              key: "jobStatuses",
              type: "checkbox",
              label: "Status",
              value: filters.jobStatuses,
              showSearch: true,
              onChange: (value) =>
                setFilters({
                  jobStatuses: value as z.infer<typeof enums.jobStatus>[],
                }),
              options: [
                {
                  label: "Active",
                  value: "ACTIVE",
                },
                {
                  label: "Completed",
                  value: "COMPLETED",
                },
                {
                  label: "Cancelled",
                  value: "CANCELLED",
                },
              ],
            },
            {
              key: "clients",
              type: "checkbox",
              label: "Clients",
              value: filters.clients,
              showSearch: true,
              onChange: (value) => setFilters({ clients: value as number[] }),
              onSearch: (value) => setClientSearch(value),
              options: clients?.clients.map((client) => ({
                label: client.name,
                value: client.id,
              })),
            },
          ]}
        />
      </div>

      <div className="grid grid-cols-2 gap-4 p-3 sm:grid-cols-3 lg:grid-cols-6">
        <Card>
          <h3 className="text-xs font-medium text-gray-500">Total Revenue</h3>
          <p className="mt-1 text-xl font-semibold text-gray-900">
            {formatCurrency(vendorSummary.summary.totalCharge, "AED")}
          </p>
        </Card>
      </div>
      <DataGrid
        rowKey="id"
        name="vendorSummaryReport"
        className="h-[calc(100vh-190px-var(--header-height))] mt-12"
        label="Vendor Summary"
        loading={!vendorSummary}
        empty={{
          icon: FileText,
          title: "No vendor data",
          description:
            "There is no vendor data to display in the summary report.",
        }}
        rows={vendorSummary.fsJobs || []}
        columns={[
          {
            key: "jobDateTime",
            name: "Job Date",
            width: 180,
            renderCell: ({ row }) => dayjs(row.startDate).format("DD/MM/YYYY"),
          },
          {
            key: "vendorName",
            name: "Vendor Name",
            width: 200,
            renderCell: ({ row }) => (
              <div
                className="cursor-pointer hover:text-primary-600 hover:underline"
                onClick={() =>
                  navigate(`/purchases/vendors/details/${row.vendors?.id}`)
                }
              >
                {row.vendors?.name || "-"}
              </div>
            ),
          },
          {
            key: "commission",
            name: "Commission",
            width: 150,
            renderCell: ({ row }) =>
              formatCurrency(row.vendorCharge || 0, "AED", true),
          },
          {
            key: "jobStatus",
            name: "Job Status",
            width: 150,
            renderCell: ({ row }) => row.status || "-",
          },
          {
            key: "jobId",
            name: "Job Id",
            width: 150,
            renderCell: ({ row }) => (
              <div
                className="truncate cursor-pointer hover:text-primary-600"
                onClick={() =>
                  navigate(`/field-service/jobs/details/${row.id}`)
                }
              >
                {getJobId(row)}
              </div>
            ),
          },
          {
            key: "invoices",
            name: "Invoices",
            width: 150,
            renderCell: ({ row }) =>
              row.fsJobInvoices.map(({ invoices: invoice }) => (
                <div
                  key={invoice.id}
                  className="cursor-pointer hover:text-primary-600 hover:underline"
                  onClick={() =>
                    navigate(`/sales/invoices/details/${invoice.id}`)
                  }
                >
                  #{invoice.number} - {calculateInvoice(invoice).invoiceTotal}
                </div>
              )),
          },

          {
            key: "services",
            name: "Services",
            width: 250,
            renderCell: ({ row }) =>
              row.fsJobServices.map((s) => s.name).join(", ") || "-",
          },

          {
            key: "clientName",
            name: "Client Name",
            width: 200,
            renderCell: ({ row }) => row.fsProperties?.clients?.name || "-",
          },
          {
            key: "propertyAddress",
            name: "Property Address",
            width: 300,
            renderCell: ({ row }) => formatAddress(row.fsProperties),
          },
        ]}
      />
    </Page>
  );
};

export default VendorServiceReport;
