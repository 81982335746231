import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import useQueryParams from "@/helpers/useQuery";
import { UAE_TAX } from "@/lib/constants";
import usePermissions from "@/lib/hooks/usePermissions";
import { calculateMultiplierTotal } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import Schemas from "@heffl/server/src/schemas";
import { Form } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import ResponsivePrimaryButton from "@heffl/ui/components/ResponsivePrimaryButton";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { Plus } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import InvoiceForm from "./components/invoice-form";
import toast from "react-hot-toast";
import { omit } from "radash";

const AddInvoice = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const projectId = queryParams.get("projectId");
  const clientId = queryParams.get("clientId");
  const quotationId = queryParams.get("quotationId");
  const invoiceId = queryParams.get("invoiceId");
  const fsJobId = queryParams.get("fsJobId");
  const fsScheduleId = queryParams.get("fsScheduleId");

  const permissions = usePermissions();

  const { data: teamSettings } = trpc.teams.currentTeam.useQuery();
  const { data: convertQuotation } = trpc.quotations.details.useQuery(
    Number(quotationId),
    {
      enabled: !!quotationId,
    }
  );
  const { data: convertJob } = trpc.fieldService.jobs.details.useQuery(
    Number(fsJobId),
    {
      enabled: !!fsJobId,
    }
  );
  const { data: convertProject } = trpc.projects.details.useQuery(
    Number(projectId),
    {
      enabled: !!projectId,
    }
  );
  const { data: duplicateInvoice } = trpc.invoices.details.useQuery(
    Number(invoiceId),
    {
      enabled: !!invoiceId,
    }
  );

  const { data: invoiceTags } = trpc.tags.list.useQuery({
    type: "INVOICE",
  });

  const form = useForm<z.infer<typeof Schemas.sales.invoice>, unknown>({
    resolver: zodResolver(Schemas.sales.invoice),
    defaultValues: {
      date: new Date(),
      dueDate: dayjs()
        .add(teamSettings?.teamSettings?.invoice?.dueDateDays ?? 30, "days")
        .toDate(),
      clientId: convertQuotation?.clients?.id,
      invoiceProducts: [
        {
          productId: undefined,
          quantity: 1,
          price: 0,
          description: "",
          tax: UAE_TAX,
          viewType: "LINE_ITEM",
        },
      ],
    },
  });

  const invoiceDate = form.watch("date");

  useEffect(() => {
    if (invoiceDate) {
      // TODO add to ui
      let dueDateDays = 30;
      if (
        teamSettings &&
        teamSettings.teamSettings.invoice.dueDateDays !== undefined
      ) {
        dueDateDays = teamSettings?.teamSettings?.invoice?.dueDateDays;
      }
      if (
        convertJob &&
        convertJob.paymentDueDays !== undefined &&
        convertJob.paymentDueDays !== null
      ) {
        dueDateDays = convertJob.paymentDueDays;
      }
      form.setValue(
        "dueDate",
        dayjs(invoiceDate).add(dueDateDays, "days").toDate()
      );
    }
  }, [invoiceDate, teamSettings]);

  const invoiceAddMutation = trpc.invoices.add.useMutation({
    onSuccess: (newInvoice) => {
      navigate(`/sales/invoices/details/${newInvoice.id}`);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  useEffect(() => {
    if (clientId) {
      form.setValue("clientId", Number(clientId));
    }
  }, [clientId]);

  useEffect(() => {
    if (convertProject) {
      convertProject.clients &&
        form.setValue("clientId", convertProject.clients.id);
      form.setValue("projectInvoices.projectId", convertProject.id);
      form.setValue("discount", convertProject.discount);
      form.setValue("salesPersonId", convertProject.salesPersonId);
      form.setValue(
        "invoiceProducts",
        convertProject.projectItems.map((item) => ({
          ...omit(item, ["id"]),
          buyPrice: item.buyPrice,
        }))
      );
    }
  }, [convertProject]);

  useEffect(() => {
    if (convertQuotation) {
      form.setValue("clientId", convertQuotation.clients.id);
      form.setValue(
        "invoiceProducts",
        convertQuotation.quotationProducts.map((product) => ({
          ...product,
          quantity:
            calculateMultiplierTotal({
              customFieldFields:
                convertQuotation.documentTemplates.customFields,
              customFieldValues: product.customFields,
            }) * product.quantity,
          buyPrice: product.products?.buyPrice || 0,
        }))
      );
      form.setValue("contactId", convertQuotation.contactId);
      form.setValue("discount", convertQuotation.discount);
    }
  }, [convertQuotation]);

  useEffect(() => {
    if (convertJob) {
      const dateString = dayjs(convertJob.startDate).isSame(
        convertJob.endDate,
        "day"
      )
        ? `on ${dayjs(convertJob.startDate).format("DD/MM/YYYY")}`
        : `on ${convertJob.fsSchedules
            .filter((schedule) => schedule.status === "COMPLETED")
            .map((schedule) => dayjs(schedule.startDate).format("DD/MM/YYYY"))
            .join(", ")}`;
      const jobDescription = `Serviced in ${
        convertJob.fsProperties.name
          ? `${convertJob.fsProperties.name}, ${convertJob.fsProperties.city}`
          : convertJob.fsProperties.city
      } ${dateString}.`;

      form.setValue("clientId", convertJob.fsProperties.clientId);
      form.setValue("fsJobInvoices.fsJobId", convertJob.id);

      if (convertJob.type === "ONEOFF" || convertJob.paymentsCount <= 1) {
        form.setValue(
          "invoiceProducts",
          convertJob.fsJobServices.map((service) => {
            return {
              ...service,
              description: `${service.description}${
                teamSettings?.teamSettings?.invoice?.addJobDescription
                  ? jobDescription
                  : ""
              }`,
              buyPrice: service.products?.buyPrice || 0,
            };
          })
        );
        form.setValue("discount", convertJob.discount);
      } else if (convertJob.type === "CONTRACT") {
        const products = convertJob.fsJobServices.map((service) => {
          const divisible = service.quantity % convertJob.paymentsCount === 0;

          const quantity = divisible
            ? service.quantity / convertJob.paymentsCount
            : 1;

          const price = divisible
            ? service.price
            : service.price / convertJob.paymentsCount;

          return {
            ...service,
            quantity,
            price,
            description: `${service.description}${
              teamSettings?.teamSettings?.invoice?.addJobDescription
                ? jobDescription
                : ""
            }`,
          };
        });
        form.setValue(
          "invoiceProducts",
          products.map((p) => ({ ...p, buyPrice: p.products?.buyPrice || 0 }))
        );
        form.setValue(
          "discount",
          convertJob.discount / convertJob.paymentsCount > 0
            ? convertJob.discount / convertJob.paymentsCount
            : convertJob.discount
        );
      }

      form.setValue("salesPersonId", convertJob.salesPersonId);
    }
  }, [convertJob]);

  useEffect(() => {
    if (fsScheduleId) {
      form.setValue("fsJobInvoices.fsScheduleId", Number(fsScheduleId));
    }
  }, [fsScheduleId]);

  useEffect(() => {
    if (duplicateInvoice) {
      form.setValue("clientId", duplicateInvoice.clients.id);
      form.setValue("invoiceProducts", duplicateInvoice.invoiceProducts);
      form.setValue("contactId", duplicateInvoice.contactId);
      form.setValue("discount", duplicateInvoice.discount);
      form.setValue("salesPersonId", duplicateInvoice.salesPersonId);
      form.setValue("templateId", duplicateInvoice.templateId);
      form.setValue("contentHtml", duplicateInvoice.contentHtml);
    }
  }, [duplicateInvoice]);

  if (
    permissions &&
    !permissions.CREATE_INVOICES.allowed &&
    !permissions.CONVERT_INVOICES.allowed
  ) {
    return (
      <div className="flex flex-col items-center h-screen">
        <div className="flex gap-3 items-center px-4 py-[10px] w-full border-b border-gray-200">
          <h1 className="text-xl font-semibold">No permission</h1>
        </div>
        <p className="mt-5  md:text-xl text-primaryLight max-w-[690px] text-center font-medium px-2">
          You dont have permission to create invoices
        </p>
      </div>
    );
  }

  if (!invoiceTags) return <FullScreenSpinner />;
  return (
    <Page
      showBack
      className="max-w-screen-lg"
      title="Add invoice"
      breadcrumbs={[
        {
          label: "Invoices",
          path: "/sales/invoices",
        },
        {
          label: "Add invoice",
          path: "/sales/invoices/add",
        },
      ]}
      suffix={
        <div>
          <ResponsivePrimaryButton
            onClick={() =>
              form.handleSubmit((values) => invoiceAddMutation.mutate(values))()
            }
            type="submit"
            variant="primary"
            loading={invoiceAddMutation.isLoading}
            icon={Plus}
          >
            Add Invoice
          </ResponsivePrimaryButton>
        </div>
      }
    >
      <div className="flex flex-col w-full">
        <Form
          {...form}
          onSubmit={(values) => {
            invoiceAddMutation.mutate(values);
          }}
        >
          <div className="flex justify-center px-3 py-5 w-full sm:px-0">
            <InvoiceForm form={form} tags={invoiceTags} />
          </div>
        </Form>
      </div>
    </Page>
  );
};

export default AddInvoice;
