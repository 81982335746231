import DataTable from "@/components/DataTable";
import DropMenu from "@/components/DropMenu";
import Empty from "@/components/Empty";
import FilterBar from "@/components/filters";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@heffl/ui/components/primitives/accordion";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { formatCurrency } from "@heffl/ui/lib/utils";
import {
  AreaChart,
  BarChart,
  BarList,
  DonutChart,
  Legend,
  LineChart,
  ProgressBar,
} from "@tremor/react";
import dayjs from "dayjs";
import { Hammer, Image, Tags } from "lucide-react";
import { useRef } from "react";
import { exportComponentAsPNG } from "react-component-export-image";
import { useNavigate } from "react-router-dom";

export const LineText = ({
  label,
  value,
  color,
}: {
  label: string;
  value: string;
  color: string;
}) => {
  return (
    <div className="flex justify-between items-center w-full">
      <div className="flex gap-3 items-center">
        <span className="w-1 h-8" style={{ backgroundColor: color }}></span>
        <p className="text-sm font-medium text-gray-500">{label}</p>
      </div>
      <p className="text-sm font-medium">{value}</p>
    </div>
  );
};

type Filters = {
  dates: [Date, Date] | undefined;
};

const Dashboard = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useParamsState<Filters>({
    dates: [dayjs().subtract(7, "day").toDate(), dayjs().toDate()],
  });
  const fullDashbaordRef = useRef<HTMLDivElement>(null);
  const topProductsRef = useRef<HTMLDivElement>(null);

  const { data: dashboardData } =
    trpc.fieldService.dashboard.dashboard.useQuery({
      startDate: filters.dates?.[0],
      endDate: filters.dates?.[1],
    });

  return (
    <Page title="Dashboard">
      <div className="flex justify-between items-center">
        <FilterBar
          className=""
          filters={[
            {
              key: "date",
              type: "date-range",
              label: "Date",
              value: filters.dates,
              onChange: (value) => setFilters({ dates: value }),
            },
          ]}
        />
        <DropMenu
          items={[
            {
              label: "PNG",
              onClick: () => {
                exportComponentAsPNG(fullDashbaordRef, {
                  fileName: `dashboard-${dayjs().format("DD-MM-YYYY")}`,
                  html2CanvasOptions: {
                    height: fullDashbaordRef.current?.clientHeight || 10 + 30,
                    width: fullDashbaordRef.current?.clientWidth,
                  },
                });
              },
              icon: Image,
            },
          ]}
        >
          <Button variant="outline" size="sm">
            Export
          </Button>
        </DropMenu>
      </div>
      {dashboardData ? (
        <div ref={fullDashbaordRef}>
          <div className="grid grid-cols-2 gap-2 mt-4 sm:grid-cols-5">
            <Card
              parentClassName="bg-red-50 cursor-pointer"
              onClick={() => {
                const params = new URLSearchParams({
                  statuses: JSON.stringify(["SCHEDULED"]),
                  dates: JSON.stringify(filters.dates),
                });
                navigate(`/field-service/schedules/list?${params.toString()}`);
              }}
            >
              <p className="text-sm font-medium text-red-400">Yet to confirm</p>
              <p className="text-xl font-semibold text-red-500 sm:text-xl">
                {dashboardData.scheduleStats[0].value}
              </p>
            </Card>
            <Card
              parentClassName="bg-red-50 cursor-pointer"
              onClick={() => {
                const params = new URLSearchParams({
                  assignees: JSON.stringify([0]),
                  dates: JSON.stringify(filters.dates),
                  statuses: JSON.stringify([
                    "SCHEDULED",
                    "CONFIRMED",
                    "ARRIVED",
                    "ON_MY_WAY",
                    "IN_PROGRESS",
                  ]),
                });
                navigate(`/field-service/schedules/list?${params.toString()}`);
              }}
            >
              <p className="text-sm font-medium text-red-4000">Unassigned</p>
              <p className="text-xl font-semibold text-red-500 sm:text-xl">
                {dashboardData.scheduleStats[7].value}
              </p>
            </Card>
            <Card
              parentClassName="cursor-pointer"
              onClick={() => {
                const params = new URLSearchParams({
                  statuses: JSON.stringify(["CONFIRMED"]),
                  dates: JSON.stringify(filters.dates),
                });
                navigate(`/field-service/schedules/list?${params.toString()}`);
              }}
            >
              <p className="text-sm font-medium text-gray-500">
                Confirmed schedules
              </p>
              <p className="text-xl font-semibold sm:text-xl">
                {dashboardData.scheduleStats[1].value}
              </p>
            </Card>
            <Card
              parentClassName=" cursor-pointer"
              onClick={() => {
                const params = new URLSearchParams({
                  statuses: JSON.stringify([
                    "IN_PROGRESS",
                    "ON_MY_WAY",
                    "ARRIVED",
                  ]),
                  dates: JSON.stringify(filters.dates),
                });
                navigate(`/field-service/schedules/list?${params.toString()}`);
              }}
            >
              <p className="text-sm font-medium text-gray-5000">
                In progress schedules
              </p>
              <p className="text-xl font-semibold sm:text-xl">
                {dashboardData.scheduleStats
                  .filter((stat) =>
                    ["IN_PROGRESS", "ON_MY_WAY", "ARRIVED"].includes(stat.name)
                  )
                  .reduce((acc, stat) => acc + stat.value, 0)}
              </p>
            </Card>
            <Card
              parentClassName="cursor-pointer"
              onClick={() => {
                const params = new URLSearchParams({
                  statuses: JSON.stringify(["COMPLETED"]),
                  dates: JSON.stringify(filters.dates),
                });
                navigate(`/field-service/schedules/list?${params.toString()}`);
              }}
            >
              <p className="text-sm font-medium text-gray-500">
                Completed schedules
              </p>
              <p className="text-xl font-semibold sm:text-xl">
                {dashboardData.scheduleStats[5].value}
              </p>
            </Card>
          </div>

          <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2">
            <Card className="flex flex-col justify-center items-center">
              <p className="self-start mb-4 text-sm font-medium text-gray-500">
                Job by status
              </p>
              <DonutChart
                data={dashboardData.jobStats}
                category="value"
                index="name"
                colors={["orange", "green", "red"]}
                className="w-40"
              />
              <Legend
                categories={["Active", "Completed", "Cancelled"]}
                colors={["orange", "green", "red"]}
                className="mt-4"
              />
              <DataTable
                className="mt-4"
                rowKey="name"
                data={dashboardData.jobStats}
                columns={[
                  {
                    title: "Status",
                    render: (value) => value.name,
                  },
                  {
                    title: "Count",
                    render: (value) => value.value,
                  },
                ]}
              />
            </Card>
            <Card>
              <p className="mb-4 text-sm font-medium text-gray-500">
                Job schedule by status
              </p>
              {dashboardData.scheduleStats.length ? (
                <BarList
                  data={dashboardData.scheduleStats.map((stat) => ({
                    name: stat.name,
                    value: stat.value,
                  }))}
                  onValueChange={(value) => {
                    const params = new URLSearchParams({
                      ...(value.name !== "UNASSIGNED" && {
                        statuses: JSON.stringify([value.name]),
                      }),
                      dates: JSON.stringify(filters.dates),
                      ...(value.name === "UNASSIGNED" && {
                        assignees: JSON.stringify([0]),
                      }),
                    });
                    navigate(
                      `/field-service/schedules/list?${params.toString()}`
                    );
                  }}
                  className="mx-auto max-w-sm"
                />
              ) : (
                <div className="flex flex-col justify-center items-center h-full">
                  <Empty
                    title="No job schedules added"
                    description="Please add a job schedule in the settings"
                    icon={Hammer}
                  />
                </div>
              )}
            </Card>
            <Card>
              <p className="mb-4 text-sm font-medium text-gray-500">
                Job schedule by tags
              </p>
              {dashboardData.scheduleTagsCount.length ? (
                <BarList
                  data={dashboardData.scheduleTagsCount.map((stat) => ({
                    name: stat.name,
                    value: stat.value,
                  }))}
                  color={"fuchsia"}
                  className="mx-auto max-w-sm"
                />
              ) : (
                <div className="flex flex-col justify-center items-center h-full">
                  <Empty
                    title="No job schedule tag added"
                    description="Please add a job schedule tag in the settings"
                    icon={Tags}
                  />
                </div>
              )}
            </Card>
            <Card>
              <p className="mb-4 text-sm font-medium text-gray-500">
                Job by tags
              </p>
              {dashboardData.jobTagsCount.length ? (
                <BarList
                  data={dashboardData.jobTagsCount.map((stat) => ({
                    name: stat.name,
                    value: stat.value,
                  }))}
                  color={"indigo"}
                  className="mx-auto max-w-sm"
                />
              ) : (
                <div className="flex flex-col justify-center items-center h-full">
                  <Empty
                    title="No job tag added"
                    description="Please add a job tag in the settings"
                    icon={Tags}
                  />
                </div>
              )}
            </Card>

            <Card ref={topProductsRef}>
              <div className="flex justify-between items-center">
                <p className="mb-4 text-sm font-medium text-gray-500">
                  Top Products
                </p>
                <DropMenu
                  items={[
                    {
                      label: "PNG",
                      onClick: () => {
                        console.log("logged");
                        exportComponentAsPNG(topProductsRef, {
                          fileName: `top-products-${dayjs().format(
                            "DD-MM-YYYY"
                          )}`,
                          html2CanvasOptions: {
                            height: topProductsRef.current
                              ? topProductsRef.current.clientHeight + 30
                              : 10,
                            width: topProductsRef.current
                              ? topProductsRef.current.clientWidth
                              : undefined,
                          },
                        });
                      },
                      icon: Image,
                    },
                  ]}
                >
                  <Button variant="outline" size="sm">
                    Export
                  </Button>
                </DropMenu>
              </div>
              <div className="flex flex-col gap-2 items-center mt-4 w-full">
                {dashboardData.topProducts &&
                dashboardData.topProducts?.length > 0 ? (
                  <>
                    <BarChart
                      data={dashboardData.topProducts}
                      categories={["usageCount"]}
                      index="name"
                      colors={["green"]}
                    />
                    <Accordion
                      type="single"
                      collapsible
                      className="mt-4 w-full"
                    >
                      <AccordionItem value="item-1">
                        <AccordionTrigger>Show data in table</AccordionTrigger>
                        <AccordionContent>
                          <DataTable
                            className="mt-4"
                            rowKey="name"
                            data={dashboardData.topProducts}
                            columns={[
                              {
                                title: "Name",
                                render: (value) => value.name,
                              },
                              {
                                title: "Usage Count",
                                render: (value) => value.usageCount,
                              },
                            ]}
                          />
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </>
                ) : (
                  <Empty
                    title="No products added"
                    description="Please add a product in the settings"
                    icon={Tags}
                  />
                )}
              </div>
            </Card>

            <Card>
              <p className="mb-4 text-sm font-medium text-gray-500">
                Number of jobs by worker
              </p>
              <div className="flex flex-col gap-2 items-center mt-4 w-full">
                {dashboardData.jobsCountByWorker &&
                dashboardData.jobsCountByWorker?.length > 0 ? (
                  <>
                    <BarChart
                      data={dashboardData.jobsCountByWorker}
                      categories={["jobCount"]}
                      index="name"
                      colors={["green"]}
                    />
                    <Accordion
                      type="single"
                      collapsible
                      className="mt-4 w-full"
                    >
                      <AccordionItem value="item-1">
                        <AccordionTrigger>Show data in table</AccordionTrigger>
                        <AccordionContent>
                          <DataTable
                            className="mt-4"
                            rowKey="name"
                            data={dashboardData.jobsCountByWorker}
                            columns={[
                              {
                                title: "Name",
                                render: (value) => value.name,
                              },
                              {
                                title: "Job Count",
                                render: (value) => value.jobCount,
                              },
                            ]}
                          />
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </>
                ) : (
                  <Empty
                    title="No workers added"
                    description="Please add a worker in the settings"
                    icon={Tags}
                  />
                )}
              </div>
            </Card>

            <Card className="flex flex-col justify-center">
              <p className="self-start mb-4 text-sm font-medium text-gray-500">
                Sales
              </p>
              <p className="flex justify-between items-center text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                <span>
                  {formatCurrency(
                    dashboardData.invoiceStats.paymentsRecieved,
                    "AED"
                  )}{" "}
                  &bull; {dashboardData.invoiceStats.paidPercentage.toFixed(2)}%
                </span>
                <span>
                  {formatCurrency(
                    dashboardData.invoiceStats.invoiceTotal,
                    "AED"
                  )}
                </span>
              </p>
              <ProgressBar
                value={dashboardData.invoiceStats.paidPercentage}
                color="teal"
                className="mt-3"
              />
              <div className="flex flex-col gap-2 items-start mt-4 w-full">
                <LineText
                  label="Total job amount"
                  value={formatCurrency(dashboardData.totalJobAmount, "AED")}
                  color="violet"
                />
                <LineText
                  label="Total invoice amount"
                  value={formatCurrency(
                    dashboardData.invoiceStats.invoiceTotal,
                    "AED"
                  )}
                  color="blue"
                />
                <LineText
                  label="Total paid amount"
                  value={formatCurrency(
                    dashboardData.invoiceStats.paymentsRecieved,
                    "AED"
                  )}
                  color="green"
                />
                <LineText
                  label="Total due amount"
                  value={formatCurrency(
                    dashboardData.invoiceStats.invoicePendingAmount,
                    "AED"
                  )}
                  color="red"
                />
              </div>
            </Card>
            <Card className="flex flex-col justify-center">
              <p className="self-start mb-4 text-sm font-medium text-gray-500">
                Quotations
              </p>
              <p className="flex justify-between items-center text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                <span>
                  {dashboardData.quotationStats[2].value} &bull;{" "}
                  {(
                    (dashboardData.quotationStats[2].value /
                      dashboardData.quotationStats[4].value) *
                    100
                  ).toFixed(2)}
                  %
                </span>
                <span>{dashboardData.quotationStats[4].value}</span>
              </p>
              <ProgressBar
                value={
                  (dashboardData.quotationStats[2].value /
                    dashboardData.quotationStats[4].value) *
                  100
                }
                color="teal"
                className="mt-3"
              />
              <div className="flex flex-col gap-2 items-start mt-4 w-full">
                <LineText
                  label="Total "
                  value={dashboardData.quotationStats[4].value.toString()}
                  color="blue"
                />
                <LineText
                  label="Draft"
                  value={dashboardData.quotationStats[0].value.toString()}
                  color="yellow"
                />
                <LineText
                  label="Sent"
                  value={dashboardData.quotationStats[1].value.toString()}
                  color="violet"
                />
                <LineText
                  label="Accepted"
                  value={dashboardData.quotationStats[2].value.toString()}
                  color="green"
                />
                <LineText
                  label="Rejected"
                  value={dashboardData.quotationStats[3].value.toString()}
                  color="red"
                />
              </div>
            </Card>
          </div>

          <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2">
            <Card>
              <p className="mb-4 text-sm font-medium text-gray-500">
                Sales breakdown
              </p>
              {dashboardData.totalProfit &&
              dashboardData.totalProfit?.length > 0 ? (
                <div className="flex flex-col gap-2 items-center mt-4 w-full">
                  <DonutChart
                    data={dashboardData.totalProfit}
                    category="value"
                    index="name"
                    colors={["green", "red", "blue"]}
                    valueFormatter={(value) => formatCurrency(value, "AED")}
                  />
                  {
                    <Legend
                      categories={dashboardData.totalProfit.map(
                        (stat) => stat.name
                      )}
                      colors={["green", "red", "blue"]}
                    />
                  }
                </div>
              ) : (
                <Empty title="No data" icon={Tags} />
              )}
            </Card>
            <Card>
              <p className="mb-4 text-sm font-medium text-gray-500">
                Expense categories breakdown
              </p>
              <div className="flex flex-col gap-2 items-center mt-4 w-full">
                {dashboardData.expenseCategoriesBreakdown &&
                dashboardData.expenseCategoriesBreakdown?.length > 0 ? (
                  <>
                    <DonutChart
                      data={dashboardData.expenseCategoriesBreakdown}
                      category="amount"
                      index="name"
                      colors={[
                        "green",
                        "red",
                        "blue",
                        "orange",
                        "yellow",
                        "purple",
                      ]}
                      valueFormatter={(value) => formatCurrency(value, "AED")}
                    />
                    <Legend
                      categories={dashboardData.expenseCategoriesBreakdown?.map(
                        (stat) => stat.name
                      )}
                      colors={[
                        "green",
                        "red",
                        "blue",
                        "orange",
                        "yellow",
                        "purple",
                      ]}
                    />
                    <Accordion
                      type="single"
                      collapsible
                      className="mt-4 w-full"
                    >
                      <AccordionItem value="item-1">
                        <AccordionTrigger>Show data in table</AccordionTrigger>
                        <AccordionContent>
                          <DataTable
                            className="mt-4"
                            rowKey="name"
                            data={dashboardData.expenseCategoriesBreakdown}
                            columns={[
                              {
                                title: "Name",
                                render: (value) => value.name,
                              },
                              {
                                title: "Amount",
                                render: (value) =>
                                  formatCurrency(value.amount, "AED"),
                              },
                            ]}
                          />
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </>
                ) : (
                  <Empty title="No data" icon={Tags} />
                )}
              </div>
            </Card>
          </div>

          <Card parentClassName="mt-4">
            <p className="mb-4 text-sm font-medium text-gray-500">
              Total revenue and expense per day
            </p>
            <div className="flex flex-col gap-2 items-center mt-4 w-full">
              {dashboardData.totalRevenueAndExpensePerDay &&
              dashboardData.totalRevenueAndExpensePerDay?.length > 0 ? (
                <>
                  <AreaChart
                    data={dashboardData.totalRevenueAndExpensePerDay}
                    showAnimation={true}
                    categories={["revenue", "expense"]}
                    index="date"
                    colors={["green", "red"]}
                  />
                  <Accordion type="single" collapsible className="mt-4 w-full">
                    <AccordionItem value="item-1">
                      <AccordionTrigger>Show data in table</AccordionTrigger>
                      <AccordionContent>
                        <DataTable
                          className="mt-4"
                          rowKey="date"
                          data={dashboardData.totalRevenueAndExpensePerDay}
                          columns={[
                            {
                              title: "Date",
                              render: (value) => value.date,
                            },
                            {
                              title: "Revenue",
                              render: (value) =>
                                formatCurrency(value.revenue, "AED"),
                            },
                            {
                              title: "Expense",
                              render: (value) =>
                                formatCurrency(value.expense, "AED"),
                            },
                          ]}
                        />
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </>
              ) : (
                <Empty title="No data" icon={Tags} />
              )}
            </div>
          </Card>

          <Card parentClassName="mt-4">
            <p className="mb-4 text-sm font-medium text-gray-500">
              Schedules per day
            </p>
            <div className="flex flex-col gap-2 items-center mt-4 w-full">
              {dashboardData.schedulesPerDay &&
              dashboardData.schedulesPerDay?.length > 0 ? (
                <>
                  <LineChart
                    data={dashboardData.schedulesPerDay}
                    showAnimation={true}
                    categories={[
                      "completed",
                      "cancelled",
                      "scheduled",
                      "inProgress",
                    ]}
                    index="date"
                    colors={["green", "red", "blue", "orange"]}
                  />
                  <Accordion type="single" collapsible className="mt-4 w-full">
                    <AccordionItem value="item-1">
                      <AccordionTrigger>Show data in table</AccordionTrigger>
                      <AccordionContent>
                        <DataTable
                          className="mt-4"
                          rowKey="date"
                          data={dashboardData.schedulesPerDay}
                          columns={[
                            {
                              title: "Date",
                              render: (value) => value.date,
                            },
                            {
                              title: "Completed",
                              render: (value) => value.completed,
                            },
                            {
                              title: "In Progress",
                              render: (value) => value.inProgress,
                            },
                            {
                              title: "Scheduled",
                              render: (value) => value.scheduled,
                            },

                            {
                              title: "Cancelled",
                              render: (value) => value.cancelled,
                            },
                          ]}
                        />
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </>
              ) : (
                <Empty
                  title="No schedules added"
                  description="Please add a schedule in the settings"
                  icon={Tags}
                />
              )}
            </div>
          </Card>
        </div>
      ) : (
        <FullScreenSpinner />
      )}
    </Page>
  );
};

export default Dashboard;
