import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { teamSettingsSchema } from "@heffl/server/src/schemas/teamSettings";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import { Switch } from "@heffl/ui/components/primitives/switch";
import { zodResolver } from "@hookform/resolvers/zod";

import { Save } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

const InvoiceSettings: React.FC = () => {
  const form = useForm<z.infer<typeof teamSettingsSchema>>({
    resolver: zodResolver(teamSettingsSchema),
  });

  const { data: teamDetails } = trpc.teams.currentTeam.useQuery();

  const updateTeamSettingsMutation = trpc.teams.updateTeamSettings.useMutation({
    onSuccess: () => {
      toast.success("Settings updated");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const onSubmit = (data: z.infer<typeof teamSettingsSchema>) => {
    updateTeamSettingsMutation.mutate({
      invoice: data.invoice,
    });
  };

  useEffect(() => {
    if (teamDetails?.teamSettings) {
      form.reset({
        ...teamDetails.teamSettings,
      });
    }
  }, [teamDetails]);

  return (
    <Page title="Invoices">
      <Form {...form} onSubmit={onSubmit}>
        <FormField
          name="invoice.dueDateDays"
          label="Invoice Due Date (Days)"
          description="Number of days until the invoice is due."
        >
          <Input
            type="number"
            className="mt-2 w-96"
            placeholder="Enter number of days"
            {...form.register("invoice.dueDateDays", { valueAsNumber: true })}
          />
        </FormField>
        <FormField
          name="invoice.addJobDescription"
          label="Add Job Description"
          description="Add the job description to the invoice."
        >
          <Switch />
        </FormField>
        <FormField
          name="invoice.showBuyPrice"
          label="Show Buy Price"
          description="Show the buy price on the invoice."
        >
          <Switch />
        </FormField>
        <div className="flex justify-end">
          <Button
            loading={updateTeamSettingsMutation.isLoading}
            type="submit"
            icon={Save}
            variant="primary"
            onClick={form.handleSubmit(onSubmit)}
          >
            Update settings
          </Button>
        </div>
      </Form>
    </Page>
  );
};

export default InvoiceSettings;
