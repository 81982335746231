import Page from "@/components/page";
import { getS3URL } from "@/helpers/s3Helpers";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import FilePicker from "@heffl/ui/components/file-picker";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { Save } from "lucide-react";
import { map } from "radash";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { defaultFilesInput } from "../purchases/expenses/list";

const TeamDetails: React.FC = () => {
  const { mutateAsync: getPresignedUrls } =
    trpc.files.presignedUrls.useMutation({
      onSuccess: (data) => {
        console.log("data", data);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });
  const { data: currentUser } = trpc.users.currentUser.useQuery();
  const { data: team, refetch } = trpc.teams.currentTeam.useQuery();

  const updateTeamMutation = trpc.teams.update.useMutation({
    onSuccess: () => {
      toast.success("Team updated successfully");
      refetch();
    },
  });

  const form = useForm<z.infer<typeof Schemas.teams.team>>({
    resolver: zodResolver(Schemas.teams.team),
  });

  const onSubmit = async (values: z.infer<typeof Schemas.teams.team>) => {
    const presignedUrls = values?.files?.team_logo.new.length
      ? await getPresignedUrls({
          files: values?.files?.team_logo.new,
          section: "teams/logos",
        })
      : [];
    updateTeamMutation.mutate({
      name: values.name,
      address: values.address,
      files: {
        team_logo: {
          deleted: [],
          ...values?.files?.team_logo,
          new: presignedUrls,
        },
      },
    });
    await map(presignedUrls, async (file, index) => {
      const fileToUpload = values?.files?.team_logo.new[index].file;
      await axios.put(file.presignedUrl, fileToUpload, {
        headers: {
          "Content-Type": file.format,
        },
      });
    });
  };

  useEffect(() => {
    if (team) {
      form.reset({
        name: team.name,
        address: team.address,
        files: {
          team_logo: defaultFilesInput(
            team.files?.fields.team_logo ? team.files.fields.team_logo : []
          ),
        },
      });
    }
  }, [team]);

  if (currentUser && currentUser.type !== "SUPER_ADMIN") {
    return <p>Not authorized</p>;
  }

  if (!team) return <FullScreenSpinner />;

  return (
    <Page className="flex justify-center" title="Team Details" showBack>
      <div className="flex flex-col gap-3 p-5 w-full max-w-3xl">
        <Form {...form} onSubmit={onSubmit}>
          <div className="flex gap-3 items-center">
            <div className="overflow-hidden p-2 w-24 h-24 rounded-full border bg-white-300">
              <img
                src={
                  team.files?.fields.team_logo
                    ? getS3URL(team.files.fields.team_logo[0])
                    : ""
                }
                className="object-contain w-full h-full"
                alt="Team logo"
              />
            </div>

            <FormField name="files.team_logo" className="mb-4">
              <FilePicker accept="image/*" showPreview={false} />
            </FormField>
          </div>
          <div className="grid gap-3 mt-4">
            <FormField name="name" label="Team Name">
              <Input />
            </FormField>
            <FormField name="address" label="Address">
              <MiniRichTextEditor placeholder="Enter address" />
            </FormField>
          </div>
          <Button
            loading={updateTeamMutation.isLoading}
            type="submit"
            variant="primary"
            icon={Save}
            className="mt-3"
          >
            Update Team
          </Button>
        </Form>
      </div>
    </Page>
  );
};

export default TeamDetails;
