import FilterBar from "@/components/filters";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { Card } from "@heffl/ui/components/primitives/card";
import Select from "@heffl/ui/components/primitives/creatable-select";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import {
  Tabs,
  TabsList,
  TabsTrigger,
} from "@heffl/ui/components/primitives/tabs";
import { BarChart, DonutChart, Legend } from "@tremor/react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

type Filters = {
  dates: [Date, Date] | undefined;
  pipelineId?: number;
};

const AdminProjectDashboard = () => {
  const [filters, setFilters] = useParamsState<Filters>({
    dates: [dayjs().subtract(30, "day").toDate(), dayjs().toDate()],
    pipelineId: 1,
  });

  const { data } = trpc.projects.dashboard.admin.useQuery({
    startDate: filters.dates?.[0],
    endDate: filters.dates?.[1],
    pipelineId: filters.pipelineId,
  });
  const { data: pipelinesList } = trpc.projects.pipelines.list.useQuery();

  const navigate = useNavigate();

  return (
    <Page className="flex flex-col gap-2">
      <Tabs defaultValue="admin" className="mb-3 w-fit">
        <TabsList className="grid grid-cols-2 w-full">
          <TabsTrigger
            value="personal"
            onClick={() => navigate("/projects/dashboard")}
          >
            Personal
          </TabsTrigger>
          <TabsTrigger
            value="admin"
            onClick={() => navigate("/projects/admin-dashboard")}
          >
            Admin
          </TabsTrigger>
        </TabsList>
      </Tabs>
      <FilterBar
        filters={[
          {
            key: "date",
            type: "date-range",
            label: "Date",
            value: filters.dates,
            onChange: (value) =>
              setFilters({
                dates: value,
              }),
          },
        ]}
      />

      <div className="grid grid-cols-2 gap-4 mt-3 md:grid-cols-4 sm:grid-cols-4">
        <Card>
          <p className="text-sm font-medium text-gray-500">Total Projects</p>
          <p className="text-xl font-semibold sm:text-xl">
            {data?.projects?.total}
          </p>
        </Card>
        <Card>
          <p className="text-sm font-medium text-gray-500">Open Projects</p>
          <p className="text-xl font-semibold sm:text-xl">
            {data?.projects?.pending}
          </p>
        </Card>
        <Card>
          <p className="text-sm font-medium text-gray-500">
            Completed Projects
          </p>
          <p className="text-xl font-semibold sm:text-xl">
            {data?.projects?.completed}
          </p>
        </Card>
        <Card>
          <p className="text-sm font-medium text-gray-500">Total Pipelines</p>
          <p className="text-xl font-semibold sm:text-xl">
            {data?.projects?.totalPipelines}
          </p>
        </Card>
      </div>

      <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2">
        <Card className="flex flex-col justify-center items-center">
          <p className="self-start mb-4 text-sm font-medium text-gray-500">
            Total Tasks by status
          </p>
          {data ? (
            <DonutChart
              data={data?.tasks}
              category="value"
              index="name"
              colors={["blue", "red", "green"]}
            />
          ) : (
            <FullScreenSpinner />
          )}
          <Legend
            categories={["Open", "In Progress", "Completed"]}
            colors={["blue", "red", "green"]}
            className="mt-4"
          />
        </Card>

        <Card className="flex flex-col justify-center items-center">
          <p className="self-start mb-4 text-sm font-medium text-gray-500">
            Total Activity by status
          </p>
          {data ? (
            <DonutChart
              data={data?.activities}
              category="value"
              index="name"
              colors={["blue", "green"]}
            />
          ) : (
            <FullScreenSpinner />
          )}
          <Legend
            categories={["Pending", "Completed"]}
            colors={["blue", "green"]}
            className="mt-4"
          />
        </Card>
      </div>

      <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2">
        <Card>
          <p className="self-start mb-4 text-sm font-medium text-gray-500">
            Tasks by user
          </p>
          <BarChart
            data={data?.tasksByUser || []}
            index="name"
            categories={["completed", "pending"]}
            colors={["green", "red"]}
            className="w-full"
          />
        </Card>
        <Card>
          <p className="self-start mb-4 text-sm font-medium text-gray-500">
            Activities by user
          </p>
          <BarChart
            data={data?.activitiesByUser || []}
            index="name"
            categories={["completed", "pending"]}
            colors={["green", "red"]}
            className="w-full"
          />
        </Card>
      </div>
      <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2">
        <Card>
          <p className="self-start mb-4 text-sm font-medium text-gray-500">
            Projects by pipeline
          </p>
          <BarChart
            data={data?.projectsByPipeline || []}
            index="name"
            categories={["value"]}
            colors={["green"]}
            className="w-full"
          />
        </Card>

        <Card>
          <p className="self-start mb-4 text-sm font-medium text-gray-500">
            Tasks and activities by stage
          </p>
          <Select
            options={pipelinesList?.map((pipeline) => ({
              label: pipeline.name,
              value: pipeline.id,
            }))}
            value={filters.pipelineId}
            onChange={(value) =>
              setFilters({
                pipelineId: value,
              })
            }
          />
          {/* {data ? (
            <BarChart
              data={data?.tasksByPipelineStage}
              index="name"
              categories={["value"]}
              colors={["green"]}
              className="w-full"
            />
          ) : (
            <FullScreenSpinner />
          )} */}
        </Card>
      </div>
    </Page>
  );
};

export default AdminProjectDashboard;
