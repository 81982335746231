import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filters";
import { SearchInput, VendorInput } from "@/components/FormComponents";
import Page from "@/components/page";
import heffl from "@/helpers/heffl";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { AddChartOfAccountsModal } from "@/pages/books/chart-of-accounts";
import Schemas from "@heffl/server/src/schemas";
import enums from "@heffl/server/src/schemas/enums";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FormList from "@heffl/ui/components/primitives/form-list";
import { Input } from "@heffl/ui/components/primitives/input";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import { cn, formatCurrency } from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";

import dayjs from "dayjs";
import { capitalize } from "lodash";
import { DollarSign, Save, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";
import PaymentMadeDetailsModal from "./details-modal";

const PaymentMadeForm = ({
  edit = false,
  form,
  paymentMadeAllocationsDetails,
}: {
  edit?: boolean;
  form: UseFormReturn<z.infer<typeof Schemas.purchases.paymentsMade>>;
  paymentMadeAllocationsDetails: {
    type: z.infer<typeof enums.paymentMadeAllocationType>;
    billId?: number | null;
    amountTotal: number;
    amountDue: number;
    title: string;
  }[];
}) => {
  const vendorId = form.watch("vendorId");

  const [showAddChartOfAccount, setShowAddChartOfAccount] = useState(false);

  const { data: paidThroughAccounts } =
    trpc.books.chartOfAccounts.list.useQuery({ type: "PAID_THROUGH" });

  return (
    <>
      {" "}
      <AddChartOfAccountsModal
        open={showAddChartOfAccount}
        onClose={() => setShowAddChartOfAccount(false)}
      />
      <VendorInput name="vendorId" label="Vendor" disabled={edit} />
      <div
        className={cn(
          "space-y-2",
          !vendorId ? "opacity-50 pointer-events-none" : ""
        )}
      >
        <div className="grid grid-cols-2 gap-3">
          <FormField name="date" label="Date">
            <DatePicker />
          </FormField>
          <FormField name="amount" label="Amount" className="mb-4">
            <Input
              type="number"
              placeholder="00.00"
              prefix="AED"
              prefixFilled
            />
          </FormField>
        </div>
        <div className="grid grid-cols-2 gap-3">
          <FormField name="paidThroughAccountId" label="Paid Through">
            <Select
              placeholder="Select paid through account"
              options={paidThroughAccounts?.chartOfAccounts.map((account) => ({
                label: account.name,
                value: account.id,
                type: account.type,
              }))}
              render={(option) => (
                <>
                  <span className="text-gray-500">
                    {capitalize(option.type)}
                  </span>
                  : {option.label}
                </>
              )}
              createButton={{
                onClick: () => setShowAddChartOfAccount(true),
                label: "Add Account",
              }}
            />
          </FormField>
          <FormField name="refNo" label="Reference No">
            <Input placeholder="Enter reference number" />
          </FormField>
        </div>
        <FormList name="paymentMadeAllocations">
          {({ _name }) => (
            <div className="space-y-2">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-2 font-semibold text-left">Bill</th>
                    <th className="p-2 font-semibold text-right">Amount</th>
                    <th className="p-2 font-semibold text-right">Pending</th>
                    <th className="p-2 font-semibold text-right">Payment</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentMadeAllocationsDetails?.map((pa, index) => {
                    return (
                      <tr key={index} className="border-b hover:bg-gray-50">
                        <td className="p-2">{pa.title}</td>
                        <td className="p-2 text-right">
                          {formatCurrency(pa.amountTotal, "AED")}
                        </td>
                        <td className="p-2 text-right">
                          {formatCurrency(pa.amountDue, "AED")}
                        </td>
                        <td className="flex justify-end p-2 text-right">
                          <FormField
                            name={_name(index, "amount")}
                            className="w-20"
                          >
                            <Input placeholder="Amount" />
                          </FormField>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </FormList>
        <FormField name="notes" label="Notes" className="mb-4">
          <Textarea placeholder="Notes" />
        </FormField>
      </div>
    </>
  );
};

export const AddPaymentMadeModal = ({
  open,
  onClose,
  billId,
  defaultValues,
}: {
  open: boolean;
  onClose: () => void;
  billId?: number;
  defaultValues?: Partial<z.infer<typeof Schemas.purchases.paymentsMade>>;
}) => {
  const form = useForm<z.infer<typeof Schemas.purchases.paymentsMade>>({
    resolver: zodResolver(Schemas.purchases.paymentsMade),
    defaultValues: {
      date: new Date(),
    },
  });

  const vendorId = form.watch("vendorId");

  const { data: pendingPayments } =
    trpc.purchases.vendors.pendingPaymentsList.useQuery(
      {
        vendorId,
      },
      { enabled: !!vendorId }
    );

  const onModalClose = () => {
    onClose();
    form.reset();
  };

  const paymentAddMutation = trpc.purchases.paymentsMade.add.useMutation({
    onSuccess() {
      heffl.toast.success("Payment added successfully");
      onModalClose();
    },
    onError(error) {
      heffl.toast.error(error.message);
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.purchases.paymentsMade>) => {
    paymentAddMutation.mutate(values);
  };

  useEffect(() => {
    if (billId) {
      const bill = pendingPayments?.find((pa) => pa.billId === billId);
      if (bill) {
        form.setValue("amount", bill.amountDue < 0 ? 0 : bill.amountDue);
      }
    }
  }, [billId, pendingPayments]);

  useEffect(() => {
    if (defaultValues) {
      form.reset({ ...form.getValues(), ...defaultValues });
    }
  }, [defaultValues]);

  useEffect(() => {
    if (pendingPayments) {
      form.setValue(
        "paymentMadeAllocations",
        pendingPayments.map((pa) => ({
          type: pa.type,
          amount: billId === pa.billId ? pa.amountDue : 0,
          billId: pa.billId,
        }))
      );
    }
  }, [pendingPayments]);

  return (
    <ModalDrawer
      modalClassName="min-w-[600px]"
      open={open}
      onClose={onModalClose}
      title="Add Payment"
      footer={
        <div className="flex w-full">
          <Button
            size="md"
            variant="primary"
            className="w-full"
            onClick={form.handleSubmit(onSubmit)}
          >
            Add Payment
          </Button>
        </div>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <PaymentMadeForm
          form={form}
          paymentMadeAllocationsDetails={pendingPayments || []}
        />
      </Form>
    </ModalDrawer>
  );
};

export const EditPaymentMadeModal = ({
  open,
  onClose,
  paymentId,
}: {
  open: boolean;
  onClose: () => void;
  paymentId: number;
}) => {
  const form = useForm<z.infer<typeof Schemas.purchases.paymentsMade>>({
    resolver: zodResolver(Schemas.purchases.paymentsMade),
  });

  const { data: paymentDetails } =
    trpc.purchases.paymentsMade.details.useQuery(paymentId);

  const onModalClose = () => {
    onClose();
    form.reset();
  };

  const paymentUpdateMutation = trpc.purchases.paymentsMade.update.useMutation({
    onSuccess() {
      heffl.toast.success("Payment updated successfully");
      onModalClose();
    },
    onError(error) {
      heffl.toast.error(error.message);
    },
  });

  const paymentDeleteMutation = trpc.purchases.paymentsMade.delete.useMutation({
    onSuccess() {
      heffl.toast.success("Payment deleted successfully");
      onModalClose();
    },
    onError(error) {
      heffl.toast.error(error.message);
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.purchases.paymentsMade>) => {
    paymentUpdateMutation.mutate({
      id: paymentId,
      paymentMade: values,
    });
  };

  useEffect(() => {
    if (paymentDetails) {
      form.reset({
        ...paymentDetails,
        paymentMadeAllocations: paymentDetails.allocationsWithPending,
      });
    }
  }, [paymentDetails]);

  return (
    <ModalDrawer
      open={open}
      onClose={onModalClose}
      title="Edit Payment"
      modalClassName="min-w-[600px]"
      footer={
        <div className="flex gap-3 w-full">
          <Button
            size="md"
            variant="destructive"
            className="w-1/5"
            onClick={() =>
              heffl.modal.confirm({
                title: "Delete Payment",
                description:
                  "Are you sure you want to delete this payment? This action cannot be undone.",
                onConfirm: () => {
                  paymentDeleteMutation.mutate(paymentId);
                },
              })
            }
            loading={paymentDeleteMutation.isLoading}
            icon={Trash}
          >
            Delete
          </Button>
          <Button
            size="md"
            variant="primary"
            className="w-4/5"
            onClick={form.handleSubmit(onSubmit)}
            icon={Save}
            loading={paymentUpdateMutation.isLoading}
          >
            Update Payment
          </Button>
        </div>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <PaymentMadeForm
          form={form}
          paymentMadeAllocationsDetails={
            paymentDetails?.allocationsWithPending || []
          }
          edit
        />
      </Form>
    </ModalDrawer>
  );
};

type PageFilters = {
  search: string;
  pageNo: number;
  dates?: [Date, Date];
  vendors: number[];
  paidThroughAccounts: number[];
  pageSize: number;
};

const PaymentsMadeList = () => {
  const [filters, setFilters] = useParamsState<PageFilters>({
    search: "",
    pageNo: 1,
    dates: undefined,
    vendors: [],
    paidThroughAccounts: [],
    pageSize: 30,
  });

  const [editPaymentId, setEditPaymentId] = useState<number | null>(null);
  const [showPaymentDetails, setShowPaymentDetails] = useState<number | null>(
    null
  );
  const [showAdd, setShowAdd] = useState(false);

  const [vendorSearch, setVendorSearch] = useState("");

  const { data: vendors } = trpc.purchases.vendors.list.useQuery({
    search: vendorSearch,
  });
  const { data: paidThroughAccounts } =
    trpc.books.chartOfAccounts.list.useQuery({
      type: "PAID_THROUGH",
    });

  const { data: paymentsMade } = trpc.purchases.paymentsMade.list.useQuery({
    ...filters,
  });

  return (
    <Page title="Payments Made" className="sm:p-0" fullWidth>
      <AddPaymentMadeModal open={showAdd} onClose={() => setShowAdd(false)} />
      {showPaymentDetails && (
        <PaymentMadeDetailsModal
          open={!!showPaymentDetails}
          onClose={() => setShowPaymentDetails(null)}
          id={showPaymentDetails}
        />
      )}
      {editPaymentId && (
        <EditPaymentMadeModal
          open={!!editPaymentId}
          onClose={() => setEditPaymentId(null)}
          paymentId={editPaymentId}
        />
      )}
      <div className="flex flex-col gap-3 justify-between w-full border-gray-200 sm:border-b sm:p-3 sm:flex-row sm:gap-0">
        <SearchInput
          value={filters.search}
          onChange={(e: string) => setFilters({ search: e })}
        />

        <ResponsiveActionButton
          onClick={() => setShowAdd(true)}
          text="Payment"
        />
      </div>
      <div className="sm:px-3">
        <FilterBar
          suffix={
            <div className="p-1 px-2 text-sm rounded-lg border shadow-sm">
              Total:{" "}
              <span className="font-medium">
                {heffl.format.currency(paymentsMade?.meta.total || 0, "AED")}
              </span>
            </div>
          }
          onChange={() => {
            setFilters({
              pageNo: 1,
            });
          }}
          className="py-3"
          filters={[
            {
              key: "date",
              type: "date-range",
              label: "Date",
              value: filters.dates,
              onChange: (value) => setFilters({ dates: value }),
            },
            {
              key: "vendors",
              type: "checkbox",
              onSearch: (search) => setVendorSearch(search),
              label: "Vendor",
              value: filters.vendors,
              onChange: (value) => setFilters({ vendors: value as number[] }),
              options: vendors?.vendors.map((vendor) => ({
                label: vendor.name,
                value: vendor.id,
              })),
            },
            {
              key: "paidThroughAccounts",
              type: "checkbox",
              label: "Paid Through",
              multiple: true,
              options:
                paidThroughAccounts?.chartOfAccounts.map((account) => ({
                  label: account.name,
                  value: account.id,
                })) || [],
              value: filters.paidThroughAccounts,
              onChange: (value) =>
                setFilters({ paidThroughAccounts: value as number[] }),
            },
          ]}
        />
      </div>
      <DataGrid
        rowKey="id"
        name="paymentMadeListMain"
        className="h-[calc(100vh-117px-var(--header-height))]"
        label="Payments Made"
        loading={false}
        rows={paymentsMade?.paymentsMade || []}
        onCellClick={({ row }) => {
          setShowPaymentDetails(row.id);
        }}
        empty={{
          icon: DollarSign,
          title: "No payments made",
          description: "Please add a payment",
          actionText: "Add payment",
          onAction: () => setShowAdd(true),
          buttonSize: "sm",
        }}
        pagination={{
          pageNo: filters.pageNo,
          pageSize: filters.pageSize,
          count: paymentsMade?.meta.count || 0,
          setPageNo: (pageNo) => setFilters({ pageNo }),
          setPageSize: (pageSize) => setFilters({ pageSize }),
        }}
        columns={[
          {
            key: "date",
            name: "Date",
            width: 140,
            renderCell: ({ row }) => dayjs(row.date).format("DD/MM/YYYY"),
          },
          {
            key: "number",
            name: "Number",
            width: 150,
            cellClass:
              "hover:underline hover:cursor-pointer hover:text-primary",
          },
          {
            key: "amount",
            name: "Amount",
            width: 200,
            renderCell: ({ row }) =>
              heffl.format.currency(row.amount, "AED", true),
          },
          {
            key: "vendor",
            name: "Vendor",
            width: 200,
            renderCell: ({ row }) => (
              <Link
                to={`/purchases/vendors/details/${row.vendors?.id}`}
                className="hover:underline hover:text-primary"
              >
                {row.vendors?.name}
              </Link>
            ),
          },
          {
            key: "paidThroughAccount",
            name: "Paid Through",
            width: 200,
            renderCell: ({ row }) => row.paidThroughAccount?.name,
          },
          {
            key: "bills",
            name: "Bills",
            width: 200,
            renderCell: ({ row }) =>
              row.paymentMadeAllocations
                .filter((allocation) => allocation.type === "BILL")
                .map((allocation) => `#${allocation.bills?.number}`)
                .filter(Boolean)
                .join(", "),
          },
          {
            key: "notes",
            name: "Notes",
            width: 200,
          },
          {
            key: "refNo",
            name: "Reference no",
            width: 150,
          },
          {
            key: "createdAt",
            name: "Created at",
            width: 140,
            renderCell: ({ row }) => dayjs(row.createdAt).format("DD/MM/YYYY"),
          },
        ]}
      />
    </Page>
  );
};

export default PaymentsMadeList;
