import { cn } from "@heffl/ui/lib/utils";
import { Link, LucideIcon } from "lucide-react";
import { ReactNode } from "react";

export type InfoItemHZProps = {
  items: {
    label: string;
    value: string | ReactNode;
    hidden?: boolean;
    onClick?: () => void;
    icon?: LucideIcon;
    valueClassName?: string;
  }[];
  className?: string;
  labelClassName?: React.ComponentProps<"dt">["className"];
};

const InfoItemsHZ = ({ items, className, labelClassName }: InfoItemHZProps) => {
  return (
    <div className={cn("flow-root", className)}>
      <dl className="">
        {items
          .filter((item) => !item.hidden)
          .map((item) => (
            <div
              className="grid grid-cols-1 items-center py-2 sm:grid-cols-[1fr_2fr]"
              key={item.label}
            >
              <dt
                className={cn(
                  "flex items-center text-[#5c5e63] text-[12px]",
                  labelClassName
                )}
              >
                {item.icon && (
                  <item.icon className="mr-1 w-3.5 h-3.5 text-gray-600" />
                )}
                {item.label}
              </dt>
              <dd
                className={cn(
                  "flex items-center text-[14px] w-full",
                  item.onClick && "hover:text-primary cursor-pointer",
                  item.valueClassName
                )}
                onClick={item.onClick}
              >
                {item.value}
                {!!item.onClick && <Link className="h-3.5 text-primary" />}
              </dd>
            </div>
          ))}
      </dl>
    </div>
  );
};

export default InfoItemsHZ;
