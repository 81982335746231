import { cn } from "../lib/utils";

type StatsCardProps = {
  items: {
    title: string;
    value: string;
  }[];
  className?: string;
};

const StatsCard = ({ items, className }: StatsCardProps) => {
  return (
    <div className={cn("flex gap-4 px-4 w-full rounded-lg border", className)}>
      {items.map((item, index) => (
        <>
          <div key={item.title} className="p-2 bg-white rounded-lg">
            <p className="text-sm underline decoration-dashed decoration-gray-300 underline-offset-4">
              {item.title}
            </p>
            <p className="mt-1 text-sm font-medium">{item.value}</p>
          </div>
          {index < items.length - 1 && (
            <div className="my-4 w-px bg-gray-200" />
          )}
        </>
      ))}
    </div>
  );
};

export default StatsCard;
