import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filters";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import enums from "@heffl/server/src/schemas/enums";
import TagsInput from "@heffl/ui/components/TagInput";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Card } from "@heffl/ui/components/primitives/card";
import {
  cn,
  formatCurrency,
  formatName,
  formatValue,
  isMobile,
} from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import {
  AlignHorizontalDistributeCenter,
  BadgeDollarSignIcon,
  Briefcase,
  Building2,
  CalendarDays,
  CircleDollarSign,
  CircleDollarSignIcon,
  Clock,
  Hammer,
  MapPinIcon,
  Tags,
  Timer,
  UserCircle2,
  XCircle,
} from "lucide-react";
import { useState } from "react";
import { z } from "zod";
import { ScheduleStatusBadge, getDateDuration } from "../jobs/details";
import { ScheduleInvoiceBadge } from "../jobs/list";
import ScheduleDetailsModal, {
  scheduleStatusOptions,
} from "./components/schduleDetailsModal";
import { calculateScheduleRevenue } from "@heffl/server/src/helpers/lineItems/calculateScheduleRevenue";

type PageFilters = {
  salesPerson: number[];
  statuses: z.infer<typeof enums.scheduleStatus>[];
  dates: [Date, Date] | undefined;
  search: string;
  pageNo: number;
  clients: number[];
  services: number[];
  tags: number[];
  assignees: number[];
  jobTags: number[];
  drivers: number[];
  supervisors: number[];
};

const pageSize = 50;

const SchedulesList = ({
  syncedDates,
  setSyncedDates,
}: {
  syncedDates: [Date, Date] | undefined;
  setSyncedDates: (dates: [Date, Date]) => void;
}) => {
  const [schduleDetailsId, setScheduleDetailsId] = useState<number | null>(
    null
  );
  const [clientSearch, setClientSearch] = useState("");
  const [serviceSearch, setServiceSearch] = useState("");

  const [filters, setFilters] = useParamsState<PageFilters>({
    statuses: [],
    salesPerson: [],
    dates: syncedDates || [dayjs().toDate(), dayjs().toDate()],
    search: "",
    pageNo: 1,
    clients: [],
    services: [],
    tags: [],
    assignees: [],
    jobTags: [],
    drivers: [],
    supervisors: [],
  });

  const { data: schedules, isLoading } =
    trpc.fieldService.schedules.list.useQuery({
      status: filters.statuses,
      startDate: filters.dates?.[0],
      endDate: filters.dates?.[1],
      pageNo: filters.pageNo,
      pageSize,
      services: filters.services,
      clients: filters.clients,
      tags: filters.tags,
      assignees: filters.assignees,
      jobTags: filters.jobTags,
      salesPersons: filters.salesPerson,
      drivers: filters.drivers,
      supervisors: filters.supervisors,
      orderBy: {
        startDate: "asc",
      },
    });
  const { data: assignees } = trpc.users.list.useQuery({
    type: ["FIELD_STAFF"],
  });
  const { data: drivers } = trpc.users.list.useQuery({
    type: ["DRIVER"],
  });

  const { data: supervisors } = trpc.users.list.useQuery({
    type: ["SUPERVISOR"],
  });
  const { data: tags } = trpc.tags.list.useQuery({
    type: "SCHEDULE",
  });

  const { data: services } = trpc.products.list.useQuery({
    search: serviceSearch,
    pageSize: 8,
  });

  const { data: jobTags } = trpc.tags.list.useQuery({
    type: "JOB",
  });
  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageNo: 1,
    pageSize: 10,
  });
  const { data: users } = trpc.users.list.useQuery({
    excludeType: ["FIELD_STAFF"],
  });

  return (
    <div className="px-2">
      {schduleDetailsId && (
        <ScheduleDetailsModal
          open={!!schduleDetailsId}
          onClose={() => setScheduleDetailsId(null)}
          id={schduleDetailsId}
        />
      )}
      <div className="flex flex-wrap gap-2 mt-3 mr-72">
        <FilterBar
          onChange={() => {
            setFilters({
              pageNo: 1,
            });
          }}
          filters={[
            {
              key: "dates",
              type: "date-range",
              label: "Date",
              value: filters.dates,
              onChange: (value) => {
                if (value) setSyncedDates(value);
                setFilters({
                  dates: value,
                });
              },
            },
            {
              key: "clients",
              type: "checkbox",
              label: "Clients",
              showSearch: true,
              value: filters.clients,
              onChange: (value) => setFilters({ clients: value as number[] }),
              options: clients?.clients.map((client) => ({
                label: client.name,
                value: client.id,
              })),
              onSearch: (e) => setClientSearch(e),
            },
            {
              key: "statuses",
              type: "checkbox",
              label: "Status",
              value: filters.statuses,
              onChange: (value) =>
                setFilters({
                  statuses: value as z.infer<typeof enums.scheduleStatus>[],
                }),
              options: scheduleStatusOptions.map((s) => ({
                label: s.label,
                value: s.value,
              })),
            },

            {
              key: "assignees",
              type: "checkbox",
              label: "Assignees",
              value: filters.assignees,
              onChange: (value) => setFilters({ assignees: value as number[] }),
              options: [
                {
                  label: "Unassigned",
                  value: 0,
                },
                ...(assignees?.map((user) => ({
                  label: formatName(user),
                  value: user.id,
                })) ?? []),
              ],
            },
            {
              key: "services",
              type: "checkbox",
              label: "Services",
              showSearch: true,
              onSearch: (e) => setServiceSearch(e),
              value: filters.services,
              onChange: (value) => setFilters({ services: value as number[] }),
              options: services?.products.map((service) => ({
                label: service.name,
                value: service.id,
              })),
            },
            {
              key: "salesPerson",
              type: "checkbox",
              label: "Sales Person",
              value: filters.salesPerson,
              onChange: (value) =>
                setFilters({ salesPerson: value as number[] }),
              options: users?.map((user) => ({
                label: formatName(user),
                value: user.id,
              })),
            },
            {
              key: "tags",
              label: "Tags",
              type: "checkbox",
              value: filters.tags,
              options:
                tags?.map((tag) => ({
                  label: tag.name,
                  value: tag.id,
                })) ?? [],
              onChange: (e) => {
                setFilters({ tags: e as number[] });
              },
            },
            {
              key: "supervisors",
              label: "Supervisors",
              type: "checkbox",
              value: filters.supervisors,
              options: supervisors?.map((supervisor) => ({
                label: formatName(supervisor),
                value: supervisor.id,
              })),
              onChange: (e) => {
                setFilters({ supervisors: e as number[] });
              },
            },
            {
              key: "drivers",
              label: "Drivers",
              type: "checkbox",
              value: filters.drivers,
              options: drivers?.map((driver) => ({
                label: formatName(driver),
                value: driver.id,
              })),
              onChange: (e) => {
                setFilters({ drivers: e as number[] });
              },
            },
            {
              key: "jobTags",
              label: "Job Tags",
              type: "checkbox",
              value: filters.jobTags,
              onChange: (e) => {
                setFilters({ jobTags: e as number[] });
              },
              options:
                jobTags?.map((tag) => ({
                  label: tag.name,
                  value: tag.id,
                })) ?? [],
            },
          ]}
        />
        <Card parentClassName="p-1">
          <div className="flex gap-2 justify-between">
            <div className="text-gray-500 flex items-center gap-0.5">
              <CircleDollarSignIcon className="w-4 h-4" />
              Total:{" "}
            </div>
            <div className="font-medium text-gray-700">
              {formatCurrency(
                schedules?.aggregate.totalScheduleRevenue || 0,
                "AED"
              )}
            </div>
          </div>
        </Card>
        <Card parentClassName="p-1">
          <div className="flex gap-2 justify-between">
            <div className="text-gray-500 flex items-center gap-0.5">
              <Briefcase className="w-4 h-4" />
              Vendor Charge:{" "}
            </div>
            <div className="font-medium text-gray-700">
              {formatCurrency(
                schedules?.aggregate.totalVendorCharge || 0,
                "AED"
              )}
            </div>
          </div>
        </Card>
      </div>
      {!isMobile() && (
        <DataGrid
          rowKey="id"
          loading={isLoading}
          className="h-[calc(100vh-111px-var(--header-height))] -mx-2 mt-4"
          name="schedulesListMain"
          label="Schedules"
          rows={(schedules && schedules.schedules) || []}
          pagination={{
            pageNo: filters.pageNo,
            count: schedules?.count,
            pageSize,
            setPageNo: (pageNo) => setFilters({ pageNo }),
          }}
          empty={{
            icon: Clock,
            title: "No schedules",
            description: "Add a schedule to get started",
          }}
          columns={[
            {
              key: "id",
              name: "#",
              width: 80,
              renderCell: ({ row }) => `#${row.id}`,
            },
            {
              key: "date",
              icon: CalendarDays,
              name: "Schedule",
              width: 260,
              renderCell: ({ row }) => {
                const isSameDay = dayjs(row.startDate).isSame(
                  row.endDate,
                  "day"
                );
                return (
                  <div className="flex gap-2 items-center cursor-pointer hover:text-primary-900">
                    {row.timeDisabled ? (
                      <span>
                        {" "}
                        {dayjs(row.startDate).format("DD MMM")} -{" "}
                        <span className="text-red-500">No time added</span>
                      </span>
                    ) : (
                      <>
                        {dayjs(row.startDate).format(
                          `DD MMM${isSameDay ? "" : ", hh:mm A"}`
                        )}
                        {isSameDay
                          ? `${dayjs(row.startDate).format(
                              ", hh:mm A"
                            )} - ${dayjs(row.endDate).format(" hh:mm A")}`
                          : dayjs(row.endDate).format(" - DD MMM, hh:mm A")}
                      </>
                    )}
                  </div>
                );
              },
            },
            {
              icon: AlignHorizontalDistributeCenter,
              key: "status",
              name: "Status",
              width: 120,
              renderCell: ({ row }) => (
                <ScheduleStatusBadge schedule={{ status: row.status }} />
              ),
            },
            {
              key: "services",
              icon: Hammer,
              name: "Services",
              width: 200,
              renderCell: ({ row }) => (
                <p className="truncate">
                  {row.fsJobs.fsJobServices
                    .map((service) => service.name)
                    .join(", ")}
                </p>
              ),
            },
            {
              key: "duration",
              icon: Timer,
              name: "Duration",
              width: 100,
              renderCell: ({ row }) =>
                getDateDuration(row.startDate, row.endDate),
            },
            {
              key: "invoice",
              name: "Invoice",
              icon: CircleDollarSign,
              width: 100,
              renderCell: ({ row }) => <ScheduleInvoiceBadge schedule={row} />,
            },
            {
              key: "client",
              icon: Building2,
              name: "Client",
              width: 120,
              renderCell: ({ row }) => (
                <p className="truncate">
                  {row.fsJobs.fsProperties.clients.name}
                </p>
              ),
            },
            {
              key: "property",
              name: "Property",
              icon: MapPinIcon,
              width: 120,
              renderCell: ({ row }) => (
                <p className="truncate">
                  {formatValue.propertyName(row.fsJobs.fsProperties)}
                </p>
              ),
            },
            {
              key: "assignees",
              name: "Assignees",
              icon: UserCircle2,
              width: 120,
              renderCell: ({ row }) => (
                <p className="truncate">
                  {row.fsScheduleAssignees.length ? (
                    row.fsScheduleAssignees
                      .map((assignee) => formatName(assignee.users))
                      .join(", ")
                  ) : (
                    <span className="text-red-500">Unassigned</span>
                  )}
                </p>
              ),
            },
            {
              key: "scheduleRevenue",
              name: "Revenue",
              icon: BadgeDollarSignIcon,
              width: 100,
              renderCell: ({ row }) => {
                return formatCurrency(
                  calculateScheduleRevenue(row, {
                    startDate: filters.dates?.[0] || new Date(),
                    endDate: filters.dates?.[1] || new Date(),
                  }),
                  "AED",
                  true
                );
              },
            },
            {
              key: "tags",
              name: "Tags",
              icon: Tags,
              width: 200,
              renderCell: ({ row }) => (
                <TagsInput
                  value={row.fsScheduleTags.map((tag) => tag.tagId)}
                  tags={tags || []}
                />
              ),
            },
            {
              key: "dateStartRaw",
              name: "Date",
              icon: CalendarDays,
              width: 200,
              renderCell: ({ row }) => {
                return dayjs(row.startDate).format("DD/MM/YYYY");
              },
            },
            {
              key: "timeStartRaw",
              name: "Time",
              icon: Clock,
              width: 200,
              renderCell: ({ row }) => {
                return dayjs(row.startDate).format("hh:mm A");
              },
            },
            {
              key: "cancelReason",
              name: "Cancel Reason",
              icon: XCircle,
              width: 200,
              renderCell: ({ row }) => {
                return row.cancelReason;
              },
            },
          ]}
          onCellClick={({ row }) => setScheduleDetailsId(row.id)}
        />
      )}
      {isMobile() && (
        <div className="flex flex-col gap-2 mt-3">
          {schedules?.schedules.map((schedule) => (
            <div
              key={schedule.id}
              className="px-3 py-2 bg-white rounded-md border border-gray-200 shadow-sm cursor-pointer"
              onClick={() => setScheduleDetailsId(schedule.id)}
            >
              <div className="flex justify-between items-baseline">
                <p className="text-sm font-medium hover:text-primary-600">
                  {schedule.fsJobs.fsJobServices.length &&
                    schedule.fsJobs.fsJobServices[0].name}
                  {schedule.fsJobs.fsJobServices.length > 1 &&
                    ` + ${schedule.fsJobs.fsJobServices.length - 1} more`}
                </p>
                <ScheduleStatusBadge
                  schedule={{ status: schedule.status }}
                  small
                />
              </div>
              <div className="flex gap-1 mt-0.5 justify-between items-baseline">
                <p className={cn("text-xs text-gray-600")}>
                  {dayjs(schedule.startDate).format("MMM D, YYYY")} -{" "}
                  <span
                    className={cn(
                      "text-gray-500",
                      schedule.timeDisabled && "text-red-500"
                    )}
                  >
                    {schedule.timeDisabled
                      ? "No time added"
                      : getDateDuration(schedule.startDate, schedule.endDate)}
                  </span>
                </p>
              </div>
              <div className="flex flex-col mt-3">
                <div className="flex gap-2 items-center py-1 rounded-md hover:bg-gray-100">
                  <Building2 className="w-3.5 h-3.5 text-gray-500" />
                  <p className="text-xs truncate">
                    {formatValue.propertyName(schedule.fsJobs.fsProperties)}
                  </p>
                </div>
                <div className="flex gap-2 items-center py-1 rounded-md hover:bg-gray-100">
                  <UserCircle2 className="w-3.5 h-3.5 text-gray-500" />
                  <p className="text-xs truncate">
                    {schedule.fsScheduleAssignees.length ? (
                      schedule.fsScheduleAssignees
                        .map((assignee) => formatName(assignee.users))
                        .join(", ")
                    ) : (
                      <span className="text-red-500">Unassigned</span>
                    )}
                  </p>
                </div>
                <div className="flex gap-2 items-center py-1 rounded-md hover:bg-gray-100">
                  <Tags className="w-3.5 h-3.5 text-gray-500" />
                  <TagsInput
                    small
                    value={schedule.fsScheduleTags.map((tag) => tag.tagId)}
                    tags={tags || []}
                  />
                  {schedule.invoiceRequired && (
                    <Badge variant="success" icon={CircleDollarSign}>
                      Required
                    </Badge>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SchedulesList;
