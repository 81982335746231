import DataTable from "@/components/DataTable";
import Page from "@/components/page";
import React from "react";
import { useNavigate } from "react-router-dom";

interface Report {
  title: string;
  description: string;
  slug: string;
}

const reports: Report[] = [
  {
    title: "Invoice Summary Report",
    description: "Overview of invoice status and key financial metrics.",
    slug: "invoice-summary-report",
  },
  {
    title: "Profit Loss Report",
    description: "Overview of profit and loss.",
    slug: "profit-loss-report",
  },
  {
    title: "Payments Received Report",
    description: "Detailed report on payments received.",
    slug: "payments-received-report",
  },
  {
    title: "Quotes Summary Report",
    description: "Overview of quote status and key financial metrics.",
    slug: "quotes-summary-report",
  },
  {
    title: "Vendor Summary Report",
    description: "Overview of vendor status and key financial metrics.",
    slug: "vendor-summary-report",
  },
  {
    title: "Customer Balances Report",
    description: "Overview of customer balances.",
    slug: "customer-balances-report",
  },
  {
    title: "Expenses Report",
    description: "Overview of expenses.",
    slug: "expense-report",
  },
  {
    title: "Typing Center Report",
    description: "Overview of typing center status and key financial metrics.",
    slug: "typing-center-report",
  },
];

const BooksReports: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Page title="Books Reports">
      <DataTable
        data={reports}
        columns={[
          {
            title: "Report Title",
            render: (report: Report) => (
              <span
                className="font-medium text-blue-400 cursor-pointer"
                onClick={() => {
                  navigate(`/sales/reports/${report.slug}`);
                }}
              >
                {report.title}
              </span>
            ),
          },
          {
            title: "Description",
            render: (report: Report) => (
              <span className="text-sm text-gray-500">
                {report.description}
              </span>
            ),
          },
          {
            title: "Created By",
            render: () => (
              <span className="text-sm text-gray-500">System generated</span>
            ),
          },
        ]}
        rowKey="slug"
      />
    </Page>
  );
};

export default BooksReports;
