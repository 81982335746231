import { trpc } from "@/helpers/trpc";
import { RouterOutputs } from "@/helpers/trpc";
import enums from "@heffl/server/src/schemas/enums";
import { z } from "zod";

type serverOutput = RouterOutputs["teams"]["details"];

type TeamDetails = {
  id: number;
  name: string;
  apps: {
    installed: (app: z.infer<typeof enums.appKeys>) => boolean;
  };
  user: {
    permissions: serverOutput["userPermissions"];
  };
  settings: serverOutput["teamSettings"];
};

const useTeam = (): TeamDetails | undefined => {
  const { data } = trpc.teams.details.useQuery();

  if (!data) return undefined;

  return {
    id: data.id,
    name: data.name,
    apps: {
      installed: (app) =>
        data?.installedApps?.some(
          (installedApp) => installedApp.appName === app
        ),
    },
    user: {
      permissions: data.userPermissions,
    },
    settings: data.teamSettings,
  };
};

export default useTeam;
