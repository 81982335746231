import DataGrid from "@/components/dataGrid/DataGrid";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { formatCurrency } from "@heffl/ui/lib/utils";
import { FileText } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const CustomerBalancesReport: React.FC = () => {
  const { data: customerBalances } =
    trpc.sales.reports.customerBalancesReport.useQuery();

  const navigate = useNavigate();

  return (
    <Page
      title="Customer Balances Report"
      fullWidth
      className="sm:!p-0 "
      showBack
    >
      <DataGrid
        rowKey="id"
        name="customerBalancesReport"
        className="h-[calc(100vh-50px-var(--header-height))] mt-12"
        label="Customer Balances"
        loading={!customerBalances}
        empty={{
          icon: FileText,
          title: "No customer balances",
          description:
            "There are no customer balances to display in the summary report.",
        }}
        rows={customerBalances?.clients || []}
        columns={[
          {
            key: "name",
            name: "Customer Name",
            width: 200,
            renderCell: ({ row }) => (
              <div
                className="truncate cursor-pointer hover:text-primary-600 hover:underline text-primary-800"
                onClick={() => navigate(`/crm/clients/details/${row.id}`)}
              >
                {row.name}
              </div>
            ),
          },
          {
            key: "invoiceBalance",
            name: "Invoice Balance",
            width: 150,
            renderCell: ({ row }) =>
              formatCurrency(row.invoiceBalance, "AED", true),
          },
          {
            key: "unUsedCredits",
            name: "Unused Credits",
            width: 150,
            renderCell: ({ row }) =>
              formatCurrency(row.unUsedCredits, "AED", true),
          },
          {
            key: "balance",
            name: "Balance",
            width: 150,
            renderCell: ({ row }) =>
              formatCurrency(row.totalBalance, "AED", true),
          },
        ]}
      />
    </Page>
  );
};

export default CustomerBalancesReport;
