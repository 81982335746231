import DropMenu from "@/components/DropMenu";
import Empty from "@/components/Empty";
import { UserAvatar } from "@/components/UserAvatar";
import { AddActivityModal } from "@/components/actionsModals/activities-modals";
import { AddLogModal } from "@/components/actionsModals/log-modals";
import { AddNotesModal } from "@/components/actionsModals/notes-modals";
import { AddTaskModal } from "@/components/actionsModals/tasks-modals";
import LineItemSelector, {
  defaultProduct,
} from "@/components/line-item-selector";
import Page, { PageHeight } from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { dealPriorityColors } from "@/lib/constants";
import Schemas from "@heffl/server/src/schemas";
import enums from "@heffl/server/src/schemas/enums";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import CopyToClipboard, {
  copyToClipboard,
} from "@heffl/ui/components/primitives/copy-to-clipboard";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import {
  ScrollArea,
  ScrollBar,
} from "@heffl/ui/components/primitives/scroll-area";
import {
  cn,
  dynamicDateFormatting,
  formatCurrency,
  formatName,
} from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@radix-ui/react-collapsible";

import {
  generateGoogleMapsLink,
  openGoogleMaps,
} from "@/pages/field-service/components/property-selector";
import {
  callMobile,
  messageOnWhatsApp,
} from "@/pages/field-service/staff-app/schedules/details";
import { renderCustomFieldValue } from "@heffl/server/src/helpers/customFields";
import { Icons } from "@heffl/ui/components/icons";
import Select from "@heffl/ui/components/primitives/creatable-select";
import NextTabs from "@heffl/ui/components/tabs";
import { isMobile } from "@heffl/ui/lib/utils";
import {
  Building,
  Calendar,
  CalendarCheck2,
  CheckCircle,
  ChevronDown,
  Contact2,
  Copy,
  DollarSign,
  Loader,
  LucideIcon,
  Mail,
  MessageCircle,
  MessageSquare,
  Navigation,
  Package,
  PenSquare,
  Pencil,
  Phone,
  Plus,
  StickyNote,
  User,
} from "lucide-react";
import { ReactNode, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { z } from "zod";
import QuotationCard from "../../../sales/quotations/QuotationCard";
import { EditClientDrawer } from "../../clients/list";
import { AddContactDrawer, EditContactDrawer } from "../../contacts/list";
import { AddCommentModal, SelectLostReasonModal } from "../../leads/details";
import EditDealDrawer from "../components/EditDealDrawer";
import DealFocus from "./focus";
import DealHistory from "./history";
import confetti from "canvas-confetti";

const doConfettiSideCannons = () => {
  const end = Date.now() + 3 * 1000; // 3 seconds
  const colors = ["#a786ff", "#fd8bbc", "#eca184", "#f8deb1"];

  const frame = () => {
    if (Date.now() > end) return;
    confetti({
      particleCount: 2,
      angle: 60,
      spread: 55,
      startVelocity: 60,
      origin: { x: 0, y: 0.5 },
      colors: colors,
    });
    confetti({
      particleCount: 2,
      angle: 120,
      spread: 55,
      startVelocity: 60,
      origin: { x: 1, y: 0.5 },
      colors: colors,
    });

    requestAnimationFrame(frame);
  };

  frame();
};

export const MobileRoundedButton = ({
  label,
  onClick,
  icon: Icon,
  disabled,
}: {
  label?: string;
  onClick?: () => void;
  icon: LucideIcon;
  disabled?: boolean;
}) => {
  if (isMobile()) {
    return (
      <div className="flex flex-col gap-1 items-center">
        <Button
          className="rounded-full"
          variant="outline"
          size="icon"
          onClick={onClick}
          disabled={disabled}
        >
          <Icon className="h-5" />
        </Button>
        <p className="text-xs text-gray-800">{label}</p>
      </div>
    );
  }
  return (
    <Button variant="outline" size="md" onClick={onClick} icon={Icon}>
      {label}
    </Button>
  );
};

export const ConditionalWrapper = ({
  children,
  condition,
  wrapper,
}: {
  children: ReactNode;
  condition: boolean;
  wrapper: (children: ReactNode) => ReactNode;
}) => {
  return condition ? wrapper(children) : children;
};

type StageProps = {
  selected: boolean;
  index: number;
  stageCount: number;
  text: string;
  loading: boolean;
  selectedIndex: number;
  className?: string;
  onClick: () => void;
};

export const Stage = ({
  index,
  text,
  selected,
  stageCount,
  onClick,
  loading = false,
  selectedIndex,
  className,
}: StageProps) => {
  const stage =
    index === 0 ? "FIRST" : index === stageCount - 1 ? "LAST" : "MIDDLE";
  return (
    <div
      className={cn(
        "h-9 flex-grow rounded-sm cursor-pointer relative transition-all duration-600",
        selected || index < selectedIndex
          ? "bg-primary hover:bg-green-600 text-white"
          : "bg-gray-200 hover:bg-gray-300 text-gray-600",
        stage === "FIRST" && "stage-first",
        stage === "MIDDLE" && "stage-middle ml-[-6px]",
        stage === "LAST" && "stage-last ml-[-6px]",
        "flex items-center justify-center text-xs font-semibold",
        className
      )}
      onClick={onClick}
    >
      <p className={`${loading && "invisible"} sm:block hidden`}>{text}</p>
      {loading && <Loader className="h-3.5 animate-twSpin absolute top-3" />}
    </div>
  );
};

export const InfoItem = ({
  label,
  value,
  labelClassName,
}: {
  label: string;
  value?: string | ReactNode;
  labelClassName?: string;
}) => {
  return (
    <div className="flex flex-col gap-1">
      <p className={cn("text-xs text-gray-500", labelClassName)}>{label}</p>
      {typeof value === "string" ? (
        <p className="text-sm font-medium text-gray-800">{value}</p>
      ) : (
        value
      )}
    </div>
  );
};

export const productsSchema = Schemas.crm.deal.pick({
  dealProducts: true,
});

type Contact = {
  id: number;
  firstName: string;
  lastName: string | null;
  jobTitle: string | null;
  mobile: string | null;
  email: string | null;
  clientId?: number | null;
};

export const ContactCard = ({
  contact,
  title,
  icon,
  className,
  showAdd,
  showCall = false,
}: {
  className?: string;
  contact: Contact;
  title?: string;
  icon?: ReactNode;
  showAdd?: boolean;
  showCall?: boolean;
}) => {
  const navigate = useNavigate();

  const [editContact, setEditContact] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  return (
    <>
      {editContact && (
        <EditContactDrawer
          id={contact.id}
          onClose={() => setEditContact(false)}
        />
      )}
      {showAddModal && (
        <AddContactDrawer
          onClose={() => setShowAddModal(false)}
          open={showAddModal}
          defaultValues={{
            clientId: contact.clientId,
          }}
        />
      )}
      <Card
        title={title}
        suffix={
          <div>
            {showAdd && (
              <Button
                onClick={() => setShowAddModal(true)}
                variant="primaryOutline"
                size="xs"
              >
                Add
              </Button>
            )}
          </div>
        }
        icon={icon}
        key={contact.id}
        className={cn("", className)}
        parentClassName="h-fit"
      >
        <div className="flex flex-row gap-1 justify-between w-full">
          <div className="flex gap-2 items-start">
            <UserAvatar user={contact} size="md" />
            <div className="flex flex-col gap-1">
              <p
                className="text-sm text-gray-800 cursor-pointer font-sembold hover:text-primary"
                onClick={() => {
                  navigate(`/crm/contacts/details/${contact.id}`);
                }}
              >
                {formatName(contact)}
              </p>
              <p className="text-sm text-gray-400">{contact.jobTitle || ""}</p>
              {contact.mobile && (
                <div className="flex flex-wrap gap-1">
                  <div className="flex gap-1 items-center px-2 py-1 bg-gray-100 rounded-xl">
                    <p className="text-xs">{contact.mobile}</p>
                    <CopyToClipboard copyText={contact.mobile} />
                  </div>
                </div>
              )}
              {contact.email && (
                <div className="flex flex-wrap gap-1">
                  <div className="flex gap-1 items-center px-2 py-1 bg-gray-100 rounded-xl">
                    <p className="text-xs">{contact.email}</p>
                    <CopyToClipboard copyText={contact.email} />
                  </div>
                </div>
              )}
            </div>
          </div>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setEditContact(true);
            }}
            variant="primaryOutline"
            size="xs"
          >
            Edit
          </Button>
        </div>
        {showCall && contact.mobile && (
          <div className="flex gap-2 mt-2 w-full">
            <Button
              disabled={!contact.mobile}
              onClick={() => {
                contact.mobile && callMobile(contact.mobile);
              }}
              icon={Phone}
              variant="primary"
              className="w-full"
            >
              Call now
            </Button>
            <Button
              disabled={!contact.mobile}
              onClick={() => {
                contact.mobile && messageOnWhatsApp(contact.mobile, "Call");
              }}
            >
              <Icons.whatsapp className="w-6 h-6" />
            </Button>
          </div>
        )}

        {showCall && !contact.mobile && (
          <Button
            icon={Phone}
            className="mt-2 w-full"
            variant="destructiveOutline"
          >
            No mobile added
          </Button>
        )}
      </Card>
    </>
  );
};

export const ClientCard = ({
  company,
}: {
  company: {
    id: number;
    name: string;
    description: string | null;
    website: string | null;
    clientAddresses?: {
      googleMapsLink?: string | null;
      googleMapsPlaceId?: string | null;
      address?: string | null;
    }[];
    contacts: {
      id: number;
      firstName: string;
      lastName: string | null;
      jobTitle: string | null;
      mobile: string | null;
      email: string | null;
    }[];
  };
}) => {
  const navigate = useNavigate();

  const [editCompany, setEditCompany] = useState(false);
  const [showContacts, setShowContacts] = useState(false);

  return (
    <>
      {editCompany && (
        <EditClientDrawer
          id={company.id}
          onClose={() => setEditCompany(false)}
        />
      )}

      <ModalDrawer open={showContacts} onClose={() => setShowContacts(false)}>
        <div className="flex flex-col gap-2">
          {company.contacts.map((contact) => (
            <ContactCard key={contact.id} contact={contact} showCall />
          ))}
        </div>
      </ModalDrawer>

      <div
        onClick={() => navigate(`/crm/clients/details/${company.id}`)}
        className="flex flex-row gap-3 justify-between p-4 w-full rounded-lg border border-gray-200 cursor-pointer"
      >
        <div className="flex gap-2 w-full">
          {!company.website ? (
            <Building className="object-cover p-2 w-11 h-11 bg-blue-50 rounded-full" />
          ) : (
            <img
              src={`https://www.google.com/s2/favicons?domain=${company.website}&sz=128`}
              className="object-cover w-11 h-11 rounded-sm"
            />
          )}
          <div className="flex flex-col gap-1 w-full">
            <p className="text-sm text-gray-800 font-sembold">{company.name}</p>
            {company.description && (
              <p className="text-sm text-gray-800 font-sembold">
                {company.description || ""}
              </p>
            )}

            {company.clientAddresses &&
              company.clientAddresses.length > 0 &&
              (company.clientAddresses[0].googleMapsLink ||
                company.clientAddresses[0].googleMapsPlaceId) && (
                <div className="flex gap-2">
                  <Button
                    onClick={() => {
                      const link = generateGoogleMapsLink(
                        company?.clientAddresses?.[0]
                      );
                      copyToClipboard(link);
                    }}
                    variant="primaryOutline"
                    size="xs"
                    icon={Copy}
                  >
                    Maps link
                  </Button>
                  <Button
                    onClick={() => openGoogleMaps(company.clientAddresses?.[0])}
                    variant="primaryOutline"
                    size="xs"
                    icon={Navigation}
                  >
                    Open map
                  </Button>
                </div>
              )}
            {company.contacts.length > 0 && (
              <Button
                icon={Phone}
                variant="primaryOutline"
                className="mt-2 w-full"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowContacts(true);
                }}
              >
                Call now
              </Button>
            )}
            {company.contacts.length === 0 && (
              <Button
                icon={Phone}
                variant="secondary"
                className="mt-2 w-full"
                size="sm"
              >
                No contacts added
              </Button>
            )}
          </div>
        </div>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setEditCompany(true);
          }}
          variant="primaryOutline"
          size="xs"
        >
          Edit
        </Button>
      </div>
    </>
  );
};

const EditProductsDrawer = ({
  onClose,
  dealId,
  defaultValues,
}: {
  onClose: () => void;
  dealId: number;
  defaultValues?: z.infer<typeof productsSchema>;
}) => {
  const productsForm = useForm<z.infer<typeof productsSchema>, unknown>({
    resolver: zodResolver(productsSchema),
    defaultValues: {
      dealProducts:
        defaultValues &&
        defaultValues.dealProducts &&
        Object.keys(defaultValues.dealProducts).length !== 0
          ? defaultValues.dealProducts
          : [defaultProduct],
    },
  });

  const dealUpdateMutation = trpc.deals.update.useMutation({
    onError: (err) => {
      toast.error(err.message);
    },
  });

  return (
    <ModalDrawer
      title="Deal products"
      description="Add products assosiated with a deal."
      className="w-full h-4/5 sm:h-full sm:max-w-3xl"
      modalClassName="min-w-[800px]"
      open
      onClose={onClose}
    >
      <Form
        {...productsForm}
        onSubmit={async (values) => {
          await dealUpdateMutation.mutateAsync({
            id: dealId,
            deal: {
              dealProducts: values.dealProducts,
            },
          });
          onClose();
          toast.success("Updated deal products.");
        }}
      >
        <FormField name="dealProducts">
          <LineItemSelector required={false} />
        </FormField>
        <Button
          type="submit"
          variant="primary"
          className="self-end"
          loading={dealUpdateMutation.isLoading}
        >
          Update products
        </Button>
      </Form>
    </ModalDrawer>
  );
};

const DealDetails = () => {
  const params = useParams();
  const dealId = Number(params.dealId);
  const navigate = useNavigate();

  const [showAdd, setShowAdd] = useImmer<{
    activity: boolean;
    task: boolean;
    note: boolean;
    log: z.infer<typeof enums.activityTypes> | undefined;
    comment: boolean;
  }>({
    activity: false,
    task: false,
    note: false,
    log: undefined,
    comment: false,
  });
  const [showEdit, setShowEdit] = useState(false);

  const [addProducts, setAddProducts] = useState(false);
  const [lostReasonOpen, setLostReasonOpen] = useState(false);

  const { data: dealDetails } = trpc.deals.details.useQuery(dealId);
  const { data: currentUser } = trpc.users.currentUser.useQuery();

  const dealUpdateMutation = trpc.deals.update.useMutation({
    onError: (err) => {
      toast.error(err.message);
    },
  });

  if (!dealDetails || !currentUser) return <FullScreenSpinner />;

  const updateDealStage = (stage: {
    id: number;
    stageType: z.infer<typeof enums.dealStageType>;
  }) => {
    if (stage.stageType === "WON") {
      doConfettiSideCannons();
    }

    if (stage.stageType === "LOST") {
      setLostReasonOpen(true);
    }
    dealUpdateMutation.mutate({
      id: dealId,
      deal: {
        dealStageId: stage.id,
        status: stage.stageType,
      },
    });
  };

  const calculateDealValue = () => {
    if (dealDetails.dealProducts.length) {
      const total = dealDetails.dealProducts.reduce(
        (acc, product) => acc + product.price * product.quantity,
        0
      );
      return formatCurrency(total, dealDetails.currencies.symbol);
    }
    return formatCurrency(
      dealDetails.price || 0,
      dealDetails.currencies.symbol
    );
  };

  const dealInfo = (
    <ScrollArea
      className="overflow-auto p-3 w-full sm:w-1/5"
      style={{
        height: PageHeight,
      }}
    >
      <div className="flex flex-col gap-3 p-2 border-b border-gray-200">
        <div className="pr-0 text-lg font-semibold">{dealDetails.title}</div>
        <Button
          className="mr-3"
          variant="outline"
          size="sm"
          onClick={() => setShowEdit(true)}
        >
          <Pencil className="h-3.5" />
          Edit Deal
        </Button>
        <div className="flex flex-col gap-2">
          {dealDetails.status === "LOST" && (
            <div className="">
              <InfoItem
                label="Lost reason"
                labelClassName="text-red-500"
                value={
                  dealDetails?.lostReasons?.reason || "No lost reason added"
                }
              />
              <p className="text-xs text-gray-500">
                {dealDetails.lostReasonDescription}
              </p>
            </div>
          )}

          <InfoItem
            label="Expected value"
            value={
              <div className="flex justify-between">
                <p>{calculateDealValue()}</p>
                <p
                  onClick={() => setAddProducts(true)}
                  className="pr-3 font-semibold cursor-pointer text-primary"
                >
                  {dealDetails.dealProducts.length
                    ? `${dealDetails.dealProducts.length} Products`
                    : ""}
                </p>
              </div>
            }
          />
          <InfoItem
            label="Close date"
            value={dealDetails.closingDate?.toDateString()}
          />
          <InfoItem
            label="Priority"
            value={
              <div className="flex gap-1 items-center capitalize">
                <div
                  className={`h-2.5 w-2.5 rounded-full`}
                  style={{
                    background: dealPriorityColors[dealDetails.priority],
                  }}
                />
                {dealDetails.priority.toLowerCase()}
              </div>
            }
          />
          <InfoItem
            label="Deal assignees"
            value={
              <div className="flex flex-row gap-2 items-center px-1">
                {dealDetails.dealAssignees.map((assignee) => (
                  <Badge key={assignee.userId} variant="unique" icon={User}>
                    {assignee.users.firstName}
                  </Badge>
                ))}
              </div>
            }
          />
          <InfoItem
            label="Deal owner"
            value={
              dealDetails.ownedBy
                ? formatName(dealDetails.ownedBy)
                : "No owner added"
            }
          />
          <InfoItem
            label="Vendor"
            value={
              dealDetails.vendors ? dealDetails.vendors.name : "No vendor added"
            }
          />
          {dealDetails.customFieldFields &&
            dealDetails.customFieldFields.map((field) => (
              <InfoItem
                key={field.id}
                label={field.label}
                value={renderCustomFieldValue(
                  field,
                  dealDetails.customFields[field.name]
                )}
              />
            ))}
        </div>
        <div className="flex flex-row gap-1 justify-center items-center w-full">
          <div className="w-1 h-1 bg-green-500 rounded-full" />
          <p className="text-xs text-gray-400">
            Last activity:{" "}
            {dealDetails.updatedAt?.toDateString() || "Not updated"}
          </p>
        </div>
      </div>

      {/* Contact */}
      <div className="flex flex-col justify-between py-4 pr-3 border-b border-gray-200">
        <Collapsible defaultOpen>
          <CollapsibleTrigger>
            <div className="flex flex-row gap-1 items-center">
              <ChevronDown className="w-4 h-4" />
              <h1 className="text-sm font-semibold">Contact</h1>
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent className="flex flex-col gap-3 py-3">
            {dealDetails.dealContacts.map(({ contacts: contactPerson }) => (
              <ContactCard key={contactPerson.id} contact={contactPerson} />
            ))}
            {!dealDetails.dealContacts.length && (
              <Empty
                icon={Contact2}
                title="No contacts added"
                description="Please add a contact assosiated with this deal"
              />
            )}
          </CollapsibleContent>
        </Collapsible>
      </div>
      {/* Contact ends */}

      {/* Company */}
      {dealDetails.clients && (
        <div className="flex flex-col justify-between py-4 pr-3 border-b border-gray-200">
          <Collapsible defaultOpen>
            <CollapsibleTrigger>
              <div className="flex flex-row gap-1 items-center">
                <ChevronDown className="w-4 h-4" />
                <h1 className="text-sm font-semibold">Client</h1>
              </div>
            </CollapsibleTrigger>
            <CollapsibleContent className="flex flex-col gap-3 py-3">
              <ClientCard company={dealDetails.clients} />
            </CollapsibleContent>
          </Collapsible>
        </div>
      )}
      {/* Company ends */}
      <ScrollBar orientation="vertical" />
    </ScrollArea>
  );

  const dealActivity = (
    <ScrollArea
      className={`block p-3 w-full bg-white border-gray-200 border-none sm:w-3/5 sm:bg-gray-100 sm:border-l`}
      style={{ height: PageHeight }}
    >
      {dealDetails.dealPipelineStages && (
        <Card title="Deal stage" className="sm:block">
          <div className="flex justify-between">
            <div>
              <span className="text-gray-500">Start:</span>{" "}
              <span>{dynamicDateFormatting(dealDetails.createdAt, true)}</span>
            </div>
            {dealDetails.closingDate && (
              <div>
                <span className="text-gray-500">Closing:</span>{" "}
                <span>
                  {dynamicDateFormatting(dealDetails.closingDate, true)}
                </span>
              </div>
            )}
          </div>

          {isMobile() ? (
            <Select
              options={dealDetails.dealPipelineStages.dealPipelines.dealPipelineStages.map(
                (stage) => ({
                  label: stage.name,
                  value: stage.id,
                  stageType: stage.stageType,
                })
              )}
              value={dealDetails.dealStageId}
              className="mt-4 text-primary"
              onSelect={(value) => {
                console.log(value);
                updateDealStage({
                  id: value.value,
                  stageType: value.stageType,
                });
              }}
            />
          ) : (
            <div className="flex flex-row flex-grow mt-3">
              {dealDetails.dealPipelineStages.dealPipelines.dealPipelineStages.map(
                (stage, index) => (
                  <Stage
                    key={stage.id}
                    loading={
                      dealUpdateMutation.variables?.deal.dealStageId ===
                        stage.id && dealUpdateMutation.isLoading
                    }
                    onClick={() => updateDealStage(stage)}
                    index={index}
                    stageCount={
                      dealDetails.dealPipelineStages?.dealPipelines
                        .dealPipelineStages.length || 0
                    }
                    text={stage.name}
                    selected={dealDetails.dealStageId === stage.id}
                    selectedIndex={
                      dealDetails.dealPipelineStages?.dealPipelines.dealPipelineStages.findIndex(
                        (s) => s.id === dealDetails.dealStageId
                      ) || 0
                    }
                  />
                )
              )}
            </div>
          )}
        </Card>
      )}
      <div className="flex justify-center items-center mt-3 w-full sm:justify-end">
        <div className="flex gap-5 sm:gap-2 sm:flex">
          <DropMenu
            items={[
              {
                label: "Email",
                onClick: () =>
                  setShowAdd((draft) => {
                    draft.log = "email";
                  }),
                icon: Mail,
              },
              {
                label: "Call",
                onClick: () =>
                  setShowAdd((draft) => {
                    draft.log = "call";
                  }),
                icon: Phone,
              },
              {
                label: "Whatsapp message",
                onClick: () =>
                  setShowAdd((draft) => {
                    draft.log = "whatsapp_message";
                  }),
                icon: MessageCircle,
              },
              {
                label: "Meeting",
                onClick: () =>
                  setShowAdd((draft) => {
                    draft.log = "meeting";
                  }),
                icon: Calendar,
              },
              {
                label: "SMS",
                onClick: () =>
                  setShowAdd((draft) => {
                    draft.log = "sms";
                  }),
                icon: MessageSquare,
              },
            ]}
          >
            <MobileRoundedButton label="Log" icon={PenSquare} />
          </DropMenu>

          <MobileRoundedButton
            label="Note"
            icon={StickyNote}
            onClick={() =>
              setShowAdd((draft) => {
                draft.note = true;
              })
            }
          />
          <MobileRoundedButton
            label="Task"
            icon={CheckCircle}
            onClick={() =>
              setShowAdd((draft) => {
                draft.task = true;
              })
            }
          />
          <MobileRoundedButton
            label="Activity"
            icon={CalendarCheck2}
            onClick={() =>
              setShowAdd((draft) => {
                draft.activity = true;
              })
            }
          />
          <MobileRoundedButton
            label="Message"
            icon={MessageCircle}
            onClick={() =>
              setShowAdd((draft) => {
                draft.comment = true;
              })
            }
          />
        </div>
      </div>

      <DealFocus />
      <DealHistory />
      <ScrollBar orientation="vertical" />
    </ScrollArea>
  );

  const dealResources = (
    <ScrollArea className={`block p-2 w-full h-full sm:w-1/5`}>
      {/* Products */}
      <div className="flex flex-col justify-between py-4 pl-3 w-full border-b border-gray-200">
        <Collapsible defaultOpen>
          <div className="flex justify-between items-center w-full">
            <div className="flex flex-row gap-1 items-center">
              <CollapsibleTrigger className="flex gap-1 items-center">
                <ChevronDown className="w-4 h-4" />{" "}
                <h1 className="text-sm font-semibold">Products</h1>
              </CollapsibleTrigger>
            </div>
            <Button
              onClick={() => setAddProducts(true)}
              size="sm"
              variant="link"
            >
              Edit
            </Button>
          </div>
          <CollapsibleContent>
            <div>
              {!dealDetails.dealProducts.length && (
                <Empty
                  icon={Package}
                  title="No products added"
                  description="Please add some products"
                  actionText="Add products"
                  onAction={() => {
                    setAddProducts(true);
                  }}
                />
              )}
              {dealDetails.dealProducts.map((product) => (
                <div
                  key={product.id}
                  className="flex gap-1 justify-between items-center py-2 border-b border-dashed"
                >
                  <div className="flex gap-1 items-center">
                    <p className="text-sm text-gray-800">
                      {product.products.name}
                    </p>
                    <p className="text-xs text-gray-600">
                      x{Number(product.quantity)}
                    </p>
                  </div>
                  <p className="text-sm text-gray-800">
                    {formatCurrency(
                      Number(product.quantity) * product.price,
                      dealDetails.currencies.symbol
                    )}
                  </p>
                </div>
              ))}
            </div>
          </CollapsibleContent>
        </Collapsible>
      </div>
      {/* Products ends */}

      {/* Quotation */}
      <div className="flex flex-col justify-between py-4 pl-3 border-b border-gray-200">
        <Collapsible defaultOpen>
          <CollapsibleTrigger>
            <div className="flex flex-row gap-1 items-center">
              <ChevronDown className="w-4 h-4" />
              <h1 className="text-sm font-semibold">Quotations</h1>
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent className="flex flex-col gap-3 py-3">
            {dealDetails.quotations.length ? (
              <Button
                onClick={() =>
                  navigate(
                    `/sales/quotations/add?dealId=${dealId}&clientId=${dealDetails.clientId}`
                  )
                }
                variant="outline"
                className="w-full text-gray-600"
              >
                <Plus className="w-4 h-4 text-gray-500" />
                Add Quotation
              </Button>
            ) : (
              <Empty
                title="No quotation added"
                icon={DollarSign}
                description="Please add a quotation to the deal"
                actionText="Add quotation"
                actionHref={`/sales/quotations/add?dealId=${dealId}&clientId=${dealDetails.clientId}`}
                buttonSize="sm"
              />
            )}

            {dealDetails.quotations.map((quotation) => (
              <QuotationCard key={quotation.id} data={quotation} />
            ))}
          </CollapsibleContent>
        </Collapsible>
      </div>
      {/* Quotatoin ends */}
      <ScrollBar orientation="vertical" />
    </ScrollArea>
  );

  return (
    <Page
      className="!p-0"
      breadcrumbs={[
        {
          label: "Deals",
          path: "/crm/deals",
        },
        {
          label: dealDetails.title,
          path: `/crm/deals/details/${dealDetails.id}`,
        },
      ]}
      fullWidth
      showBack
      title="Deal details"
    >
      <AddActivityModal
        open={showAdd.activity}
        onClose={() =>
          setShowAdd((draft) => {
            draft.activity = false;
          })
        }
        dealId={dealId}
      />
      <AddNotesModal
        open={showAdd.note}
        onClose={() =>
          setShowAdd((draft) => {
            draft.note = false;
          })
        }
        dealId={dealId}
      />
      <AddTaskModal
        open={showAdd.task}
        onClose={() =>
          setShowAdd((draft) => {
            draft.task = false;
          })
        }
        dealId={dealId}
      />
      <AddCommentModal
        open={showAdd.comment}
        onClose={() => {
          setShowAdd((draft) => {
            draft.comment = false;
          });
        }}
        dealId={dealId}
      />
      {showAdd.log && (
        <AddLogModal
          open={!!showAdd.log}
          onClose={() =>
            setShowAdd((draft) => {
              draft.log = undefined;
            })
          }
          dealId={dealId}
          type={showAdd.log}
        />
      )}
      <EditDealDrawer
        dealId={dealId}
        open={showEdit}
        onClose={() => setShowEdit(false)}
      />
      {addProducts && (
        <EditProductsDrawer
          dealId={dealId}
          onClose={() => setAddProducts(false)}
          defaultValues={dealDetails}
        />
      )}
      <SelectLostReasonModal
        open={lostReasonOpen}
        onClose={() => setLostReasonOpen(false)}
        type="DEAL"
        defaultValues={{
          reasonId: dealDetails.lostReasonId,
          description: dealDetails.lostReasonDescription,
        }}
        onChange={(params) => {
          dealUpdateMutation.mutate({
            id: dealId,
            deal: {
              lostReasonId: params.reasonId,
              lostReasonDescription: params.description,
              status: "LOST",
            },
          });
        }}
      />

      {isMobile() ? (
        <NextTabs
          className="mt-3"
          tabs={[
            {
              id: "dealActivity",
              label: "Activity",
              content: dealActivity,
            },
            {
              id: "dealInfo",
              label: "Info",
              content: dealInfo,
            },
            {
              id: "dealResources",
              label: "Resources",
              content: dealResources,
            },
          ]}
        />
      ) : (
        <div className="flex flex-row w-full">
          {dealInfo}
          {dealActivity}
          {dealResources}
        </div>
      )}
    </Page>
  );
};

export default DealDetails;
