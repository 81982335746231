import { z } from "zod";
import enums from "./enums";

const customFieldLink = z.object({
  targetFieldName: z.string(),
  targetFieldLabel: z.string(),
  targetEntity: enums.entities,
  type: enums.customFieldLinkTypes,
});

export const customFields = z.object({
  isActive: z.boolean().default(true),
  label: z.string(),
  dataType: enums.customFieldTypes,
  isMultiplier: z.boolean().default(false),
  values: z
    .array(
      z.object({
        value: z.string().min(1, "Value is required"),
        isNew: z.boolean().default(false),
      })
    )
    .default([])
    .superRefine((values, ctx) => {
      const uniqueValues = new Set(
        values.map((item) => item.value.toLowerCase())
      );
      if (uniqueValues.size !== values.length) {
        ctx.addIssue({
          path: ["values"],
          code: z.ZodIssueCode.custom,
          message: "Values must be unique",
        });
      }
    }),
  section: enums.customFieldSections,
  name: z.string(),
  required: z.boolean().default(false),
  unique: z.boolean().default(false),
  placeholder: z.string().optional().default(""),
  dealPipelineId: z.number().nullish(),
  documentTemplateId: z.number().nullish(),
  projectPipelineId: z.number().nullish(),
  customObjectId: z.number().nullish(),
  customFieldLink: customFieldLink.nullish(),
  description: z.string().default(""),
  position: z.number().default(1),
});
