import { Progress } from "@heffl/ui/components/primitives/progress";
import { formatCurrency } from "@heffl/ui/lib/utils";

const PayablesChart = ({
  current,
  overdue,
}: {
  current: number;
  overdue: number;
}) => {
  return (
    <div className="space-y-3">
      <h2 className="text-xl font-semibold text-gray-800">Payables</h2>
      <p className="text-sm text-gray-500">
        Total unpaid bills {formatCurrency(current + overdue, "AED")}
      </p>
      <Progress
        value={(current / (current + overdue)) * 100}
        className="h-3 bg-gray-100"
      />
      <div className="flex justify-between pt-4">
        <div>
          <div className="text-sm font-medium text-primary-500">Current</div>
          <div className="text-xl font-semibold text-gray-800">
            {formatCurrency(current, "AED")}
          </div>
        </div>
        <div>
          <div className="text-sm font-medium text-secondary-800">Overdue</div>
          <div className="text-xl font-semibold text-gray-800">
            {formatCurrency(overdue, "AED")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayablesChart;
