import InfoItemsHZ from "@/components/InfoItemHZ";
import ResponsiveActions from "@heffl/ui/components/responsive-actions";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import ActivityTimeline from "@/pages/crm/components/activity-timeline";
import { getJobId } from "@/pages/field-service/jobs/list";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import TemplateRender from "@heffl/ui/components/template-render";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { formatName, isMobile } from "@heffl/ui/lib/utils";
import { format } from "date-fns";
import dayjs from "dayjs";
import { debounce } from "lodash";
import {
  ArrowDownToLine,
  ArrowLeft,
  CalendarDays,
  CircleDollarSign,
  ClipboardList,
  Copy,
  DollarSign,
  Hammer,
  Handshake,
  Info,
  Loader,
  MailCheck,
  MoreHorizontal,
  PencilIcon,
  Printer,
  StickyNote,
  ThumbsDown,
  ThumbsUp,
  Trash,
  Wrench,
} from "lucide-react";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { downloadPdf } from "../invoices/details";
import MobileQuote from "./clientShare/MobileQuote";
import { QuotationStatusBadge } from "./list";
import { renderCustomFieldValue } from "@heffl/server/src/helpers/customFields";
import { SelectLostReasonModal } from "@/pages/crm/leads/details";
import useTeam from "@/lib/hooks/useTeam";
import AddProjectDrawer from "@/pages/projects/list/components/AddProjectDrawer";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import heffl from "@/helpers/heffl";

export const generateQuotationName = (quotation: {
  date: Date;
  number: string;
  clients: {
    name: string;
  };
}) => {
  // make sure to generate ios safe file names # dosent support in ios, chekc unix file name system
  if (!quotation) return "";
  return `Quotation ${quotation.number}-${quotation.clients.name}-${dayjs(
    quotation.date
  ).format("DD-MM-YYYY")}`;
};

const QuotationDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const quotationPrintRef = useRef<HTMLDivElement>(null);
  const confirm = useConfirm();
  const team = useTeam();

  const quotationId = Number(params.id);

  const [previewMode, setPreviewMode] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [showLostReasonModal, setShowLostReasonModal] = useState(false);
  const [addProjectModal, setAddProjectModal] = useState<{
    open: boolean;
    pipelineId: number | null;
  }>({
    pipelineId: null,
    open: false,
  });

  const { data: quotation } = trpc.quotations.details.useQuery(quotationId);
  const { data: projectPipelines } = trpc.projects.pipelines.list.useQuery();

  const printQuotation = useReactToPrint({
    content: () => quotationPrintRef.current,
    documentTitle: quotation ? generateQuotationName(quotation) : "",
  });

  const updateQuotationMutation = trpc.quotations.update.useMutation({
    onSuccess() {
      toast.success("Quotation updated successfully");
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const updateNotesMutation = trpc.quotations.update.useMutation({
    onSuccess() {
      return {
        invalidate: false,
      };
    },
    onError(error) {
      toast.error(error.message);
    },
  });
  const debouncedUpdateNotes = debounce(updateNotesMutation.mutate, 500);

  const deleteQuotationMutation = trpc.quotations.delete.useMutation({
    onSuccess() {
      toast.success("Quotation deleted successfully");
      navigate("/sales/quotations");
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  if (!quotation || !team) return <FullScreenSpinner />;

  return (
    <Page
      fullWidth
      title={`Quotation #${quotation.number}`}
      tags={
        <div className="flex flex-wrap gap-2">
          <QuotationStatusBadge status={quotation.status} small={isMobile()} />
          {quotation.quotationTags.map((tag) => (
            <Badge small={isMobile()} key={tag.id} background={tag.tags.color}>
              {tag.tags.name}
            </Badge>
          ))}
        </div>
      }
      description={`Created on ${format(
        quotation.createdAt,
        "dd MMM yyyy hh:mm a"
      )}`}
      showBack
      suffix={
        <div className="flex gap-2">
          <ResponsiveActions
            actions={[
              {
                icon: Printer,
                buttonVariant: "outline",
                iconClassName: "text-purple-500",
                buttonClassName: "sm:rounded-r-none",
                label: "Print",
                onClick: () => {
                  setPreviewMode(false);
                  setTimeout(() => {
                    printQuotation();
                    setPreviewMode(true);
                  }, 100);
                },
              },
              {
                buttonClassName: "sm:rounded-l-none sm:rounded-r-none sm:-ml-2",
                buttonVariant: "outline",
                icon: ArrowDownToLine,
                label: "Download",
                onClick: async () => {
                  setDownloadLoading(true);
                  await downloadPdf({
                    name: generateQuotationName(quotation),
                    url: `print?quotation=${quotation.uuid}`,
                  });
                  setTimeout(() => {
                    setDownloadLoading(false);
                  }, 3000);
                },
                loading: downloadLoading,
                hideLabelOnDesktop: true,
              },
              {
                buttonClassName: "sm:rounded-l-none sm:-ml-2",
                buttonVariant: "outline",
                label: "Duplicate",
                icon: Copy,
                hideLabelOnDesktop: true,
                onClick: () =>
                  navigate(`/sales/quotations/add?quotationId=${quotation.id}`),
              },
              {
                hide: quotation.status !== "DRAFT",
                icon: MailCheck,
                label: "Mark as sent",
                iconClassName: "text-green-500",
                loading: updateQuotationMutation.isLoading,
                onClick: () => {
                  updateQuotationMutation.mutate({
                    id: quotation.id,
                    quotation: {
                      status: "SENT",
                    },
                  });
                },
              },
              {
                loading:
                  updateQuotationMutation.isLoading &&
                  updateQuotationMutation.variables?.quotation.status ===
                    "ACCEPTED",
                buttonVariant: "success",
                hide: quotation.status !== "SENT",
                icon: ThumbsUp,
                label: "Accepted",
                onClick: () => {
                  updateQuotationMutation.mutate({
                    id: quotation.id,
                    quotation: { status: "ACCEPTED" },
                  });
                },
              },
              {
                loading:
                  updateQuotationMutation.isLoading &&
                  updateQuotationMutation.variables?.quotation.status ===
                    "REJECTED",
                hide: quotation.status !== "SENT",
                buttonVariant: "destructive",
                icon: ThumbsDown,
                label: "Rejected",
                onClick: async () => {
                  setShowLostReasonModal(true);
                },
              },
              {
                icon: MoreHorizontal,
                label: "More",
                onClick: () => {},
                dropdown: (
                  <>
                    <Button
                      variant="ghost"
                      className="flex justify-start"
                      onClick={() =>
                        navigate(`/sales/quotations/edit/${quotation.id}`)
                      }
                    >
                      <PencilIcon className="mr-2 w-5" />
                      Edit
                    </Button>
                    {quotation.status !== "DRAFT" && (
                      <Button
                        variant="ghost"
                        className="flex justify-start"
                        icon={ArrowLeft}
                        onClick={() => {
                          heffl.modal.confirm({
                            title: "Revert to draft",
                            description:
                              "Are you sure to revert this quotation to draft?",
                            onConfirm: () => {
                              updateQuotationMutation.mutate({
                                id: quotation.id,
                                quotation: { status: "DRAFT" },
                              });
                            },
                          });
                        }}
                      >
                        Revert to draft
                      </Button>
                    )}
                    {team.apps.installed("FIELD_SERVICE") && (
                      <Button
                        variant="ghost"
                        className="flex justify-start"
                        onClick={() => {
                          navigate(
                            `/field-service/jobs/add?quotationId=${quotation.id}`
                          );
                        }}
                        disabled={quotation.status !== "ACCEPTED"}
                      >
                        <Wrench className="mr-2 w-5 text-yellow-500" />
                        Convert to Job
                        {quotation.fsJobQuotations.length > 0 && (
                          <span className="flex justify-center items-center ml-2 w-4 h-4 text-xs text-white bg-yellow-400 rounded-full">
                            {quotation.fsJobQuotations.length}
                          </span>
                        )}
                      </Button>
                    )}
                    {team.apps.installed("PROJECTS") && (
                      <Button
                        variant="ghost"
                        className="flex justify-start"
                        disabled={quotation.status !== "ACCEPTED"}
                        onClick={() => {
                          setAddProjectModal({
                            open: true,
                            pipelineId: null,
                          });
                        }}
                      >
                        <ClipboardList className="mr-2 w-5 text-violet-500" />
                        Convert to Project{" "}
                        {quotation.projectQuotations.length > 0 && (
                          <span className="flex justify-center items-center ml-2 w-4 h-4 text-xs text-white bg-yellow-400 rounded-full">
                            {quotation.projectQuotations.length}
                          </span>
                        )}
                      </Button>
                    )}

                    <Button
                      variant="ghost"
                      className="flex justify-start"
                      disabled={quotation.status !== "ACCEPTED"}
                      onClick={() => {
                        navigate(
                          `/sales/proforma-invoices/add?quotationId=${quotation.id}`
                        );
                      }}
                    >
                      <DollarSign className="mr-2 w-5 text-violet-500" />
                      Convert to Proforma
                    </Button>

                    <Button
                      variant="ghost"
                      className="flex justify-start"
                      disabled={quotation.status !== "ACCEPTED"}
                      onClick={() => {
                        navigate(
                          `/sales/invoices/add?quotationId=${quotation.id}`
                        );
                      }}
                    >
                      <CircleDollarSign className="mr-2 w-5 text-green-500" />
                      Convert to Invoice
                    </Button>
                    <Button
                      variant="ghost"
                      className="flex justify-start"
                      onClick={async () => {
                        const confirmed = await confirm(
                          "Are you sure to delete this quotation?"
                        );
                        if (confirmed) {
                          deleteQuotationMutation.mutate(quotation.id);
                        }
                      }}
                    >
                      <Trash className="mr-2 w-5 text-red-500" />
                      Delete
                    </Button>
                  </>
                ),
              },
            ]}
          />
        </div>
      }
    >
      <SelectLostReasonModal
        submitText="Mark as rejected"
        open={showLostReasonModal}
        onClose={() => setShowLostReasonModal(false)}
        type="QUOTATION"
        onChange={(params) => {
          updateQuotationMutation.mutate({
            id: quotation.id,
            quotation: {
              status: "REJECTED",
              lostReasonId: params.reasonId,
              lostReasonDescription: params.description,
            },
          });
        }}
      />
      {addProjectModal.open && !addProjectModal.pipelineId && (
        <ModalDrawer
          title="Select Project Pipeline"
          open={addProjectModal.open}
          onClose={() => setAddProjectModal({ open: false, pipelineId: null })}
        >
          <div className="space-y-2">
            {projectPipelines?.map((pipeline) => (
              <Card
                key={pipeline.id}
                parentClassName="cursor-pointer hover:border-primary pb-4"
                onClick={() =>
                  setAddProjectModal({
                    open: true,
                    pipelineId: pipeline.id,
                  })
                }
              >
                <p className="font-medium">{pipeline.name}</p>
              </Card>
            ))}
          </div>
        </ModalDrawer>
      )}
      {addProjectModal.open && addProjectModal.pipelineId && (
        <AddProjectDrawer
          open={addProjectModal.open}
          onClose={(projectId) => {
            setAddProjectModal({ open: false, pipelineId: null });
            if (projectId) {
              navigate(`/projects/details/${projectId}`);
            }
          }}
          pipelineId={addProjectModal.pipelineId}
          defaultValues={{
            projectQuotations: [quotation.id],
          }}
        />
      )}
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-12">
        <div className="flex justify-center w-full sm:col-span-8">
          <div className="w-full rounded-lg border border-gray-200 shadow-lg sm:w-fit">
            <div ref={quotationPrintRef}>
              {isMobile() ? (
                <MobileQuote quote={quotation} />
              ) : (
                <TemplateRender
                  contentHtml={quotation.contentInjected}
                  template={quotation.documentTemplates}
                  previewMode={previewMode}
                />
              )}
            </div>
          </div>
        </div>
        <div className="space-y-4 w-full sm:col-span-4">
          <Card
            title="Details"
            icon={<Info className="h-5 text-blue-500" />}
            className="space-y-3"
          >
            <InfoItemsHZ
              items={[
                {
                  hidden: quotation.status !== "REJECTED",
                  label: "Rejection reason",
                  value: (
                    <div>
                      <p className="text-red-500">
                        {quotation.lostReasons?.reason}
                      </p>
                      <p className="text-sm text-gray-500">
                        {quotation.lostReasonDescription}
                      </p>
                    </div>
                  ),
                },
                {
                  label: "Date",
                  value: dayjs(quotation.date).format("DD/MM/YYYY"),
                },
                {
                  label: "Status",
                  value: <QuotationStatusBadge status={quotation.status} />,
                },
                {
                  label: "Client",
                  value: quotation.clients.name,
                  onClick: () =>
                    navigate(`/sales/clients/details/${quotation.clients.id}`),
                },

                {
                  label: "Expiry",
                  value: quotation.expiryDate
                    ? dayjs(quotation.expiryDate).format("DD/MM/YYYY")
                    : "No Expiry Date",
                },
                {
                  hidden: quotation.fsJobQuotations.length === 0,
                  label: "Jobs",
                  value: (
                    <div className="flex flex-wrap gap-2 items-center">
                      {quotation.fsJobQuotations.map(({ fsJobs: job }) => (
                        <Badge
                          onClick={() =>
                            navigate(`/field-service/jobs/details/${job.id}`)
                          }
                          key={job.id}
                          background="gray"
                          icon={Hammer}
                          variant="unique"
                          small
                          className="cursor-pointer"
                        >
                          {getJobId(job)}
                        </Badge>
                      ))}
                      {!quotation.fsJobQuotations.length && "No jobs linked"}
                    </div>
                  ),
                },
                {
                  hidden: quotation.projectQuotations.length === 0,
                  label: "Projects",
                  value: (
                    <div className="flex flex-wrap gap-2 items-center">
                      {quotation.projectQuotations.map(
                        ({ projects: project }) => (
                          <Badge
                            onClick={() =>
                              navigate(`/projects/details/${project.id}`)
                            }
                            key={project.id}
                            background="gray"
                            icon={Hammer}
                            variant="unique"
                            small
                            className="cursor-pointer"
                          >
                            {project.title}
                          </Badge>
                        )
                      )}
                      {!quotation.projectQuotations.length &&
                        "No projects linked"}
                    </div>
                  ),
                },
                {
                  hidden: !quotation.deals,
                  label: "Deal",
                  value: quotation.deals ? (
                    <Badge
                      onClick={() =>
                        navigate(`/crm/deals/details/${quotation.deals?.id}`)
                      }
                      background="blue"
                      icon={Handshake}
                      variant="unique"
                      small
                      className="cursor-pointer"
                    >
                      {quotation.deals.title}
                    </Badge>
                  ) : (
                    "No deal linked"
                  ),
                },
                {
                  label: "Sales person",
                  value: quotation.salesPerson
                    ? quotation.salesPerson.firstName
                    : "No Sales Person",
                },
                { label: "Created by", value: formatName(quotation.createdBy) },
                ...(quotation.customFieldDetails?.fields || []).map(
                  (field) => ({
                    label: field.label,
                    value: renderCustomFieldValue(
                      field,
                      quotation.customFieldDetails?.values[field.name]
                    ),
                  })
                ),
              ]}
            />
          </Card>
          <Card
            title="Notes"
            icon={<StickyNote className="text-yellow-500" />}
            className="pb-4"
          >
            <MiniRichTextEditor
              placeholder="Add notes about quotation..."
              value={quotation.internalNotes || ""}
              onChange={(v) => {
                debouncedUpdateNotes({
                  id: quotation.id,
                  quotation: {
                    internalNotes: v,
                  },
                });
              }}
              height={170}
            />
            {updateNotesMutation.isLoading && (
              <div className="flex gap-2 items-center mt-1 float-end">
                <p>Updating</p>
                <Loader className="h-5 animate-twSpin text-primary" />
              </div>
            )}
          </Card>
          <Card
            title="Timeline"
            icon={<CalendarDays className="h-5 text-green-500" />}
          >
            <ActivityTimeline activities={quotation.activities} />
          </Card>
        </div>
      </div>
    </Page>
  );
};

export default QuotationDetails;
