import {
  ClientInput,
  ContactInput,
  UserInput,
} from "@/components/FormComponents";
import LineItemSelectorNew from "@/components/line-item-selector-new";
import CustomFieldsInputForm from "@/helpers/customFields/custom-fields-input-form";
import { trpc } from "@/helpers/trpc";
import { QUOTATION_STATUS } from "@/lib/constants";
import { calculateQuotationLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import { dynamicTags } from "@heffl/server/src/helpers/templates/dynamicTags";
import Schemas from "@heffl/server/src/schemas";
import RichTextEditor from "@heffl/ui/components/primitives/RichTextEditor";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import { MultiSelect } from "@heffl/ui/components/primitives/multi-select";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import { cn, formatCurrency } from "@heffl/ui/lib/utils";
import { Building2, CircleDollarSign, Pencil, Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";

const QuotationForm = ({
  form,
  edit = false,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.sales.quotation>, unknown>;
  edit?: boolean;
}) => {
  const { data: templates } = trpc.templates.list.useQuery({
    type: "QUOTATION",
  });

  const clientId = form.watch("clientId");
  const templateId = form.watch("templateId");
  const lineItems = form.watch("quotationProducts") || [];
  const discount = form.watch("discount");
  const status = form.watch("status");

  const { data: customFields } = trpc.customizations.customFields.list.useQuery(
    {
      section: "QUOTATION_LINE_ITEM",
      documentTemplateId: templateId,
    },
    {
      enabled: !!templateId,
    }
  );

  const lineItemsValues = calculateQuotationLineItems({
    lineItems: lineItems.map((item) => ({
      ...item,
      customFields: item.customFields || {},
    })),
    discount,
    customFields: customFields || [],
  });

  const [showEditContent, setShowEditContent] = useState(false);

  const { data: products } = trpc.products.list.useQuery({
    pageNo: 1,
    pageSize: 20,
  });
  const { data: deals } = trpc.quotations.deals.useQuery(
    {
      clientId,
    },
    {
      enabled: !!clientId,
    }
  );

  const { data: templateDetails } = trpc.templates.details.useQuery(
    Number(templateId),
    {
      enabled: !!templateId && !edit,
    }
  );

  const { data: tags } = trpc.tags.list.useQuery({
    type: "QUOTATION",
  });

  const includeItemsVat = () => {
    // decrease vat of each items
    const updatedLineItems = lineItems.map((item) => {
      const vatDeductedAmount: number = (item.price * 100) / (100 + item.tax);
      return {
        ...item,
        price: Number(vatDeductedAmount.toFixed(2)),
      };
    });
    form.setValue("quotationProducts", updatedLineItems);
  };

  useEffect(() => {
    if (templateDetails && !edit) {
      form.setValue("contentHtml", templateDetails.contentHtml || "");
    }
  }, [templateDetails, form, edit]);

  useEffect(() => {
    if (templates?.length && !edit) {
      form.setValue("templateId", templates[0].id);
    }
  }, [templates, edit, form]);

  if (!products || !templates || !tags) return <FullScreenSpinner />;

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-3">
        {!edit && (
          <FormField name="templateId" label="Template">
            <Select
              options={templates.map((template) => ({
                label: template.name,
                value: template.id,
              }))}
            />
          </FormField>
        )}
        <FormField name="date" label="Date">
          <DatePicker />
        </FormField>
        {edit && (
          <FormField name="status" label="Status">
            <Select
              allowClear={false}
              options={QUOTATION_STATUS.filter((item) =>
                status === "REJECTED" ? true : item.value !== "REJECTED"
              ).map((status) => ({
                label: status.label,
                value: status.value,
                color: status.color,
              }))}
              render={(option) => (
                <Badge className={cn("text-xs text-white", option.color)}>
                  {option.label}
                </Badge>
              )}
            />
          </FormField>
        )}
        <FormField name="quotationTags" label="Tags">
          <MultiSelect
            placeholder="Add tags"
            options={tags?.map((tag) => ({
              label: tag.name,
              value: tag.id,
              color: tag.color,
            }))}
          />
        </FormField>
      </div>

      <div className="grid grid-cols-1 gap-2 sm:grid-cols-3">
        <ClientInput
          className="w-full"
          name="clientId"
          label="Client"
          onAddModalClose={(id) => id && form.setValue("clientId", id)}
        />
        <ContactInput
          name="contactId"
          label="Contact"
          clientId={clientId}
          fetchEnabled={!!clientId}
          onAddModalClose={(id) => id && form.setValue("contactId", id)}
          disabled={!clientId}
          setDefault={!edit && !!clientId}
          allowClear
        />
        <FormField name="dealId" label="Deal">
          <Select
            allowClear
            placeholder="Link deal"
            icon={<CircleDollarSign className="h-5" />}
            options={deals?.map((deal) => ({
              ...deal,
              label: deal.title,
              value: deal.id,
            }))}
            render={(option) => (
              <div className="flex flex-col gap-1">
                <p className="text-sm font-semibold">{option.title}</p>
                <div className="flex flex-row gap-2">
                  {option?.price && (
                    <span className="flex flex-row gap-1 items-center p-1 px-2 text-xs bg-violet-100 rounded-full">
                      {" "}
                      <CircleDollarSign className="h-3" /> AED {option.price}
                    </span>
                  )}
                  <span className="flex flex-row gap-1 items-center p-1 px-2 text-xs bg-yellow-100 rounded-full">
                    {" "}
                    <Building2 className="h-3" />
                    {option.clients.name}
                  </span>
                </div>
              </div>
            )}
          />
        </FormField>
      </div>
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-3">
        <FormField name="expiryDate" label="Expiry date">
          <DatePicker />
        </FormField>
        <FormField name="subject" label="Subject" className="colspan-2">
          <Input placeholder="Quote subject" />
        </FormField>

        <UserInput
          name="salesPersonId"
          label="Sales person"
          defaultCurrentUser
          type={["STAFF", "ADMIN", "OWNER", "SUPER_ADMIN"]}
          allowClear
        />
        <CustomFieldsInputForm
          section="QUOTATION"
          documentTemplateId={templateId}
        />
      </div>

      <div className="mb-6">
        <div className="flex flex-col py-2">
          <FormField name="quotationProducts" label="">
            <LineItemSelectorNew
              isEdit={edit}
              documentTemplateId={templateId}
              required
              type="QUOTATION"
            />
          </FormField>
        </div>
      </div>
      <div className="flex justify-end w-full">
        <table className="gap-8 text-base border-separate border-spacing-y-2">
          <tr>
            <td className="w-52">Subtotal</td>
            <td className="text-right">
              {formatCurrency(lineItemsValues.subTotal, "AED")}
            </td>
          </tr>
          <tr>
            <td className="w-52">Discount</td>
            <td className="text-right">
              <FormField name="discount">
                <Input suffix="AED" className="w-[70px]" />
              </FormField>
            </td>
          </tr>
          <tr>
            <td className="flex gap-2 items-center w-52">
              VAT
              <Button
                onClick={includeItemsVat}
                size="sm"
                variant="primaryOutline"
              >
                <Plus className="h-4" />
                Include
              </Button>
            </td>
            <td className="text-right">
              {formatCurrency(lineItemsValues.totalTax, "AED")}
            </td>
          </tr>
          <tr>
            <td className="w-52">Total</td>
            <td className="font-semibold text-right">
              {formatCurrency(lineItemsValues.total, "AED")}
            </td>
          </tr>
        </table>
      </div>
      {!showEditContent && (
        <Button
          variant="primaryOutline"
          onClick={() => setShowEditContent(true)}
        >
          <Pencil className="h-4" /> Edit content
        </Button>
      )}

      <FormField
        name="contentHtml"
        label="Content"
        className={cn(!showEditContent && "hidden")}
      >
        <RichTextEditor
          height="700px"
          dynamicTags={[
            dynamicTags.Client,
            dynamicTags.Contact,
            dynamicTags.Quote,
          ]}
        />
      </FormField>

      <FormField
        name="internalNotes"
        label="Notes"
        className="w-full sm:float-right sm:w-1/2"
      >
        <Textarea />
      </FormField>
    </div>
  );
};

export default QuotationForm;
