import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { Button } from "@heffl/ui/components/primitives/button";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { formatName, isMobile } from "@heffl/ui/lib/utils";
import { KanbanIcon, List, Pencil, Plus } from "lucide-react";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddProjectDrawer from "./components/AddProjectDrawer";

import FilterBar from "@/components/filters";
import { useParamsState } from "@/lib/hooks/useParamsState";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import { match } from "ts-pattern";
import ProjectsKanban from "./components/ProjectsKanban";
import MobileList from "./mobileList";
import ProjectTable from "./table";
import { Switch } from "@heffl/ui/components/primitives/switch";

type ProjectFilter = {
  pipelineId: number | null;
  clients: number[];
  tags: number[];
  assignees: number[];
  statuses: ("OPEN" | "CLOSED")[];
  pageNo?: number;
  pageSize?: number;
  archived: boolean;
};

const ProjectsListPage = () => {
  const trpcUtils = trpc.useUtils();
  const navigate = useNavigate();

  const { view } = useParams<{ view?: "table" | "board" }>();

  const [addProjectDrawer, setAddProjectDrawer] = useState(false);
  const [clientSearch, setClientSearch] = useState("");

  const [filters, setFilters] = useParamsState<ProjectFilter>({
    pipelineId: null,
    clients: [],
    tags: [],
    assignees: [],
    statuses: [],
    pageNo: 1,
    pageSize: 30,
    archived: false,
  });

  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageNo: 1,
    pageSize: 10,
  });
  const { data: tags } = trpc.tags.list.useQuery({
    type: "PROJECT",
  });
  const { data: users } = trpc.users.list.useQuery({
    excludeType: ["FIELD_STAFF"],
  });

  const { data: pipelines } = trpc.projects.pipelines.list.useQuery();

  const { data: pipelineDetails } = trpc.projects.pipelines.details.useQuery(
    filters.pipelineId || 0,
    {
      enabled: !!filters.pipelineId,
    }
  );
  const projectsFilters = useMemo(
    () => ({
      pipelines: filters.pipelineId ? [filters.pipelineId] : [],
      clients: filters.clients,
      tags: filters.tags,
      assignees: filters.assignees,
      statuses: filters.statuses,
      archived: filters.archived,
      pageNo: view === "table" && !isMobile() ? filters.pageNo : undefined,
      pageSize: view === "table" && !isMobile() ? filters.pageSize : undefined,
    }),
    [filters]
  );

  const { data: projects } = trpc.projects.list.useQuery(projectsFilters);

  return (
    <Page title="Projects" fullWidth className="!p-0">
      {filters.pipelineId && (
        <AddProjectDrawer
          open={addProjectDrawer}
          onClose={() => setAddProjectDrawer(false)}
          pipelineId={filters.pipelineId}
        />
      )}
      <StripeTabs
        className="pt-2 w-full"
        tabParentClassName="pl-4"
        value={view}
        onChange={(tab) => navigate(`/projects/${tab}`)}
        items={[
          { label: "Board", key: "board", icon: KanbanIcon },
          { label: "Table", key: "table", icon: List },
        ]}
        suffix={
          filters.pipelineId && (
            <ResponsiveActionButton
              onClick={() => setAddProjectDrawer(true)}
              text={pipelineDetails?.singularName || ""}
            />
          )
        }
      />

      <div className="flex flex-wrap items-center py-3 w-full">
        <FilterBar
          className="px-3"
          defaultFilters={["pipelineId"]}
          filters={[
            {
              key: "pipelineId",
              type: "checkbox",
              multiple: false,
              label: "Pipeline",
              value: filters.pipelineId ? [filters.pipelineId] : [],
              onChange: (value) =>
                setFilters({
                  pipelineId: value.length > 0 ? Number(value[0]) : null,
                }),
              options: pipelines?.map((pipeline) => ({
                label: pipeline.name,
                value: pipeline.id,
              })),
            },
            {
              key: "clients",
              type: "checkbox",
              label: "Clients",
              showSearch: true,
              value: filters.clients,
              onChange: (value) => setFilters({ clients: value as number[] }),
              options: clients?.clients.map((client) => ({
                label: client.name,
                value: client.id,
              })),
              onSearch: (e) => setClientSearch(e),
            },
            {
              key: "assignees",
              type: "checkbox",
              label: "Assignees",
              value: filters.assignees,
              onChange: (value) => setFilters({ assignees: value as number[] }),
              options: users?.map((user) => ({
                label: formatName(user),
                value: user.id,
              })),
            },
            {
              key: "statuses",
              type: "checkbox",
              label: "Status",
              value: filters.statuses,
              onChange: (value) =>
                setFilters({ statuses: value as ("OPEN" | "CLOSED")[] }),
              options: ["OPEN", "CLOSED"].map((status) => ({
                label: status,
                value: status,
              })),
            },
            {
              key: "tags",
              type: "checkbox",
              label: "Tags",
              value: filters.tags,
              onChange: (value) => setFilters({ tags: value as number[] }),
              options: tags?.map((tag) => ({
                label: tag.name,
                value: tag.id,
              })),
            },
          ]}
        />
        <div className="flex gap-2 items-center pl-3 mt-3 sm:mt-0">
          <Switch
            value={filters.archived}
            onChange={(value) => setFilters({ archived: value })}
            checkedChildren="Archived"
            unCheckedChildren="Archived"
          />
          {filters.pipelineId && (
            <Button
              onClick={() =>
                navigate(`/projects/pipelines/edit/${filters.pipelineId}`)
              }
              variant="primaryOutline"
              icon={Pencil}
              size="sm"
            >
              Edit pipeline
            </Button>
          )}
          <Button
            onClick={() => navigate("/projects/pipelines/add")}
            variant="primaryOutline"
            icon={Plus}
            size="sm"
          >
            Add pipeline
          </Button>
        </div>
      </div>

      {match({ view, isMobile: isMobile() })
        .with({ view: "board" }, () => (
          <ProjectsKanban
            projects={projects?.projects || []}
            pipelineId={filters.pipelineId}
            onChange={(updatedProjects) => {
              if (projects?.meta) {
                trpcUtils.projects.list.setData(projectsFilters, () => ({
                  projects: updatedProjects,
                  meta: projects?.meta,
                }));
              }
            }}
          />
        ))
        .with({ isMobile: true }, () => (
          <MobileList projects={projects?.projects || []} />
        ))
        .with({ view: "table", isMobile: false }, () => (
          <ProjectTable
            projects={projects}
            pageNo={filters.pageNo || 1}
            pageSize={filters.pageSize || 30}
            setPageNo={(pageNo) => setFilters({ pageNo })}
          />
        ))
        .otherwise(() => null)}
    </Page>
  );
};

export default ProjectsListPage;
