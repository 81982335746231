import LineItemSelector from "@/components/line-item-selector";
import { trpc } from "@/helpers/trpc";
import PropertySelector, {
  EditPropertyModal,
} from "@/pages/field-service/components/property-selector";
import { Card } from "@heffl/ui/components/primitives/card";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import { MultiSelect } from "@heffl/ui/components/primitives/multi-select";
import { cn, formatCurrency } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import {
  Building2,
  CalendarDays,
  CheckCircle,
  Clock,
  DollarSign,
  Hammer,
  Info,
  LucideIcon,
  Play,
  ScrollText,
  Snowflake,
  Tag,
  XCircle,
} from "lucide-react";
import { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { RRule, rrulestr } from "rrule";

import {
  ContactInput,
  MultipleFieldStaffInput,
  UserInput,
  VendorInput,
} from "@/components/FormComponents";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";

import enums from "@heffl/server/src/schemas/enums";
import DropMenu from "@heffl/ui/components/DropMenu";
import FilePicker from "@heffl/ui/components/file-picker";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/creatable-select";
import TimePicker from "@heffl/ui/components/primitives/time-picker";
import { nanoid } from "nanoid";
import { z } from "zod";
import { joinDateAndTime } from "../../schedules/components/schduleDetailsModal";
import FrequencySelector from "./job-frequency-selector";
import { SchedulesInput } from "./schedules-input";

import Schemas from "@heffl/server/src/schemas";

export type FsSchedule = z.infer<
  typeof Schemas.fieldService.jobAddSchema.shape.fsSchedules
>[number];

export const jobEditSchema = Schemas.fieldService.fsJob.superRefine(
  (
    {
      endDate,
      type,
      paymentsCount,
      startDate,
      startTime,
      endTime,
      vendorCharge,
      vendorId,
    },
    ctx
  ) => {
    if (
      endDate &&
      dayjs(endDate).startOf("day").isBefore(dayjs(startDate).startOf("day"))
    ) {
      ctx.addIssue({
        code: "custom",
        message: "End date cannot be before start date",
        path: ["endDate"],
      });
    }
    if (type === "CONTRACT") {
      paymentsCount < 1 &&
        ctx.addIssue({
          code: "custom",
          message: "Payments count is required for contract",
          path: ["paymentsCount"],
        });
      !endDate &&
        ctx.addIssue({
          code: "custom",
          message: "End date is required for contract",
          path: ["endDate"],
        });
    }

    if (startTime && !endTime) {
      ctx.addIssue({
        code: "custom",
        message: "End time must be provided",
        path: ["endTime"],
      });
    }
    if (endTime && !startTime) {
      ctx.addIssue({
        code: "custom",
        message: "Start time must be provided",
        path: ["startTime"],
      });
    }

    if (
      (vendorId && !(vendorCharge && vendorCharge > 0)) ||
      (vendorCharge && vendorCharge > 0 && !vendorId)
    ) {
      ctx.addIssue({
        code: "custom",
        message: vendorId
          ? "Vendor charge must be greater than 0"
          : "Vendor is required when charge is provided",
        path: vendorId ? ["vendorCharge"] : ["vendorId"],
      });
    }
    if (
      dayjs(startDate).isSame(endDate, "day") &&
      startTime &&
      endTime &&
      dayjs(endTime).isBefore(startTime)
    ) {
      ctx.addIssue({
        code: "custom",
        message: "End time must be after start time",
        path: ["endTime"],
      });
    }
  }
);

export const jobSchema = Schemas.fieldService.jobAddSchema.superRefine(
  (
    {
      endDate,
      type,
      paymentsCount,
      startDate,
      startTime,
      endTime,
      vendorCharge,
      vendorId,
    },
    ctx
  ) => {
    if (
      endDate &&
      dayjs(endDate).startOf("day").isBefore(dayjs(startDate).startOf("day"))
    ) {
      ctx.addIssue({
        code: "custom",
        message: "End date cannot be before start date",
        path: ["endDate"],
      });
    }
    if (type === "CONTRACT") {
      paymentsCount < 1 &&
        ctx.addIssue({
          code: "custom",
          message: "Payments count is required for contract",
          path: ["paymentsCount"],
        });
      !endDate &&
        ctx.addIssue({
          code: "custom",
          message: "End date is required for contract",
          path: ["endDate"],
        });
    }

    if (startTime && !endTime) {
      ctx.addIssue({
        code: "custom",
        message: "End time must be provided",
        path: ["endTime"],
      });
    }
    if (endTime && !startTime) {
      ctx.addIssue({
        code: "custom",
        message: "Start time must be provided",
        path: ["startTime"],
      });
    }

    if (
      (vendorId && !(vendorCharge && vendorCharge > 0)) ||
      (vendorCharge && vendorCharge > 0 && !vendorId)
    ) {
      ctx.addIssue({
        code: "custom",
        message: vendorId
          ? "Vendor charge must be greater than 0"
          : "Vendor is required when charge is provided",
        path: vendorId ? ["vendorCharge"] : ["vendorId"],
      });
    }
    if (
      dayjs(startDate).isSame(endDate, "day") &&
      startTime &&
      endTime &&
      dayjs(endTime).isBefore(startTime)
    ) {
      ctx.addIssue({
        code: "custom",
        message: "End time must be after start time",
        path: ["endTime"],
      });
    }
  }
);

export const jobStatusOptions: {
  label: string;
  value: z.infer<typeof enums.jobStatus>;
  color: string;
  hex: string;
  icon: LucideIcon;
}[] = [
  {
    label: "Pending",
    value: "PENDING",
    color: "text-yellow-500",
    hex: "#EAB308",
    icon: Clock,
  },
  {
    label: "Active",
    value: "ACTIVE",
    color: "text-blue-400",
    hex: "#3B82F6",
    icon: Play,
  },
  {
    label: "Completed",
    value: "COMPLETED",
    color: "text-green-600",
    hex: "#16A34A",
    icon: CheckCircle,
  },
  {
    label: "Freezed",
    value: "FREEZED",
    color: "text-gray-500",
    hex: "#6B7280",
    icon: Snowflake,
  },
  {
    label: "Cancelled",
    value: "CANCELLED",
    color: "text-red-500",
    hex: "#EF4444",
    icon: XCircle,
  },
];
// TODO: dont allow invoice creation more than job amount
const addContractInvoiceRequired = (
  schedules: FsSchedule[],
  totalPaymentsCount: number
) => {
  const updatedSchedules = schedules.map((schedule) => ({
    ...schedule,
    invoiceRequired: false,
  }));
  if (totalPaymentsCount > 0) {
    const invoiceInterval = Math.floor(
      updatedSchedules.length / totalPaymentsCount
    );
    for (let i = 0; i < totalPaymentsCount; i++) {
      updatedSchedules[i * invoiceInterval] = {
        ...updatedSchedules[i * invoiceInterval],
        invoiceRequired: true,
      };
    }
  }
  return updatedSchedules;
};

const syncStartEndDate = ({
  startDate,
  endDate,
  startTime,
  endTime,
  type,
  schedules,
}: {
  schedules: unknown[];
  startDate: Date;
  endDate?: Date | null;
  startTime?: Date | null;
  endTime?: Date | null;
  type: "ONEOFF" | "CONTRACT";
}) => {
  const newStartDate = joinDateAndTime(
    startDate,
    startTime || new Date(startDate.setHours(0, 0, 0, 0))
  );
  let newEndDate = joinDateAndTime(
    type === "ONEOFF" && schedules.length === 1
      ? endDate || startDate
      : startDate,
    endTime || new Date(startDate.setHours(0, 0, 0, 0))
  );
  if (dayjs(newEndDate).isBefore(dayjs(newStartDate))) {
    newEndDate = dayjs(newEndDate).add(1, "day").toDate();
  }
  return { startDate: newStartDate, endDate: newEndDate };
};

type GenerateSchedulesProps = {
  jobEndDate?: Date | null;
  jobStartDate: Date;
  frequencyRule?: string | null;
  isDefaultValuesSet: boolean;
  jobStartTime?: Date | null;
  jobEndTime?: Date | null;
  jobType: "ONEOFF" | "CONTRACT";
  fsScheduleAssignees: number[];
  oneoffDefaultConfirmed: boolean;
};
const generateSchedules = ({
  jobEndDate,
  jobStartDate,
  frequencyRule,
  isDefaultValuesSet,
  jobStartTime,
  jobEndTime,
  jobType,
  fsScheduleAssignees,
  oneoffDefaultConfirmed,
}: GenerateSchedulesProps) => {
  if (!jobStartDate || !isDefaultValuesSet) return;
  let schedulesGenerated: Date[] = [];
  const shouldGenerateSchedules =
    frequencyRule && jobEndDate && !dayjs(jobStartDate).isSame(jobEndDate);
  if (shouldGenerateSchedules) {
    const inputRrule = rrulestr(frequencyRule);
    const rrule = new RRule({
      ...inputRrule.origOptions,
      dtstart: new Date(
        Date.UTC(
          jobStartDate.getFullYear(),
          jobStartDate.getMonth(),
          jobStartDate.getDate()
        )
      ),
      until: new Date(
        Date.UTC(
          jobEndDate.getFullYear(),
          jobEndDate.getMonth(),
          jobEndDate.getDate()
        )
      ),
      tzid: "Asia/Dubai",
    });
    schedulesGenerated = rrule.all();
  } else {
    schedulesGenerated = [jobStartDate];
  }

  const schedulesFormatted: FsSchedule[] = schedulesGenerated.map(
    (schedule, index) => {
      const { startDate, endDate } = syncStartEndDate({
        startDate: schedule,
        endDate: jobEndDate,
        startTime: jobStartTime,
        endTime: jobEndTime,
        type: jobType,
        schedules: schedulesGenerated,
      });
      return {
        uuid: nanoid(),
        startDate,
        endDate,
        timeDisabled: !jobStartTime || !jobEndTime,
        status:
          schedulesGenerated.length > 1 || !oneoffDefaultConfirmed
            ? "SCHEDULED"
            : "CONFIRMED",
        invoiceRequired:
          index === schedulesGenerated.length - 1 && jobType === "ONEOFF",
        fsScheduleAssignees,
        fsScheduleTags: [],
        fsScheduleParts: [],
        fsScheduleServices: [],
      };
    }
  );

  // will update invoiceRequired if generate but not when payments count is changed
  // if (jobType === "CONTRACT") {
  //   schedulesFormatted = addContractInvoiceRequired(
  //     schedulesFormatted,
  //     totalPaymentsCount
  //   );
  // }
  return schedulesFormatted;
};

// schddules set is used to check if default values are set or not
const JobForm = ({
  form,
  edit = false,
  isDefaultValuesSet,
  defaultClientId,
}: {
  form: UseFormReturn<z.infer<typeof jobSchema>>;
  edit?: boolean;
  isDefaultValuesSet: boolean;
  defaultClientId?: number | null;
}) => {
  const jobEndDate = form.watch("endDate");
  const jobStartDate = form.watch("startDate");
  const jobType = form.watch("type");
  const schedules = form.watch("fsSchedules");
  const jobStartTime = form.watch("startTime");
  const jobEndTime = form.watch("endTime");
  const fsScheduleAssignees = form.watch("fsScheduleAssignees");
  const propertyId = form.watch("fsPropertyId");
  const frequencyRule = form.watch("frequencyRule");
  const vendorId = form.watch("vendorId");
  const lineItems = form.watch("fsJobServices");
  const discount = form.watch("discount");

  const lineItemsValues = calculateLineItems({ lineItems, discount });

  const [clientId, setClientId] = useState<number | undefined>();
  const [showEditPropertyModal, setShowEditPropertyModal] = useState(false);

  const { data: team } = trpc.teams.currentTeam.useQuery();
  const { data: tags } = trpc.tags.list.useQuery({
    type: "JOB",
  });
  const { data: quotations } = trpc.quotations.list.useQuery(
    {
      fsProperties: [propertyId],
    },
    {
      enabled: !!propertyId,
    }
  );
  // const { data: dataVendors } = trpc.vendors.list.useQuery();
  const { data: paymentMethods } =
    trpc.invoices.payments.methods.list.useQuery();

  const { data: contactsData } = trpc.contacts.list.useQuery(
    {
      clientId: clientId,
    },
    {
      enabled: !!clientId,
    }
  );

  const updateSchedules = () => {
    if (!schedules?.length || !isDefaultValuesSet) return;
    const schedulesUpdated: FsSchedule[] = schedules.map((schedule) => {
      const { startDate, endDate } = syncStartEndDate({
        startDate: schedule.startDate,
        endDate: jobEndDate,
        startTime: jobStartTime,
        endTime: jobEndTime,
        type: jobType,
        schedules: schedules,
      });
      return {
        ...schedule,
        ...(jobStartTime &&
          jobEndTime && {
            startDate,
            endDate,
            timeDisabled: false,
          }),
        fsScheduleAssignees,
        fsScheduleServices: schedule.fsScheduleServices || [],
      };
    });
    // if (jobType === "CONTRACT") {
    //   schedulesUpdated = addContractInvoiceRequired(
    //     schedulesUpdated,
    //     totalPaymentsCount
    //   );
    // }
    form.setValue("fsSchedules", schedulesUpdated);
  };

  // set contact on client select
  useEffect(() => {
    if (!clientId) {
      form.setValue("contactPersonId", undefined);
      form.setValue("siteContactPersonId", undefined);
    } else if (contactsData?.contacts?.length) {
      form.setValue("contactPersonId", contactsData.contacts[0].id);
      form.setValue("siteContactPersonId", contactsData.contacts[0].id);
    }
  }, [contactsData]);

  // update schedules list - not replacing the whole list
  useEffect(() => {
    updateSchedules();
  }, [jobStartTime, jobEndTime, fsScheduleAssignees]);

  // regenerate schedules on start date, end date, or frequency rule change
  useEffect(() => {
    const generatedSchedules = generateSchedules({
      jobEndDate,
      jobStartDate,
      frequencyRule,
      isDefaultValuesSet,
      jobStartTime,
      jobEndTime,
      jobType,
      fsScheduleAssignees,
      oneoffDefaultConfirmed:
        team?.teamSettings?.fsJobs?.oneoffDefaultConfirmed ?? true,
    });
    if (generatedSchedules) {
      form.setValue("fsSchedules", generatedSchedules);
    }
  }, [jobEndDate, jobStartDate, frequencyRule]);

  const showSchedulesInfo =
    (jobEndDate &&
      jobStartDate &&
      dayjs(jobEndDate).isAfter(dayjs(jobStartDate)) &&
      !dayjs(jobStartDate).isSame(dayjs(jobEndDate), "day")) ||
    jobType === "CONTRACT";

  const resetScheduleSettings = () => {
    if (!edit) {
      form.setValue("fsSchedules", []);
      // @ts-ignore
      form.setValue("endDate", null);
      if (jobType === "ONEOFF") {
        form.setValue("paymentsCount", 0);
        // @ts-ignore
      } else form.setValue("paymentsCount", null);
    }
  };

  return (
    <>
      {showEditPropertyModal && (
        <EditPropertyModal
          open={showEditPropertyModal}
          onClose={() => setShowEditPropertyModal(false)}
          propertyId={propertyId}
        />
      )}
      <Card
        title="Client details"
        icon={<Building2 className="text-orange-500" />}
        className="flex flex-col gap-3 sm:flex-row"
      >
        <FormField
          name="fsPropertyId"
          label="Client property"
          suffix={
            propertyId && (
              <Button
                size="xs"
                variant="primaryOutline"
                onClick={() => setShowEditPropertyModal(true)}
              >
                Edit
              </Button>
            )
          }
          className="w-full sm:w-2/5"
        >
          <PropertySelector
            onSelect={(v) => setClientId(v?.clientId)}
            defaultClientId={defaultClientId}
          />
        </FormField>
        <ContactInput
          disabled={!propertyId}
          placeholder="Select contact"
          propertyId={propertyId}
          clientId={clientId}
          name="contactPersonId"
          label="Contact person"
          className="w-full sm:w-2/5"
          allowClear
          onAddModalClose={(id) => {
            id && form.setValue("contactPersonId", id);
          }}
        />
        <ContactInput
          disabled={!propertyId}
          placeholder="Select contact"
          propertyId={propertyId}
          clientId={clientId}
          name="siteContactPersonId"
          label="Site contact person"
          className="w-full sm:w-2/5"
          allowClear
          onAddModalClose={(id) => {
            id && form.setValue("siteContactPersonId", id);
          }}
        />
      </Card>
      {!edit && (
        <div className="flex mt-3 w-full">
          <div
            onClick={() => {
              form.setValue("type", "ONEOFF");
              resetScheduleSettings();
            }}
            className={cn(
              "flex items-center justify-center gap-2 p-2 border rounded-l-lg  cursor-pointer bg-primary-800 text-white",
              jobType !== "ONEOFF" && "bg-white text-black"
            )}
          >
            <Hammer className="w-5 h-5" />
            <p className="!text-sm sm:text-base">One-Off Job</p>
          </div>
          <div
            onClick={() => {
              form.setValue("type", "CONTRACT");
              resetScheduleSettings();
            }}
            className={cn(
              "flex items-center justify-center rounded-r-lg gap-2 p-3 border cursor-pointer bg-primary-800 text-white",
              jobType !== "CONTRACT" && "bg-white text-black"
            )}
          >
            <CalendarDays className="w-5 h-5" />
            <p className="!text-sm font-medium sm:text-base">Contract Job</p>
          </div>
        </div>
      )}
      <div className="flex flex-col gap-3 mt-2 w-full sm:flex-row">
        <div className="w-full sm:w-1/3">
          <Card
            title="Schedules"
            icon={<CalendarDays className="text-red-500" />}
          >
            <div className="space-y-3">
              <div className="grid grid-cols-2 gap-2">
                <FormField
                  name="startDate"
                  label={
                    jobType === "ONEOFF" ? "Start date" : "Contract start date"
                  }
                >
                  <DatePicker className="flex" />
                </FormField>
                <FormField
                  name="endDate"
                  label={
                    jobType === "ONEOFF" ? "End date" : "Contract end date"
                  }
                >
                  <DatePicker
                    className="flex"
                    allowClear
                    disabled={!jobStartDate}
                    fromDate={jobStartDate}
                    presets={[
                      {
                        label: "1 year",
                        value: dayjs(jobStartDate).add(364, "day").toDate(),
                      },
                      {
                        label: "6 months",
                        value: dayjs(jobStartDate).add(6, "months").toDate(),
                      },
                      {
                        label: "3 months",
                        value: dayjs(jobStartDate).add(3, "months").toDate(),
                      },
                    ]}
                  />
                </FormField>
              </div>

              <div className="grid grid-cols-2 gap-2">
                <FormField name="startTime" label="Start time">
                  <TimePicker
                    onChange={(v) => {
                      if (
                        !form.getValues("endTime") ||
                        dayjs(form.getValues("endTime")).isBefore(dayjs(v))
                      ) {
                        form.setValue(
                          "endTime",
                          dayjs(v).add(1, "hour").toDate()
                        );
                      }
                    }}
                  />
                </FormField>
                <FormField
                  name="endTime"
                  label="End time"
                  suffix={
                    jobStartTime && (
                      <DropMenu
                        items={[
                          {
                            label: "1h",
                            onClick: () => {
                              form.setValue(
                                "endTime",
                                dayjs(jobStartTime).add(1, "hour").toDate()
                              );
                            },
                          },
                          {
                            label: "2h",
                            onClick: () => {
                              form.setValue(
                                "endTime",
                                dayjs(jobStartTime).add(2, "hour").toDate()
                              );
                            },
                          },
                          {
                            label: "2h 30m",
                            onClick: () => {
                              form.setValue(
                                "endTime",
                                dayjs(jobStartTime)
                                  .add(2, "hour")
                                  .add(30, "minute")
                                  .toDate()
                              );
                            },
                          },
                          {
                            label: "3h",
                            onClick: () => {
                              form.setValue(
                                "endTime",
                                dayjs(jobStartTime).add(3, "hour").toDate()
                              );
                            },
                          },
                        ]}
                      >
                        <Button size="xs" variant="primaryOutline">
                          Select
                        </Button>
                      </DropMenu>
                    )
                  }
                >
                  <TimePicker disabled={!jobStartTime} />
                </FormField>
              </div>

              <div className="flex gap-2">
                {showSchedulesInfo && (
                  <FormField name="frequencyRule" label="Frequency rule">
                    <FrequencySelector />
                  </FormField>
                )}
                {jobType === "CONTRACT" && (
                  <FormField name="paymentsCount" label="Payments count">
                    <Input
                      placeholder="Total number of payments"
                      onChange={(e) => {
                        if (!edit && Number(e.target.value) > 0) {
                          const updated = addContractInvoiceRequired(
                            schedules,
                            Number(e.target.value)
                          );
                          form.setValue("fsSchedules", updated);
                        }
                      }}
                    />
                  </FormField>
                )}
              </div>
              {showSchedulesInfo && (
                <FormField name="fsSchedules">
                  <SchedulesInput form={form} edit={edit} />
                </FormField>
              )}

              {!edit && (
                <MultipleFieldStaffInput
                  name="fsScheduleAssignees"
                  startTime={
                    jobStartTime
                      ? joinDateAndTime(jobStartDate, jobStartTime)
                      : undefined
                  }
                  endTime={
                    jobEndTime
                      ? joinDateAndTime(jobEndDate || jobStartDate, jobEndTime)
                      : undefined
                  }
                />
              )}

              <FormField name="fsJobTags" label="Tags">
                <MultiSelect
                  icon={Tag}
                  placeholder="Add tags"
                  options={tags?.map((tag) => ({
                    label: tag.name,
                    value: tag.id,
                    color: tag.color,
                  }))}
                />
              </FormField>
            </div>
          </Card>
          <Card
            title="Other"
            icon={<Info className="text-red-500" />}
            parentClassName="mt-3"
          >
            <div className="space-y-3">
              <div className="grid gap-2 sm:grid-cols-2">
                <FormField name="paymentMethodId" label="Payment method">
                  <Select
                    placeholder="Cash, Bank..."
                    allowClear
                    options={paymentMethods?.map((pm) => ({
                      label: pm.name,
                      value: pm.id,
                    }))}
                  />
                </FormField>
                <UserInput
                  name="salesPersonId"
                  label="Sales person"
                  placeholder="Select sales person"
                  allowClear
                  type={["ADMIN", "OWNER", "STAFF", "SUPER_ADMIN"]}
                />
              </div>

              <div className="grid grid-cols-5 gap-2">
                <VendorInput
                  allowClear
                  name="vendorId"
                  label="Vendor"
                  className="col-span-3"
                />
                <FormField
                  name="vendorCharge"
                  label="Charge"
                  className="col-span-2"
                >
                  <Input
                    type="number"
                    placeholder="0.00"
                    prefix="AED"
                    disabled={!vendorId}
                  />
                </FormField>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <FormField name="lpoNumber" label="LPO Number">
                  <Input placeholder="LPO Number" />
                </FormField>
                <FormField name="files.fs_job_lpo" label="LPO File">
                  <FilePicker
                    label="Choose file"
                    className="w-full"
                    accept="application/pdf"
                  />
                </FormField>
              </div>
              <FormField name="fsSchedulePricingType" label="Pricing type">
                <Select
                  icon={<DollarSign className="w-5 h-5" />}
                  placeholder="Select pricing type"
                  options={[
                    {
                      label: "Schedule based",
                      value: "SCHEDULE",
                    },
                    {
                      label: "Staff based",
                      value: "FIELD_STAFF",
                    },
                  ]}
                />
              </FormField>
              <FormField name="fsJobQuotations" label="Quotations">
                <MultiSelect
                  icon={ScrollText}
                  placeholder="Linked quotations"
                  options={quotations?.quotations?.map((q) => ({
                    label: `${q.number} - ${q.subject || "No subject"}`,
                    value: q.id,
                  }))}
                />
              </FormField>
            </div>
          </Card>
        </div>
        <div className="flex flex-col gap-2 w-full sm:w-2/3">
          <Card title="Line Items" icon={<Hammer className="text-green-500" />}>
            <FormField name="fsJobServices" label="" className="w-full">
              <LineItemSelector isEdit={edit} required isFsJob />
            </FormField>
            <div className="flex justify-end w-full">
              <table className="gap-8 text-base border-separate border-spacing-y-2">
                <tr>
                  <td className="w-52">Subtotal</td>
                  <td className="text-right">
                    {formatCurrency(lineItemsValues.subTotal, "AED")}
                  </td>
                </tr>
                <tr>
                  <td className="flex gap-2 items-center w-52">VAT</td>
                  <td className="text-right">
                    {formatCurrency(lineItemsValues.totalTax, "AED")}
                  </td>
                </tr>
                <tr>
                  <td className="w-52">Discount</td>
                  <td className="text-right">
                    <FormField name="discount">
                      <Input suffix="AED" className="w-[70px]" />
                    </FormField>
                  </td>
                </tr>
                <tr>
                  <td className="w-52">Total</td>
                  <td className="font-semibold text-right">
                    {formatCurrency(lineItemsValues.total, "AED")}
                  </td>
                </tr>
              </table>
            </div>
          </Card>
          <Card
            title="More info"
            icon={<Info className="text-blue-500" />}
            className="flex flex-col gap-3 sm:flex-row"
          >
            <FormField
              name="instructions"
              label="Instructions for field workers"
            >
              <MiniRichTextEditor
                placeholder="Describe the job, add notes etc.."
                height={170}
              />
            </FormField>
            <FormField name="internalNotes" label="Notes for office">
              <MiniRichTextEditor
                placeholder="Note down important information, or comments..."
                height={170}
              />
            </FormField>
          </Card>
        </div>
      </div>
    </>
  );
};

export default JobForm;
