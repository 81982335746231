import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import useNav from "@/stores/useNav";
import enums from "@heffl/server/src/schemas/enums";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { cn } from "@heffl/ui/lib/utils";
import {
  BadgeDollarSign,
  Bolt,
  BookText,
  FileCode,
  Folder,
  KanbanSquare,
  LucideIcon,
  ShoppingBag,
  TextCursorInput,
  Users,
  UsersRound,
  Wrench,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

export type Apps = {
  name: string;
  icon: LucideIcon;
  path: string;
  color: string;
  id: z.infer<typeof enums.appKeys>;
  description: string;
}[];

export const apps: Apps = [
  {
    name: "CRM",
    icon: Users,
    path: "/crm/dashboard",
    color: "#16a34a", // green-600
    id: "CRM",
    description:
      "Manage customer relationships effectively. Track leads, contacts, and interactions. Streamline sales processes, analyze customer data, and improve client satisfaction. Boost your business growth with powerful CRM tools.",
  },
  {
    name: "Sales",
    icon: BadgeDollarSign,
    path: "/sales/dashboard",
    color: "#3b82f6", // blue-500
    id: "SALES",
    description:
      "Optimize your sales operations. Monitor sales performance, track deals, and manage pipelines. Generate quotes, invoices, and reports. Increase revenue and streamline your sales process with comprehensive sales management tools.",
  },
  {
    name: "Purchases",
    icon: ShoppingBag,
    path: "/purchases/purchase-orders",
    color: "#10B981",
    id: "PURCHASES",
    description:
      "Efficiently manage your procurement processes. Track purchase orders, manage vendors, and monitor inventory levels. Streamline your supply chain, reduce costs, and ensure timely deliveries with our purchase management system.",
  },
  {
    name: "Books",
    icon: BookText,
    path: "/books/dashboard",
    color: "#6366f1", // indigo-500
    id: "BOOKS",
    description:
      "Manage your financial records efficiently. Track income and expenses, generate financial reports, and monitor cash flow. Simplify bookkeeping, ensure accurate financial data, and make informed business decisions with our comprehensive accounting tools.",
  },
  {
    name: "Projects",
    icon: KanbanSquare,
    path: "/projects/dashboard",
    color: "#8b5cf6", // violet-500
    id: "PROJECTS",
    description:
      "Organize and track your projects effectively. Plan tasks, allocate resources, and monitor progress. Collaborate with team members, set milestones, and generate project reports. Boost productivity and ensure project success.",
  },
  {
    name: "Files",
    icon: Folder,
    path: "/files",
    color: "#ef4444", // red-500
    id: "FILES",
    description:
      "Centralize your document management. Store, organize, and share files securely. Collaborate on documents, control versions, and access files from anywhere. Improve team productivity with efficient file management tools.",
  },
  {
    name: "Forms",
    icon: TextCursorInput,
    path: "/forms",
    color: "#22c55e", // green-500
    id: "FORMS",
    description:
      "Create and manage forms for various business processes. Customize form fields, set up workflows, and collect data efficiently. Streamline your data collection and analysis with our versatile form builder.",
  },
  // {
  //   name: "Contacts",
  //   icon: Contact,
  //   path: "/contacts",
  //   color: "#22c55e", // green-500
  //   id: "CONTACTS",
  // },
  {
    name: "Service",
    icon: Wrench,
    path: "/field-service/dashboard",
    color: "#f97316", // orange-500
    id: "FIELD_SERVICE",
    description:
      "Streamline your field service operations. Schedule and dispatch technicians, track service requests, and manage equipment maintenance. Improve customer satisfaction and optimize your service delivery with our field service management tools.",
  },
  {
    name: "Employees",
    icon: UsersRound,
    path: "/employees/list",
    color: "#3b82f6", // blue-500
    id: "EMPLOYEES",
    description:
      "Manage your workforce efficiently. Track employee information, monitor performance, and handle payroll. Streamline HR processes, manage time-off requests, and ensure compliance. Empower your team with comprehensive employee management tools.",
  },
  {
    name: "Templates",
    icon: FileCode,
    path: "/templates",
    color: "#eab308", // yellow-500
    id: "TEMPLATES",
    description:
      "Create and manage reusable templates for various business documents. Standardize your communication, streamline document creation, and ensure consistency across your organization. Save time and improve efficiency with customizable templates.",
  },
  // {
  //   name: "Assistant",
  //   icon: Bot,
  //   path: "/ai-assistant",
  //   color: "#06b6d4", // cyan-500
  //   id: "AI_ASSISTANT",
  // },
  {
    name: "Settings",
    icon: Bolt,
    path: "/settings/profile",
    color: "#525252", // gray-600
    id: "SETTINGS",
    description:
      "Configure and customize your workspace. Manage user preferences, set up integrations, and control system-wide settings. Ensure your workspace is tailored to your organization's needs and optimize your workflow efficiency.",
  },
];

const defaultAppsNames = ["SETTINGS", "FILES", "TEMPLATES", "EMPLOYEES"];

export const defaultApps = apps.filter((app) =>
  defaultAppsNames.includes(app.id)
);

const AppsList = () => {
  const { setSelectedPage } = useNav();

  const { data: currentUser } = trpc.users.currentUser.useQuery();

  const navigate = useNavigate();

  if (!currentUser) return <FullScreenSpinner />;

  return (
    <Page
      fullWidth
      className="h-full bg-green-100"
      style={{
        backgroundImage: "linear-gradient(to top, #fff1eb 0%, #ace0f9 100%)",
      }}
      pageTitleParentClassName="bg-[#ACDFF9]"
    >
      <div className="grid grid-cols-3 gap-y-5 px-6 mx-auto max-w-screen-md sm:pt-20 sm:gap-4 sm:grid-cols-4">
        {apps
          .filter(
            (app) =>
              app.id !== "EMPLOYEES" || currentUser.type === "SUPER_ADMIN"
          )
          .map((app) => (
            <div
              className="flex flex-col gap-2 justify-center items-center font-medium text-gray-700 cursor-pointer sm:gap-3"
              key={app.name}
              onClick={() => {
                console.log(app.path.split("/").pop(), "selected path");
                setSelectedPage(app.path.split("/").pop() || "");
                navigate(app.path);
              }}
            >
              <div className="p-6 w-20 h-20 bg-white rounded-lg shadow-md transition-all duration-100 sm:w-24 sm:h-24 hover:scale-110 hover:shadow-lg sm:rounded-xl">
                <app.icon
                  className={cn(
                    "w-full h-full hover:rotate-12 transition-all  duration-75",
                    `text-${app.color}`
                  )}
                />
              </div>
              <p>{app.name}</p>
            </div>
          ))}
      </div>
    </Page>
  );
};

export default AppsList;
