// import FilterBar from "@/components/filters";
import Page from "@/components/page";
import { RouterInputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@heffl/ui/components/primitives/tabs";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CheckCircle, Clock, Pause, PlayCircle } from "lucide-react";
import { CompanyLogo } from "@/pages/crm/deals/components/dealCard";
import enums from "@heffl/server/src/schemas/enums";
import { z } from "zod";
import { cn, dynamicDateFormatting } from "@heffl/ui/lib/utils";
import { taskDateColor } from "../details/components/kanban/TaskCard";

type Filters = {
  dates: [Date, Date] | undefined;
};

type TaskFilter = {
  pipelineIds: number[];
  statuses: z.infer<typeof enums.projectTaskStatusTypes>[];
  clients: number[];
  tags: number[];
  assignees: number[];
  dates: [Date, Date] | undefined;
};

const NewProjectDashboard = () => {
  const [tasksCompleted, setTasksCompleted] = useState(0);
  const [tasksPending, setTasksPending] = useState(0);

  const [myTasks, setMyTasks] = useState<typeof tasksQuery.data>([]);
  const [tasksIAssigned, setTasksIAssigned] = useState<typeof tasksQuery.data>(
    []
  );

  const [filters] = useParamsState<Filters>({
    dates: [dayjs().subtract(30, "day").toDate(), dayjs().toDate()],
  });

  const { data: currentUser } = trpc.users.currentUser.useQuery();

  const { data } = trpc.projects.dashboard.personal.useQuery({
    startDate: filters.dates?.[0],
    endDate: filters.dates?.[1],
  });

  //   TASK QUERY

  const [taskFilters, setTaskFilters] = useParamsState<TaskFilter>({
    pipelineIds: [],
    clients: [],
    tags: [],
    assignees: [],
    statuses: [],
    dates: undefined,
  });

  const tasksParams: RouterInputs["projects"]["tasks"]["list"] = {
    pipelineIds: taskFilters.pipelineIds,
    clients: taskFilters.clients,
    tags: taskFilters.tags,
    assignees: taskFilters.assignees,
    orderBy: "createdAt",
    statuses: taskFilters.statuses,
    dates: taskFilters.dates,
  };

  const tasksQuery = trpc.projects.tasks.list.useQuery(tasksParams);
  console.log("TASKS", tasksQuery.data);

  const navigate = useNavigate();

  const [timeFilter, setTimeFilter] = useState<"today" | "week" | "month">(
    "today"
  );

  useEffect(() => {
    setTaskFilters((prev) => ({
      ...prev,
      dates: [
        timeFilter === "today"
          ? dayjs().startOf("day").toDate()
          : timeFilter === "week"
          ? dayjs().startOf("week").toDate()
          : dayjs().startOf("month").toDate(),

        timeFilter === "today"
          ? dayjs().endOf("day").toDate()
          : timeFilter === "week"
          ? dayjs().endOf("week").toDate()
          : dayjs().endOf("month").toDate(),
      ],
    }));
  }, [timeFilter]);

  useEffect(() => {
    if (tasksQuery.data) {
      const completed = tasksQuery.data.filter(
        (task) => task.status === "COMPLETED"
      ).length;
      const pending = tasksQuery.data.filter((task) =>
        ["ON_HOLD", "IN_PROGRESS"].includes(task.status)
      ).length;

      setTasksCompleted(completed);
      setTasksPending(pending);
    }
  }, [tasksQuery.data]);

  useEffect(() => {
    if (tasksQuery.data && currentUser) {
      // Tasks assigned to me
      const assignedToMe = tasksQuery.data.filter((task) =>
        task?.projectTaskAssignees?.some(
          (assignee) => assignee.userId === currentUser.id
        )
      );

      // Tasks I created/assigned
      const createdByMe = tasksQuery.data.filter(
        (task) => task.createdById === currentUser.id
      );

      setMyTasks(assignedToMe);
      setTasksIAssigned(createdByMe);
      console.log("Assigned to me:", assignedToMe); // Add this debug log
    }
  }, [tasksQuery.data, currentUser]);

  if (!data || !currentUser) return <FullScreenSpinner />;
  return (
    <Page fullWidth className="bg-[#F7F8FA]">
      {["ADMIN", "SUPER_ADMIN"].includes(currentUser.type) && (
        <Tabs value="new" className="mb-3 w-fit">
          <TabsList className="grid grid-cols-3 w-full">
            <TabsTrigger
              value="personal"
              onClick={() => navigate("/projects/dashboard")}
            >
              Personal
            </TabsTrigger>
            <TabsTrigger
              value="admin"
              onClick={() => navigate("/projects/admin-dashboard")}
            >
              Admin
            </TabsTrigger>
            <TabsTrigger
              value="new"
              onClick={() => navigate("/projects/new-dashboard")}
            >
              New
            </TabsTrigger>
          </TabsList>
        </Tabs>
      )}
      {/* <FilterBar
        className=""
        filters={[
          {
            key: "date",
            type: "date-range",
            label: "Date",
            value: filters.dates,
            onChange: (value) =>
              setFilters({
                dates: value,
              }),
          },
        ]}
      /> */}
      <div className="mt-8 space-y-4">
        <div className="flex flex-col gap-3 justify-center items-center w-full">
          <p className="font-normal lg:text-lg">
            {dayjs().format("dddd, MMMM D")}
          </p>
          <h5 className="text-2xl font-semibold lg:text-3xl">
            {dayjs().hour() < 12
              ? "Good morning"
              : dayjs().hour() < 17
              ? "Good afternoon"
              : "Good evening"}
            , {currentUser.firstName}
          </h5>
          <div className="flex flex-col gap-4 justify-center items-center px-8 py-6 mt-4 bg-white rounded-2xl lg:flex-row lg:gap-8 lg:py-3 lg:rounded-full">
            <div className="flex gap-2 items-center">
              <select
                value={timeFilter}
                onChange={(e) =>
                  setTimeFilter(e.target.value as "today" | "week" | "month")
                }
                className="bg-transparent cursor-pointer outline-none"
              >
                <option value="today">Today</option>
                <option value="week">This Week</option>
                <option value="month">This Month</option>
              </select>
              {/* <span className="text-lg">↓</span> */}
            </div>
            <div className="flex gap-6 items-center">
              <div className="flex gap-2 items-center">
                <span className="text-xl font-semibold">{tasksCompleted}</span>
                <span>Tasks Completed</span>
              </div>
              <div className="flex gap-2 items-center">
                <span className="text-xl font-semibold">{tasksPending}</span>
                <span>Tasks Pending</span>
              </div>
            </div>
          </div>

          {/* CARDS👇 */}

          <div className="grid grid-cols-1 gap-6 mt-8 w-full lg:grid-cols-2">
            {/* MY TASKS👇 */}
            <div className="bg-white border border-[#E6EDFf] flex flex-col items-start min-h-64 rounded-xl">
              <h1 className="text-lg font-medium text-gray-800 px-5 border-b border-[#E6EDFf] pb-3 w-full py-3">
                My Tasks
              </h1>
              <Tabs defaultValue="pending" className="px-5 py-4 w-full">
                <TabsList className="grid grid-cols-3 w-fit">
                  <TabsTrigger value="pending">Pending</TabsTrigger>
                  <TabsTrigger value="upcoming">Upcoming</TabsTrigger>
                  <TabsTrigger value="completed">Completed</TabsTrigger>
                </TabsList>

                <TabsContent value="pending">
                  <div className="overflow-auto">
                    <table className="mt-4 w-full">
                      <thead className="border-y border-[#E6EDFf] text-left text-gray-500 ">
                        <tr>
                          <th className="p-3 font-medium tracking-wide border-r border-[#E6EDFf] whitespace-nowrap">
                            Title
                          </th>
                          <th className="lg:w-40 p-3 font-medium tracking-wide border-r border-[#E6EDFf] whitespace-nowrap">
                            Status
                          </th>
                          <th className="p-3 font-medium tracking-wide whitespace-nowrap lg:w-40">
                            Due Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {console.log("Rendering tasks:", myTasks)} */}
                        {myTasks
                          ?.filter((task) =>
                            ["ON_HOLD", "IN_PROGRESS"].includes(task.status)
                          )
                          .map((task) => (
                            <tr
                              className="border-b border-[#E6EDFf]"
                              key={task.id}
                            >
                              <td className="p-3  whitespace-nowrap border-r border-[#E6EDFf]">
                                {task.title}
                              </td>
                              <td className="p-3  whitespace-nowrap border-r border-[#E6EDFf]">
                                <span
                                  className={cn(
                                    "text-xs rounded tracking-wide px-2 py-1 flex items-center gap-1 w-fit",
                                    {
                                      "bg-yellow-200 bg-opacity-40 text-yellow-500":
                                        task.status === "IN_PROGRESS",
                                      "bg-red-200 bg-opacity-40 text-red-500":
                                        task.status === "ON_HOLD",
                                    }
                                  )}
                                >
                                  {task.status === "IN_PROGRESS" ? (
                                    <>
                                      <PlayCircle size={14} /> In Progress
                                    </>
                                  ) : task.status === "ON_HOLD" ? (
                                    <>
                                      <Pause size={14} /> On Hold
                                    </>
                                  ) : (
                                    task.status
                                  )}
                                </span>
                              </td>
                              <td className="p-3 whitespace-nowrap">
                                {task.date && (
                                  <p
                                    className={cn("", taskDateColor(task.date))}
                                  >
                                    {dynamicDateFormatting(task.date, true)}
                                  </p>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </TabsContent>
                <TabsContent value="upcoming">
                  <div className="overflow-auto">
                    <table className="mt-4 w-full">
                      <thead className="border-y border-[#E6EDFf] text-left text-gray-500 ">
                        <tr>
                          <th className=" p-3 font-medium tracking-wide border-r border-[#E6EDFf] whitespace-nowrap">
                            Title
                          </th>
                          <th className="lg:w-40 p-3 font-medium tracking-wide border-r border-[#E6EDFf] whitespace-nowrap">
                            Status
                          </th>
                          <th className="p-3 font-medium tracking-wide whitespace-nowrap lg:w-40">
                            Due Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {myTasks
                          ?.filter((task) => task.status === "OPEN")
                          .map((task) => (
                            <tr
                              className="border-b border-[#E6EDFf]"
                              key={task.id}
                            >
                              <td className="p-3  whitespace-nowrap border-r border-[#E6EDFf]">
                                {task.title}
                              </td>
                              <td className="p-3  whitespace-nowrap border-r border-[#E6EDFf]">
                                <span className="flex gap-1 items-center px-2 py-1 text-xs tracking-wide text-blue-500 bg-blue-200 bg-opacity-40 rounded w-fit">
                                  <Clock size={14} /> Open
                                </span>
                              </td>
                              <td className="p-3 whitespace-nowrap">
                                {task.date && (
                                  <p
                                    className={cn("", taskDateColor(task.date))}
                                  >
                                    {dynamicDateFormatting(task.date, true)}
                                  </p>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </TabsContent>
                <TabsContent value="completed">
                  <div className="overflow-auto">
                    <table className="mt-4 w-full">
                      <thead className="border-y border-[#E6EDFf] text-left text-gray-500 ">
                        <tr>
                          <th className="p-3 font-medium tracking-wide border-r border-[#E6EDFf] whitespace-nowrap">
                            Title
                          </th>
                          <th className="lg:w-40 p-3 font-medium tracking-wide border-r border-[#E6EDFf] whitespace-nowrap">
                            Status
                          </th>
                          <th className="p-3 font-medium tracking-wide whitespace-nowrap lg:w-40">
                            Due Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {myTasks
                          ?.filter((task) => task.status === "COMPLETED")
                          .map((task) => (
                            <tr
                              className="border-b border-[#E6EDFf]"
                              key={task.id}
                            >
                              <td className="p-3 whitespace-nowrap border-r border-[#E6EDFf]">
                                {task.title}
                              </td>
                              <td className="p-3 whitespace-nowrap border-r border-[#E6EDFf]">
                                <span className="flex gap-1 items-center px-2 py-1 text-xs tracking-wide text-green-500 bg-green-200 bg-opacity-40 rounded w-fit">
                                  <CheckCircle size={14} /> Completed
                                </span>
                              </td>
                              <td className="p-3 whitespace-nowrap">
                                {task.date && (
                                  <p
                                    className={cn("", taskDateColor(task.date))}
                                  >
                                    {dynamicDateFormatting(task.date, true)}
                                  </p>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </TabsContent>
              </Tabs>
            </div>

            {/* NOTES👇 */}

            <div className="items-start w-full bg-white rounded-xl">
              <h1 className="text-lg font-medium text-gray-800 px-5 border-b border-[#E6EDFf] pb-3 w-full py-3">
                Notes
              </h1>
              <div className="px-5 py-4 w-full">
                <MiniRichTextEditor height={200} />
              </div>
            </div>

            {/* TASKS I ASSIGNED👇 */}
            <div className="flex flex-col items-start bg-white rounded-xl">
              <h1 className="text-lg font-medium text-gray-800 px-5 border-b border-[#E6EDFf] pb-3 w-full py-3">
                Tasks I assigned
              </h1>
              {/* <StripeTabs items={["Pending", "Upcoming"]} /> */}
              <Tabs defaultValue="pending" className="px-5 py-4 w-full">
                <TabsList className="grid grid-cols-2 w-fit">
                  <TabsTrigger value="pending">Pending</TabsTrigger>
                  <TabsTrigger value="upcoming">Upcoming</TabsTrigger>
                </TabsList>
                <TabsContent value="pending">
                  <div className="overflow-auto">
                    <table className="mt-4 w-full">
                      <thead className="border-y border-[#E6EDFf] text-left text-gray-500 ">
                        <tr>
                          <th className="p-3 font-medium tracking-wide border-r border-[#E6EDFf] whitespace-nowrap">
                            Title
                          </th>
                          <th className="lg:w-40 p-3 font-medium tracking-wide border-r border-[#E6EDFf] whitespace-nowrap">
                            Status
                          </th>
                          <th className="p-3 font-medium tracking-wide whitespace-nowrap lg:w-40">
                            Due Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tasksIAssigned
                          ?.filter((task) =>
                            ["ON_HOLD", "IN_PROGRESS"].includes(task.status)
                          )
                          .map((task) => (
                            <tr
                              className="border-b border-[#E6EDFf]"
                              key={task.id}
                            >
                              <td className="p-3  whitespace-nowrap border-r border-[#E6EDFf]">
                                {task.title}
                              </td>
                              <td className="p-3  whitespace-nowrap border-r border-[#E6EDFf]">
                                <span
                                  className={cn(
                                    "text-xs rounded tracking-wide px-2 py-1 flex items-center gap-1 w-fit",
                                    {
                                      "bg-yellow-200 bg-opacity-40 text-yellow-500":
                                        task.status === "IN_PROGRESS",
                                      "bg-red-200 bg-opacity-40 text-red-500":
                                        task.status === "ON_HOLD",
                                    }
                                  )}
                                >
                                  {task.status === "IN_PROGRESS" ? (
                                    <>
                                      <PlayCircle size={14} /> In Progress
                                    </>
                                  ) : task.status === "ON_HOLD" ? (
                                    <>
                                      <Pause size={14} /> On Hold
                                    </>
                                  ) : (
                                    task.status
                                  )}
                                </span>
                              </td>
                              <td className="p-3 whitespace-nowrap">
                                {task.date && (
                                  <p
                                    className={cn("", taskDateColor(task.date))}
                                  >
                                    {dynamicDateFormatting(task.date, true)}
                                  </p>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </TabsContent>
                <TabsContent value="upcoming">
                  <div className="overflow-auto">
                    <table className="mt-4 w-full">
                      <thead className="border-y border-[#E6EDFf] text-left text-gray-500 ">
                        <tr>
                          <th className="p-3 font-medium tracking-wide border-r border-[#E6EDFf] whitespace-nowrap">
                            Title
                          </th>
                          <th className="lg:w-40 p-3 font-medium tracking-wide border-r border-[#E6EDFf] whitespace-nowrap">
                            Status
                          </th>
                          <th className="p-3 font-medium tracking-wide whitespace-nowrap lg:w-40">
                            Due Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tasksIAssigned
                          ?.filter((task) => task.status === "OPEN")
                          .map((task) => (
                            <tr
                              className="border-b border-[#E6EDFf]"
                              key={task.id}
                            >
                              <td className="p-3  whitespace-nowrap border-r border-[#E6EDFf]">
                                {task.title}
                              </td>
                              <td className="p-3  whitespace-nowrap border-r border-[#E6EDFf]">
                                <span className="flex gap-1 items-center px-2 py-1 text-xs tracking-wide text-blue-500 bg-blue-200 bg-opacity-40 rounded w-fit">
                                  <Clock size={14} /> Open
                                </span>
                              </td>
                              <td className="p-3 whitespace-nowrap">
                                {task.date && (
                                  <p
                                    className={cn("", taskDateColor(task.date))}
                                  >
                                    {dynamicDateFormatting(task.date, true)}
                                  </p>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </TabsContent>
              </Tabs>
            </div>

            {/* MY PROJECTS👇 */}

            <div className="flex flex-col items-start bg-white border border-[#E6EDFf] rounded-xl ">
              <h1 className="text-lg font-medium text-gray-800 px-5 border-b border-[#E6EDFf] pb-3 w-full py-3">
                My Projects
              </h1>
              {/* new project card */}
              <div className="grid grid-cols-1 gap-4 px-5 py-4 w-full lg:grid-cols-2">
                {data?.pendingProjects?.map((project) => (
                  <div
                    className="flex items-center gap-3 px-4 py-5 bg-slate-50 border border-[#E6EDFf] border-opacity-40 rounded-lg"
                    key={project.id}
                  >
                    <CompanyLogo company={{ name: project.prefix }} />
                    <div className="flex flex-col gap-1">
                      <h3 className="font-semibold text-[#333] ">
                        {project.title}
                      </h3>
                      <h3 className="text-gray-600">
                        {project?.clients?.name}
                      </h3>
                      <p className="font-medium text-red-400 opacity-90">
                        10/10/2024
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* MY TIMESHEETS👇 */}
            <div className="lg:col-span-2 bg-white border border-[#E6EDFf] flex flex-col items-start min-h-64 rounded-xl">
              <h1 className="text-lg font-medium text-gray-800 px-5  pb-3 w-full border-b border-[#E6EDFf] py-3">
                My Timesheets
              </h1>
              <div className="overflow-auto px-5 pt-3 pb-5 w-full">
                <table className="mt-4 w-full">
                  <thead className="border-y border-[#E6EDFf] text-left text-gray-500 ">
                    <tr>
                      <th className="p-3 font-medium tracking-wide border-r border-[#E6EDFf] whitespace-nowrap">
                        Project
                      </th>
                      <th className="p-3 font-medium tracking-wide border-r border-[#E6EDFf] whitespace-nowrap">
                        Task
                      </th>
                      <th className="p-3 font-medium tracking-wide border-r border-[#E6EDFf] whitespace-nowrap">
                        Status
                      </th>
                      <th className="p-3 font-medium tracking-wide whitespace-nowrap">
                        Due Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tasksQuery.data?.map((task) => (
                      <tr className="border-b border-[#E6EDFf]" key={task.id}>
                        <td className="p-3  whitespace-nowrap border-r border-[#E6EDFf]">
                          {task?.projects?.title}
                        </td>
                        <td className="p-3  whitespace-nowrap border-r border-[#E6EDFf]">
                          {task.title}
                        </td>
                        <td className="p-3  whitespace-nowrap border-r border-[#E6EDFf]">
                          <span
                            className={cn(
                              "text-xs rounded tracking-wide px-2 py-1 flex items-center gap-1 w-fit",
                              {
                                "bg-blue-200 bg-opacity-40 text-blue-500":
                                  task.status === "OPEN",
                                "bg-yellow-200 bg-opacity-40 text-yellow-500":
                                  task.status === "IN_PROGRESS",
                                "bg-red-200 bg-opacity-40 text-red-500":
                                  task.status === "ON_HOLD",
                                "bg-green-200 bg-opacity-40 text-green-500":
                                  task.status === "COMPLETED",
                              }
                            )}
                          >
                            {task.status === "OPEN" ? (
                              <>
                                <Clock size={14} /> Open
                              </>
                            ) : task.status === "IN_PROGRESS" ? (
                              <>
                                <PlayCircle size={14} /> In Progress
                              </>
                            ) : task.status === "ON_HOLD" ? (
                              <>
                                <Pause size={14} /> On Hold
                              </>
                            ) : task.status === "COMPLETED" ? (
                              <>
                                <CheckCircle size={14} /> Completed
                              </>
                            ) : (
                              task.status
                            )}
                          </span>
                        </td>
                        <td className="p-3 whitespace-nowrap">
                          {task.date && (
                            <p className={cn("", taskDateColor(task.date))}>
                              {dynamicDateFormatting(task.date, true)}
                            </p>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default NewProjectDashboard;
