import { cn } from "@heffl/ui/lib/utils";
import { LucideIcon, MoreVertical, Search } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "./primitives/dropdown-menu";

type Column<T> = {
  label: string;
  key?: keyof T;
  render?: (row: T, index: number) => React.ReactNode;
  icon?: LucideIcon;
  className?: string;
};

type Row<T> = T;

const SimpleTable = <T extends Record<string, any>>({
  columns,
  rows,
  className,
  onRowClick,
  borderless = false,
  idKey,
  actions,
}: {
  columns: Column<T>[];
  rows: Row<T>[];
  idKey: keyof T;
  className?: string;
  onRowClick?: (row: Row<T>) => void;
  borderless?: boolean;
  actions?: (row: T) => (
    | {
        type: "item";
        icon?: LucideIcon;
        label: string;
        onClick: (row: T) => void;
        hidden?: boolean;
        className?: string;
      }
    | {
        type: "separator";
      }
  )[];
}) => {
  return (
    <div className={cn("flex flex-col", className)}>
      <div className="-m-1.5 overflow-x-auto">
        <div className="p-1.5 min-w-full inline-block align-middle">
          <div
            className={cn(
              "overflow-hidden rounded-md",
              !borderless && "border"
            )}
          >
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  {columns.map((column) => (
                    <th key={column.label} scope="col" className="px-6 py-4">
                      <div className="flex gap-1 items-center text-xs text-[#75777c] text-start font-[500]">
                        {column.icon && <column.icon className="w-3.5 h-3.5" />}
                        {column.label}
                      </div>
                    </th>
                  ))}
                  {actions && actions.length > 0 && <th scope="col"></th>}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {rows.map((row, index) => (
                  <tr
                    key={row[idKey]}
                    onClick={() => onRowClick?.(row)}
                    className="cursor-pointer hover:bg-gray-50"
                  >
                    {columns.map((column) => (
                      <td
                        key={column.key as string}
                        className={cn(
                          "px-6 py-4 text-sm text-gray-800 whitespace-nowrap",
                          column.className
                        )}
                      >
                        {column.render
                          ? column.render(row, index)
                          : column.key
                          ? row[column.key]
                          : "Cant access value"}
                      </td>
                    ))}
                    {actions && actions.length > 0 && (
                      <td>
                        <DropdownMenu>
                          <DropdownMenuTrigger>
                            <MoreVertical className="w-4 h-4" />
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            {actions(row)
                              .filter((action) =>
                                "hidden" in action ? !action.hidden : true
                              )
                              .map((action) =>
                                action.type === "item" ? (
                                  <DropdownMenuItem
                                    key={action.label}
                                    onClick={() => action.onClick(row)}
                                  >
                                    {action.icon && (
                                      <action.icon className="mr-2 w-4 h-4" />
                                    )}
                                    {action.label}
                                  </DropdownMenuItem>
                                ) : (
                                  <DropdownMenuSeparator />
                                )
                              )}
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            {rows.length === 0 && (
              <div className="flex flex-col justify-center items-center p-8 w-full">
                <div className="p-4 mb-4 bg-gray-50 rounded-full">
                  <Search className="w-8 h-8 text-gray-400" />
                </div>
                <h3 className="mb-1 font-medium text-gray-900">
                  No data found
                </h3>
                <p className="text-sm text-center text-gray-500">
                  There are no items to display.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleTable;
