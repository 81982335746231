import { formatCurrency, formatName } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { ReasonModal, ReasonModalProps } from "@/lib/reason-modal";
import NiceModal from "@ebay/nice-modal-react";
import toast from "react-hot-toast";
import appIcons from "@heffl/ui/components/appIcons";
import { ConfirmModal, ConfirmModalProps } from "@/lib/confirm-modal";

const modal = {
  reason: (props: ReasonModalProps) =>
    NiceModal.show(ReasonModal, { ...props }),
  confirm: (props: ConfirmModalProps) =>
    NiceModal.show(ConfirmModal, { ...props }),
};
const icons = appIcons;

const format = {
  name: formatName,
  date: (date: Date) => {
    return dayjs(date).format("DD/MM/YYYY");
  },
  dateTime: (date: Date) => {
    return dayjs(date).format("DD/MM/YYYY hh:mm A");
  },
  currency: formatCurrency,
};

const heffl = { format, modal, toast, icons };

export default heffl;
