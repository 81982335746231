import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filters";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { formatCurrency } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { FileText } from "lucide-react";
import React, { useMemo } from "react";

type ProductFilter = {
  dates: [Date, Date] | undefined;
};

const ServiceProductUtilization: React.FC = () => {
  const [filters, setFilters] = useParamsState<ProductFilter>({
    dates: [dayjs().startOf("month").toDate(), dayjs().endOf("month").toDate()],
  });

  const productFilters = useMemo(
    () => ({
      startDate: filters.dates?.[0],
      endDate: filters.dates?.[1],
    }),
    [filters]
  );

  const { data: productUtilization } =
    trpc.fieldService.reports.topProductsUtilized.useQuery(productFilters);

  if (!productUtilization) {
    return <FullScreenSpinner />;
  }

  const totalUsage = productUtilization.reduce(
    (sum, product) => sum + product.usageCount,
    0
  );
  const totalRevenue = productUtilization.reduce(
    (sum, product) => sum + product.totalPrice,
    0
  );

  return (
    <Page title="Service/Product Utilization" fullWidth className="sm:!p-0">
      <div className="flex flex-wrap items-center pt-3 w-full">
        <FilterBar
          className="px-3"
          filters={[
            {
              key: "dates",
              type: "date-range",
              label: "Date",
              value: filters.dates,
              onChange: (value) => setFilters({ dates: value as [Date, Date] }),
            },
          ]}
        />
      </div>

      <div className="grid grid-cols-2 gap-4 p-3 sm:grid-cols-3 lg:grid-cols-6">
        <Card>
          <h3 className="text-xs font-medium text-gray-500">Total Usage</h3>
          <p className="mt-1 text-xl font-semibold text-gray-900">
            {totalUsage}
          </p>
        </Card>
        <Card>
          <h3 className="text-xs font-medium text-gray-500">Total Revenue</h3>
          <p className="mt-1 text-xl font-semibold text-gray-900">
            {formatCurrency(totalRevenue, "AED")}
          </p>
        </Card>
      </div>
      <DataGrid
        rowKey="id"
        name="serviceProductUtilization"
        className="h-[calc(100vh-190px-var(--header-height))] mt-12"
        label="Service/Product Utilization"
        loading={!productUtilization}
        empty={{
          icon: FileText,
          title: "No product data",
          description:
            "There is no product utilization data to display in the report.",
        }}
        rows={productUtilization || []}
        columns={[
          {
            key: "name",
            name: "Product Name",
            width: 200,
          },
          {
            key: "unitPrice",
            name: "Unit Price",
            width: 150,
            renderCell: ({ row }) =>
              formatCurrency(row.price || 0, "AED", true),
          },
          {
            key: "usageCount",
            name: "Usage Count",
            width: 150,
          },
          {
            key: "totalPrice",
            name: "Total Revenue",
            width: 150,
            renderCell: ({ row }) =>
              formatCurrency(row.totalPrice || 0, "AED", true),
          },
          {
            key: "sku",
            name: "SKU",
            width: 150,
          },
          {
            key: "description",
            name: "Description",
            width: 300,
          },
        ]}
      />
    </Page>
  );
};

export default ServiceProductUtilization;
