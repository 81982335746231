import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { ProjectForm } from "./AddProjectDrawer";

const EditProjectDrawer = ({
  open,
  onClose,
  id,
}: {
  open: boolean;
  id: number;
  onClose: () => void;
}) => {
  const form = useForm<z.infer<typeof Schemas.project.project>>({
    resolver: zodResolver(Schemas.project.project),
  });

  const { data: projectDetails } = trpc.projects.details.useQuery(id);
  const projectUpdateMutation = trpc.projects.update.useMutation({
    onSuccess() {
      form.reset({});
      toast.success("Updated project");
      onClose();
    },
  });

  useEffect(() => {
    if (projectDetails)
      form.reset({
        ...projectDetails,
        projectTags: projectDetails.projectTags.map((tag) => tag.tags.id),
        projectAssignees: projectDetails.projectAssignees.map(
          (assignee) => assignee.userId
        ),
        endDate: projectDetails.endDate || undefined,
        projectQuotations: projectDetails.projectQuotations.map(
          (quotation) => quotation.quotations.id
        ),
      });
  }, [projectDetails]);

  const onSubmit = (values: z.infer<typeof Schemas.project.project>) => {
    projectUpdateMutation.mutate({
      id: id,
      project: values,
    });
  };

  if (!projectDetails) return <FullScreenSpinner />;

  return (
    <ModalDrawer
      open={open}
      onClose={() => {
        onClose();
        form.reset({});
      }}
      title="Update project"
      className="w-full sm:w-[450px] h-4/5 sm:h-full "
      footer={
        <Button
          loading={projectUpdateMutation.isLoading}
          onClick={() => form.handleSubmit(onSubmit)()}
          variant="primary"
          className="mt-4 w-full"
        >
          Update project
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <ProjectForm form={form} edit pipelineId={projectDetails.pipelineId} />
      </Form>
    </ModalDrawer>
  );
};

export default EditProjectDrawer;
