import { Entity } from "./recordActivity";

type Label = Record<string, string>;

export type Labels = {
  [key in Entity]: Label;
};
export const activityColumnLabels: Labels = {
  QUOTATION: {
    quotationProducts: "Products",
    quotationTags: "Tags",
    internalNotes: "Notes",
    dealId: "Linked deal",
  },
  INVOICE: {
    invoiceProducts: "Products",
    invoiceTags: "Tags",
    internalNotes: "Notes",
    paymentsRecieved: "Payments",
  },
  PURCHASE_ORDER: {
    purchaseOrderItems: "Items",
    purchaseOrderTags: "Tags",
    internalNotes: "Notes",
  },
  PROFORMA_INVOICE: {
    proformaInvoiceProducts: "Products",
    proformaInvoiceTags: "Tags",
    internalNotes: "Notes",
  },
  BILL: {
    billProducts: "Items",
    billTags: "Tags",
    internalNotes: "Notes",
  },
  FS_JOB: {
    fsJobServices: "Services",
    fsJobTags: "Tags",
    internalNotes: "Notes",
    fs_job: "Job",
  },
};

export const getActivityColumnLabels = (
  entity: Entity,
  path: string | number
) => {
  const labels = activityColumnLabels[entity];
  if (labels) {
    return path in labels ? labels[path] : path.toString();
  }
  return path.toString();
};
