import LineItemSelector, {
  defaultProduct,
} from "@/components/line-item-selector";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@heffl/ui/components/primitives/grid-table";
import { Input } from "@heffl/ui/components/primitives/input";

import {
  ClientInput,
  MultipleContactsInput,
  MultipleUserInput,
  UserInput,
  VendorInput,
} from "@/components/FormComponents";
import CustomFieldsInputForm from "@/helpers/customFields/custom-fields-input-form";
import validateCustomFields from "@/helpers/customFields/validateCustomFields";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

const dealWithPipelineSchema = Schemas.crm.deal.extend({
  pipelineId: z.number().nullish(),
});

const dealWithPipelineSchemaPartial = Schemas.crm.deal
  .extend({
    pipelineId: z.number().nullish(),
  })
  .partial();

export const DealForm = ({
  form,
  edit = false,
  defaultValues,
}: {
  form: UseFormReturn<z.infer<typeof dealWithPipelineSchema>, unknown>;
  edit?: boolean;
  defaultValues?: {
    client: Partial<z.infer<typeof Schemas.crm.client>>;
  };
}) => {
  const pipelineId = form.watch("pipelineId");
  const clientId = form.watch("clientId");
  const pipelineStageId = form.watch("dealStageId");
  const totalValue = form.watch("price");
  const currentDealProducts = form.watch("dealProducts") || [];

  const [showAddProducts, setShowAddProducts] = useState(false);

  const { data: products } = trpc.products.list.useQuery({});
  const { data: pipelinesList } = trpc.deals.pipelines.list.useQuery();
  const { data: pipelineStages } =
    trpc.deals.pipelines.getPipelineStages.useQuery(
      {
        dealPipelineId: Number(pipelineId) || 0,
      },
      {
        enabled: !!pipelineId,
      }
    );

  const { data: currencies } = trpc.currencies.list.useQuery();

  const dealProductsTotal = () => {
    return currentDealProducts.reduce((acc, curr) => {
      return acc + (curr.price || 0) * (curr.quantity || 0);
    }, 0);
  };

  useEffect(() => {
    if (pipelineStages && !form.getValues("dealStageId")) {
      form.setValue("dealStageId", pipelineStages[0].id);
    }
  }, [form, pipelineStages]);

  // fun total value multiply
  useEffect(() => {
    const [first, second] = String(totalValue).split("*");
    if (first && second) {
      setTimeout(() => {
        form.setValue("price", Number(first) * Number(second));
      }, 1000);
    }
  }, [totalValue]);

  useEffect(() => {
    if (pipelinesList && pipelineStageId && !pipelineId) {
      const pipeline = pipelinesList.find((pipeline) =>
        pipeline.dealPipelineStages.find(
          (stage) => stage.id === form.getValues("dealStageId")
        )
      );
      if (pipeline) {
        form.setValue("pipelineId", pipeline.id);
      }
    }
  }, [pipelineStageId, pipelinesList, form, pipelineId]);

  useEffect(() => {
    if (!showAddProducts) {
      const updatedProducts = currentDealProducts.filter(
        (product) => product.productId && product.quantity && product.price
      );
      form.setValue("dealProducts", updatedProducts);
    }
  }, [showAddProducts, form]);

  if (!pipelinesList || !currencies || !products) return <FullScreenSpinner />;

  return (
    <>
      <div className="flex flex-col gap-2">
        <ClientInput
          name="clientId"
          label="Client"
          onAddModalClose={(id) => id && form.setValue("clientId", id)}
          placeholder="Select or add client"
          addDefaultValues={defaultValues?.client}
        />
        <MultipleContactsInput
          clientId={clientId}
          placeholder="Add contacts associated with this deal"
          name="dealContacts"
          label="Contacts"
          onAddModalClose={(id) =>
            id &&
            form.setValue("dealContacts", [
              ...(form.getValues("dealContacts") || []),
              id,
            ])
          }
        />
        <FormField name="title" label="Title">
          <Input placeholder="Deal..." />
        </FormField>
        <div className="grid grid-cols-2 gap-2">
          <FormField name="price" label="Total value">
            <Input
              prefix="AED"
              prefixFilled
              placeholder={
                !currentDealProducts.length
                  ? "0.00"
                  : String(dealProductsTotal())
              }
              disabled={!!currentDealProducts.length}
            />
          </FormField>
          <FormField name="closingDate" label="Expected close date">
            <DatePicker className="flex" />
          </FormField>
        </div>
        <ModalDrawer
          title="Add products"
          open={showAddProducts}
          onClose={() => {
            setShowAddProducts(false);
          }}
          className="w-full"
          modalClassName="min-w-[800px]"
        >
          {showAddProducts && (
            <FormField name="dealProducts">
              <LineItemSelector />
            </FormField>
          )}
          <div className="flex justify-end mt-2 w-full">
            <Button
              variant="primary"
              onClick={() => {
                setShowAddProducts(false);
              }}
            >
              Save products
            </Button>
          </div>
        </ModalDrawer>
        {currentDealProducts.length ? (
          <Table className="border border-collapse border-slate-300">
            <TableHeader>
              <TableRow>
                <TableHead className="w-[200px]">Product</TableHead>
                <TableHead>Quantity</TableHead>
                <TableHead>Price</TableHead>
                <TableHead>Subtotal</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {currentDealProducts.map((product) => (
                <TableRow key={product.productId}>
                  <TableCell className="font-medium">
                    {
                      products.products.find((p) => p.id === product.productId)
                        ?.name
                    }
                  </TableCell>
                  <TableCell>{product.quantity}</TableCell>
                  <TableCell>{product.price}</TableCell>
                  <TableCell>{product.price * product.quantity}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : null}
        <div className="flex justify-end w-full">
          <p
            className="text-sm font-medium text-blue-600 cursor-pointer"
            onClick={() => {
              setShowAddProducts(true);
              if (!currentDealProducts.length) {
                form.setValue("dealProducts", [
                  // @ts-ignore
                  { ...defaultProduct, productId: null },
                ]);
              }
            }}
          >
            {currentDealProducts.length ? "Edit" : "Add"} products
          </p>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <FormField name="pipelineId" label="Pipeline">
            <Select
              placeholder="Select pipeline"
              options={pipelinesList.map((pipeline) => ({
                label: pipeline.name,
                value: pipeline.id,
              }))}
            />
          </FormField>
          <FormField name="dealStageId" label="Stage">
            <Select
              placeholder="Select stage"
              options={
                pipelineStages?.map((stage) => ({
                  label: stage.name,
                  value: stage.id,
                })) || []
              }
            />
          </FormField>
        </div>
        <div className="grid gap-2 sm:grid-cols-2">
          <MultipleUserInput name="dealAssignees" label="Assigned to" />
          <UserInput
            name="ownerUserId"
            label="Owner"
            defaultCurrentUser={!edit}
            type={["ADMIN", "OWNER", "STAFF", "SUPER_ADMIN"]}
          />
        </div>
        <VendorInput name="vendorId" label="Vendor" allowClear />
        <CustomFieldsInputForm section="DEAL" dealPipelineId={pipelineId} />
      </div>
    </>
  );
};

const AddDealDrawer = ({
  open,
  onClose,
  defaultValues,
  onAddDeal,
}: {
  open: boolean;
  onClose: () => void;
  defaultValues?: z.infer<typeof dealWithPipelineSchemaPartial> & {
    client?: Partial<z.infer<typeof Schemas.crm.client>>;
  };
  onAddDeal?: (id: number) => void;
}) => {
  const params = useParams();
  const contactId = Number(params.contactId);

  const form = useForm<z.infer<typeof dealWithPipelineSchema>>({
    resolver: zodResolver(
      Schemas.crm.deal.superRefine(({ clientId, dealContacts }, ctx) => {
        if (!clientId && !dealContacts?.length) {
          ctx.addIssue({
            code: "custom",
            message: "Contact or company is needed",
            path: ["clientId"],
          });
          ctx.addIssue({
            code: "custom",
            message: "Contact or company is needed",
            path: ["dealContacts"],
          });
        }
      })
    ),
    defaultValues: {
      currencyId: 1,
      dealContacts: contactId ? [contactId] : [],
      priority: "MEDIUM",
      closingDate: dayjs().add(20, "day").toDate(),
      ...defaultValues,
    },
  });

  const pipelineId = form.watch("pipelineId");

  const { data: customFields } = trpc.customizations.customFields.list.useQuery(
    {
      section: "DEAL",
      dealPipelineId: pipelineId,
    },
    {
      enabled: !!pipelineId,
    }
  );

  const dealAddMutation = trpc.deals.add.useMutation({
    async onSuccess(newDeal) {
      onAddDeal && onAddDeal(newDeal.id);
      onClose();
      form.reset();
    },
  });

  const onSubmit = (values: z.infer<typeof dealWithPipelineSchema>) => {
    if (!pipelineId) return;
    const { isValid } = validateCustomFields({
      customFields,
      customFieldsValues: values.customFields,
      form,
    });
    if (!isValid) return;
    dealAddMutation.mutate(values);
  };

  return (
    <ModalDrawer
      open={open}
      onClose={() => {
        onClose();
        form.reset();
      }}
      title="Add deal"
      footer={
        <Button
          loading={dealAddMutation.isLoading}
          type="submit"
          variant="primary"
          className="w-full"
          onClick={() => {
            form.handleSubmit(onSubmit)();
          }}
          size="md"
        >
          Add deal
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <DealForm
          form={form}
          defaultValues={
            defaultValues &&
            defaultValues.client && { client: defaultValues.client }
          }
        />
      </Form>
    </ModalDrawer>
  );
};

export default AddDealDrawer;
