import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import { trpc } from "@/helpers/trpc";
import { accountTypes } from "@/lib/constants";
import Schemas from "@heffl/server/src/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { Mail } from "lucide-react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import video from "../../assets/hero-ai-video.mp4";

const Register = () => {
  const navigate = useNavigate();

  const registrationForm = useForm<z.infer<typeof Schemas.user.user>>({
    resolver: zodResolver(Schemas.user.user),
  });

  const { data: teams } = trpc.users.teams.list.useQuery();
  const addUserMutation = trpc.users.add.useMutation({
    onSuccess() {
      navigate("/login");
      toast.success("User created successfully");
    },
    onError(error) {
      console.log(error);
      toast.error(error.message);
    },
  });

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between p-7 w-full">
        <h1 className="text-lg font-bold">Heffl</h1>
      </div>
      <div className="flex flex-row">
        <div className="w-1/3 h-screen">
          <div className="flex flex-col gap-2 justify-start items-center px-8 mt-16 h-full">
            <h1 className="text-3xl font-bold">Register</h1>
            <div className="flex flex-col gap-4 mt-4 w-full">
              <Form
                {...registrationForm}
                onSubmit={(values) =>
                  addUserMutation.mutate({ ...values, countryCode: 971 })
                }
              >
                <FormField name="teamId" label="Team">
                  <Select
                    options={
                      teams?.map((team) => ({
                        label: team.name,
                        value: team.id,
                      })) || []
                    }
                    placeholder="Select a team"
                  />
                </FormField>
                <FormField name="type" label="Account Type">
                  <Select
                    options={accountTypes.map((type) => ({
                      label: type.label,
                      value: type.value,
                    }))}
                    placeholder="Select a type"
                  />
                </FormField>
                <div className="flex gap-2">
                  <FormField name="firstName" label="First Name">
                    <Input
                      placeholder="First Name"
                      type="text"
                      className="w-full"
                    />
                  </FormField>
                  <FormField name="lastName" label="Last Name">
                    <Input
                      placeholder="Last Name"
                      type="text"
                      className="w-full"
                    />
                  </FormField>
                </div>

                <FormField name="phone" label="Phone">
                  <Input
                    prefix="+971"
                    placeholder="Phone"
                    type="text"
                    className="w-full"
                  />
                </FormField>
                <FormField name="email" label="Email">
                  <Input
                    prefix={<Mail />}
                    placeholder="Email"
                    type="email"
                    className="w-full"
                  />
                </FormField>
                <FormField name="password" label="Password">
                  <Input
                    placeholder="Password"
                    type="password"
                    className="w-full"
                  />
                </FormField>
                <Button
                  type="submit"
                  variant={"primary"}
                  loading={addUserMutation.isLoading}
                >
                  Register
                </Button>
              </Form>
            </div>
          </div>
        </div>
        <div className="w-2/3 h-screen bg-gray-100 rounded-lg">
          <div className="flex flex-col justify-center items-center p-6 h-full">
            {/* <img src="/heroImg.jpg" alt="Heffl screenshot" className="mt-20 rounded-xl md:mt-28 md:rounded-2xl shadow-heroImg xl:max-w-1200"/> */}
            <video
              src={video}
              autoPlay
              className="h-auto rounded-xl md:rounded-2xl shadow-heroImg xl:max-w-1200"
              loop
              muted
              playsInline
              preload="auto"
            ></video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
