import { THistory } from "@heffl/server/src/routes/crm/deals";
import { Button } from "@heffl/ui/components/primitives/button";
import StripeTabs, {
  TabsProps,
} from "@heffl/ui/components/primitives/stripe-tabs";
import ResponsiveActions, {
  ResponsiveAction,
} from "@heffl/ui/components/responsive-actions";
import { cn, isMobile } from "@heffl/ui/lib/utils";
import { ChevronLeft, LucideIcon, Text } from "lucide-react";
import { useNavigate } from "react-router-dom";
import InfoItemsHZ, { InfoItemHZProps } from "./InfoItemHZ";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@heffl/ui/components/primitives/accordion";
import { useParamsState } from "@/lib/hooks/useParamsState";

type DetailsPageProps<T extends string> = {
  icon?: LucideIcon;
  title: string;
  widgets?: (React.ReactNode | null)[];
  description?: string;
  tabs?: TabsProps<T>;
  attributes?: {
    section: string;
    items: InfoItemHZProps["items"];
  }[];
  activities?: THistory[];
  actions?: ResponsiveAction[];
};

const DetailsPage = <T extends string>(props: DetailsPageProps<T>) => {
  const navigate = useNavigate();

  const detailsTab = {
    key: "details",
    label: "Details",
    icon: Text,
    children: (
      <Accordion
        type="multiple"
        defaultValue={props.attributes?.map((attr) => attr.section)}
      >
        {props.attributes?.map(({ section, items }) => (
          <AccordionItem key={section} value={section}>
            <AccordionTrigger
              iconClassName="text-[#232529] w-3 h-3"
              className="text-[#232529]"
            >
              {section}
            </AccordionTrigger>
            <AccordionContent>
              <InfoItemsHZ items={items} className="pl-1" />
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    ),
    actions: [],
    title: "",
  };

  const getTabs = () => {
    if (!props.tabs?.items) return [];

    if (isMobile()) {
      return [detailsTab, ...props.tabs.items].map((item) => ({
        ...item,
      }));
    }
    return props.tabs.items.map((item) => ({
      ...item,
    }));
  };

  const [selectedTab, setSelectedTab] = useParamsState<{ tab: T }>({
    tab: getTabs()[0].key as T,
  });

  const Icon = props.icon;

  return (
    <div className="flex overflow-hidden w-full h-screen bg-gray-50">
      <div className="flex flex-col w-full bg-white">
        <div className="flex gap-2 items-center p-1.5 w-full border-b border-gray-200">
          <Button
            className=" !w-7 !h-7 rounded-full"
            size="icon"
            variant="outline"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ChevronLeft className="h-4" />
          </Button>
          {!!Icon && (
            <div className="grid place-items-center p-2 bg-gray-100 rounded-full border border-gray-300 shadow-md">
              <Icon className="text-gray-500" size={18} />
            </div>
          )}
          <span className="flex justify-between items-center pr-4 w-full">
            <div>
              <div className="flex gap-1 items-center">
                <p className="text-sm font-semibold text-gray-800 line-clamp-1">
                  {props.title}
                </p>
              </div>
              {props.description?.length && (
                <p className="font-normal text-gray-500 text-[11px] -mt-1 line-clamp-1">
                  {props.description}
                </p>
              )}
            </div>
            <ResponsiveActions actions={props.actions || []} />
          </span>
        </div>
        {!!props.widgets?.length && (
          <div className="flex gap-2 items-center p-2 px-4 h-8 bg-[#FBFBFB] border-b border-gray-200">
            {props.widgets.filter(Boolean).map((widget) => widget)}
          </div>
        )}
        <div className="flex flex-1 w-full h-full min-h-0">
          <div
            className={cn(
              "flex flex-col w-9/12 h-full",
              isMobile() && "w-full"
            )}
          >
            <StripeTabs
              {...props.tabs}
              onChange={(tab) => setSelectedTab({ tab: tab as T })}
              className={cn(props.tabs?.className)}
              items={getTabs().map((item) => ({
                ...item,
                children: undefined,
              }))}
              value={selectedTab.tab}
              tabParentClassName={cn(props.tabs?.tabParentClassName, "pl-4")}
            />
            <div className="overflow-auto flex-1 p-3">
              {(getTabs().find((item) => item.key === selectedTab.tab)?.title ||
                getTabs().find((item) => item.key === selectedTab.tab)?.actions
                  ?.length) && (
                <div className="flex justify-between w-full">
                  <h3 className="text-lg font-medium">
                    {
                      getTabs().find((item) => item.key === selectedTab.tab)
                        ?.title
                    }
                  </h3>
                  <ResponsiveActions
                    actions={
                      getTabs().find((item) => item.key === selectedTab.tab)
                        ?.actions || []
                    }
                  />
                </div>
              )}
              {getTabs().find((item) => item.key === selectedTab.tab)?.children}
            </div>
          </div>
          {!isMobile() && (
            <div className="flex flex-col w-3/12 h-full border-l border-gray-200">
              <StripeTabs
                tabParentClassName="pl-4"
                contentClassName="!overflow-auto flex-1 min-h-0 p-2 pt-1"
                items={[detailsTab]}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailsPage;
