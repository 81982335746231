import { ActivityDetailsModal } from "@/components/actionsModals/activities-modals";
import { TaskDetailsModal } from "@/components/actionsModals/tasks-modals";
import Empty from "@/components/Empty";
import FilterBar from "@/components/filters";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import DayEventCalendar from "@/pages/field-service/components/DayEventCalendar";
import Select from "@heffl/ui/components/primitives/creatable-select";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { ScrollArea } from "@heffl/ui/components/primitives/scroll-area";
import {
  Tabs,
  TabsList,
  TabsTrigger,
} from "@heffl/ui/components/primitives/tabs";
import {
  formatAmountToK,
  formatCurrency,
  generateUniqueColor,
} from "@heffl/ui/lib/utils";
import { BarChart, BarList, Card, Grid } from "@tremor/react";

import dayjs from "dayjs";
import {
  Building,
  CircleDollarSign,
  Filter,
  Handshake,
  LucideIcon,
  ScrollText,
  UserSquare2,
} from "lucide-react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AddClientDrawer } from "../clients/list";
import { AddContactDrawer } from "../contacts/list";
import AddDealDrawer from "../deals/components/AddDealDrawer";
import DealCard from "../deals/components/dealCard";
import { AddLeadDrawer } from "../leads/list";

export type SingleQuickAction = {
  path: string;
  title: string;
  icon: LucideIcon;
  description: string;
  onClick: () => void;
};

export const QuickActioncard = ({ action }: { action: SingleQuickAction }) => {
  return (
    <div
      className="flex flex-row gap-3 items-center p-4 w-full rounded-lg border border-gray-200 cursor-pointer hover:bg-green-100 sm:items-start"
      onClick={action.onClick}
    >
      <div className="grid place-items-center w-8 h-8 bg-green-50 rounded-full">
        <action.icon className="w-4 text-primary" />
      </div>
      <div className="flex flex-col justify-center h-full">
        <p className="text-sm font-medium sm:tex-lg">{action.title}</p>
      </div>
    </div>
  );
};

type TTaskTab = "openTasks" | "completedTasks";

type TQuickActions = "CONTACT" | "COMPANY" | "QUOTATION" | "DEAL" | "LEAD";

type Filters = {
  dates: [Date, Date] | undefined;
};

const MetricCard = ({
  title,
  value,
  onClick,
}: {
  title: string;
  value: string | number;
  onClick?: () => void;
}) => {
  return (
    <Card
      className="p-3"
      // parentClassName="cursor-pointer"
      onClick={onClick}
    >
      <p className="text-sm font-medium text-gray-500">{title}</p>
      <p className="text-xl font-semibold sm:text-xl">{value}</p>
    </Card>
  );
};

const CRMPersonalDashboard = () => {
  const [filters, setFilters] = useParamsState<Filters>({
    dates: [dayjs().toDate(), dayjs().toDate()],
  });
  const [selectedPipelineId, setSelectedPipelineId] = useState<string | number>(
    1
  );
  const [selectedTaskTab, setSelectedTaskTab] = useState<TTaskTab>("openTasks");
  const [selectedQuickAction, setSelectedQuickAction] = useState<
    undefined | TQuickActions
  >(undefined);
  const [showTaskDetailsModal, setShowTaskDetailsModal] = useState<
    null | number
  >(null);
  const [showDetailsActivityModal, setShowDetailsActivityModal] = useState<
    null | number
  >(null);

  const activityUpdateMutation = trpc.activities.update.useMutation({
    onSuccess: () => {
      toast.success("Activity updated");
    },
  });

  const { data: dashboardNew } = trpc.home.dashboardNew.useQuery({
    startDate: filters.dates?.[0] || dayjs().toDate(),
    endDate: filters.dates?.[1] || dayjs().toDate(),
  });

  const { data: pipelinesList } = trpc.deals.pipelines.list.useQuery();
  const { data: personal } = trpc.home.personal.useQuery({
    pipelineId: Number(selectedPipelineId),
    startDate: filters.dates?.[0],
    endDate: filters.dates?.[1],
  });

  const navigate = useNavigate();

  const quickActions: SingleQuickAction[] = [
    {
      path: "add-lead",
      title: "Add lead",
      icon: UserSquare2,
      description: "Quickly add a new contact to the system",
      onClick: () => setSelectedQuickAction("LEAD"),
    },
    {
      path: "add-client",
      title: "Add client",
      icon: Building,
      description: "Easily create a new company entry",
      onClick: () => setSelectedQuickAction("COMPANY"),
    },
    {
      path: "add-quotation",
      title: "Add quotation",
      icon: ScrollText,
      description: "Initiate the process of adding a new quotation",
      onClick: () => navigate("/sales/quotations/add"),
    },
    {
      path: "add-deal",
      title: "Add deal",
      icon: CircleDollarSign,
      description: "Start a new deal record",
      onClick: () => setSelectedQuickAction("DEAL"),
    },
  ];

  const onDrawerClose = () => setSelectedQuickAction(undefined);

  if (!dashboardNew || !pipelinesList || !personal)
    return <FullScreenSpinner />;

  const activityResources = [
    {
      id: dayjs().format("DD-MM-YYYY"),
      title: "Today",
    },
  ];

  const groupedActivities = (
    personal?.activities.filter((activity) => {
      const activityDate = dayjs(activity.startDate);
      return activityDate.isSame(dayjs(), "day");
    }) || []
  ).map((activity) => ({
    id: activity.id,
    start: activity.startDate,
    end: activity.endDate,
    title: activity.title,
    backgroundColor: generateUniqueColor(activity.id.toString(), 400),
    allDay: false,
    resourceId: dayjs(activity.startDate).format("DD-MM-YYYY"),
  }));

  const activitiesList = (
    <div className="flex flex-col gap-3">
      <ScrollArea className="overflow-auto sm:h-[500px]">
        <DayEventCalendar
          resources={activityResources}
          events={groupedActivities}
          view="timeGridWeek"
          // hides days other than today and next two days
          hiddenDays={Array.from({ length: 7 }, (_, i) => i).filter(
            (day) => ![dayjs().day()].includes(day as 0 | 1 | 2 | 3 | 4 | 5 | 6)
          )}
          onEventClick={(event) => {
            setShowDetailsActivityModal(Number(event.id));
          }}
          onEventUpdate={(event) => {
            activityUpdateMutation.mutate({
              id: Number(event.id),
              activity: {
                startDate: event.start,
                endDate: {
                  date: event.end,
                  dateType: "CUSTOM_DATE",
                },
              },
            });
          }}
          date={dayjs().toDate()}
        />
      </ScrollArea>
    </div>
  );

  return (
    <div className="w-full">
      <AddLeadDrawer
        open={selectedQuickAction === "LEAD"}
        onClose={onDrawerClose}
      />
      <AddContactDrawer
        open={selectedQuickAction === "CONTACT"}
        onClose={onDrawerClose}
      />
      <AddClientDrawer
        open={selectedQuickAction === "COMPANY"}
        onClose={onDrawerClose}
      />
      <AddDealDrawer
        open={selectedQuickAction === "DEAL"}
        onClose={onDrawerClose}
      />

      {showDetailsActivityModal && (
        <ActivityDetailsModal
          open={!!showDetailsActivityModal}
          onClose={() => setShowDetailsActivityModal(null)}
          activityId={showDetailsActivityModal}
        />
      )}

      {showTaskDetailsModal && (
        <TaskDetailsModal
          open={!!showTaskDetailsModal}
          onClose={() => setShowTaskDetailsModal(null)}
          taskId={showTaskDetailsModal}
        />
      )}

      <div className="flex flex-col gap-2 mb-10">
        {/* Quick actions section */}
        <h1 className="text-xl font-semibold">Quick actions</h1>
        <Grid numItems={2} numItemsSm={4} numItemsLg={4} className="gap-2 mt-6">
          {quickActions.map((action) => (
            <QuickActioncard action={action} key={action.path} />
          ))}
        </Grid>
        {/* Quick action section ends here */}

        <FilterBar
          className=""
          filters={[
            {
              key: "date",
              type: "date-range",
              label: "Date",
              value: filters.dates,
              onChange: (value) =>
                setFilters({
                  dates: value,
                }),
            },
          ]}
        />

        <div className="grid grid-cols-2 gap-4 mt-3 sm:grid-cols-4">
          <MetricCard title="Leads created" value={dashboardNew.leadsCreated} />
          <MetricCard title="Deals created" value={dashboardNew.dealsCreated} />
          <MetricCard
            title="Deals won count"
            value={dashboardNew.noOfDealsWon}
          />
          <MetricCard
            title="Deals won amount"
            value={formatCurrency(dashboardNew.dealsAmountWon, "AED")}
          />
        </div>

        {/* Card ends here */}
        <div className="grid grid-cols-1 gap-4 mt-6 sm:grid-cols-3">
          <Card className="flex flex-col gap-2">
            <h1 className="text-sm font-semibold">My task</h1>
            <Tabs
              value={selectedTaskTab}
              onValueChange={(value: string) =>
                setSelectedTaskTab(value as TTaskTab)
              }
            >
              <TabsList>
                <TabsTrigger value="openTasks">Open</TabsTrigger>
                <TabsTrigger value="completedTasks">Completed</TabsTrigger>
              </TabsList>
            </Tabs>

            {/* <div className="flex flex-col gap-3 h-[400px] overflow-scroll">
              {personal.tasksByStatus[selectedTaskTab].length ? (
                personal.tasksByStatus[selectedTaskTab].map((task) => (
                  <TaskCard
                    key={task.id}
                    task={task}
                    onClick={() => {
                      setShowTaskDetailsModal(task.id);
                    }}
                  />
                ))
              ) : (
                <div className="flex flex-col gap-2 h-[400px] items-center justify-center">
                  <StickyNote className="w-16 h-16 text-gray-700" />
                  <h1 className="text-lg font-semibold text-gray-700">
                    No{" "}
                    {selectedTaskTab === "completedTasks"
                      ? "completed"
                      : "open"}{" "}
                    tasks for you
                  </h1>
                  <p className="text-sm text-gray-600">
                    There is no{" "}
                    {selectedTaskTab === "completedTasks"
                      ? "completed"
                      : "open"}{" "}
                    tasks for you
                  </p>
                </div>
              )}
            </div> */}
          </Card>
          <Card className="flex flex-col gap-2">
            <h1 className="text-sm font-semibold">Todays Activities</h1>
            {activitiesList}
          </Card>

          <Card className="flex flex-col gap-2">
            <h1 className="text-sm font-semibold">
              My deals closing this month
            </h1>
            <div className="flex flex-col gap-2">
              {personal.deals.length ? (
                personal.deals?.map((deal) => (
                  <DealCard key={deal.id} deal={deal} isListingCard={true} />
                ))
              ) : (
                <div className="h-[400px] flex flex-col items-center justify-center gap-2">
                  <Handshake className="w-16 h-16" />
                  <h1 className="text-lg font-semibold text-gray-700">
                    No deals closing this month
                  </h1>
                  <p className="text-sm text-gray-500">
                    There are no deals for you closing this month
                  </p>
                </div>
              )}
            </div>
          </Card>
        </div>

        <div className="grid grid-cols-1 gap-4 mt-6 sm:grid-cols-2">
          <Card className="flex flex-col">
            <p className="self-start mb-4 text-sm font-medium text-gray-700">
              Lead by stage
            </p>
            <h3 className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
              Overall conversion
            </h3>
            <p className="text-lg font-semibold">
              {personal.conversionRate.conversionRate}%
            </p>
            <BarChart
              data={personal.leadStats}
              index="name"
              categories={["value"]}
              colors={["green"]}
              className="z-50 mt-3"
            />
          </Card>
          <Card className="flex flex-col">
            <p className="self-start mb-4 text-sm font-medium text-gray-700">
              Lead by source
            </p>
            {personal.leadSourceStats.length ? (
              <BarList
                data={personal.leadSourceStats}
                color="pink"
                className=""
              />
            ) : (
              <div className="flex flex-col justify-center items-center h-full">
                <Empty
                  title="No lead sources added"
                  description="Please add a lead source in the settings"
                  icon={Filter}
                />
              </div>
            )}
          </Card>
        </div>

        {/* Chart */}
        <Card title="Leads created by user" className="mt-4">
          <h1 className="text-sm font-semibold">Amount by stage</h1>
          <div className="mt-2">
            <Select
              options={pipelinesList.map((pipeline) => ({
                label: pipeline.name,
                value: pipeline.id.toString(),
              }))}
              onChange={(value) => setSelectedPipelineId(Number(value))}
              value={selectedPipelineId.toString()}
              placeholder="Select pipeline"
              className="w-full sm:w-64"
            />
          </div>
          <div className="mt-6">
            {personal?.dealsAmountByStage && (
              <BarChart
                data={personal.dealsAmountByStage.data || []}
                index="name"
                categories={["value"]}
                colors={["green"]}
                valueFormatter={(value) => formatAmountToK(value)}
              />
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default CRMPersonalDashboard;
